import React from "react";
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer as MUDrawer,
  makeStyles,
  Hidden,
  IconButton,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import { Link as RouterLink, useParams } from "react-router-dom";
import HomeIcon from "@material-ui/icons/HomeOutlined";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCartOutlined";
import PersonAddIcon from "@material-ui/icons/PersonAddOutlined";
import AboutIcon from "@material-ui/icons/InfoOutlined";
import PhoneIcon from "@material-ui/icons/PhoneOutlined";
import AssignmentIcon from "@material-ui/icons/AssignmentOutlined";
import CloseIcon from "@material-ui/icons/Close";
import PersonIcon from "@material-ui/icons/PersonOutline";
import ExitToAppIcon from "@material-ui/icons/ExitToAppOutlined";
import PasswordIcon from "@material-ui/icons/VpnKeyOutlined";
import SearchIcon from "@material-ui/icons/SearchOutlined";
import DateRangeIcon from "@material-ui/icons/DateRangeOutlined";

import { Image } from "cloudinary-react";

import { clubUrl } from "../lib/helpers";
import PoweredBy from "../components/PoweredBy";

export default function Drawer(props) {
  const classes = useStyles();
  const { clubId } = useParams();

  const renderTopDrawerItems = () => (
    <List dense>
      <ListItem
        button
        component={RouterLink}
        to={clubUrl(clubId)}
        onClick={props.onCloseDrawer}
      >
        <ListItemIcon>
          <HomeIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText primary="דף הבית" />
      </ListItem>
      <ListItem
        button
        component={RouterLink}
        to={clubUrl(clubId, "players/new")}
        onClick={props.onCloseDrawer}
      >
        <ListItemIcon>
          <PersonAddIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText primary="הוספת ספורטאי/ת" />
      </ListItem>
      {props.clubConfig && props.clubConfig.store && (
        <ListItem
          button
          component={RouterLink}
          to={clubUrl(clubId, "purchase?mode=store")}
          onClick={props.onCloseDrawer}
        >
          <ListItemIcon>
            <ShoppingCartIcon className={classes.icon} />
          </ListItemIcon>
          <ListItemText primary="חנות" />
        </ListItem>
      )}

      <ListItem
        button
        component={RouterLink}
        to={clubUrl(clubId, "about")}
        onClick={props.onCloseDrawer}
      >
        <ListItemIcon>
          <AboutIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText primary="אודות" />
      </ListItem>

      <ListItem
        button
        component={RouterLink}
        to={clubUrl(clubId, "contact")}
        onClick={props.onCloseDrawer}
      >
        <ListItemIcon>
          <PhoneIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText primary="צור קשר" />
      </ListItem>
      <ListItem
        button
        component={RouterLink}
        to={clubUrl(clubId, "clubTerms")}
        onClick={props.onCloseDrawer}
      >
        <ListItemIcon>
          <AssignmentIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText primary="תקנון רישום" />
      </ListItem>
      {props.isMobile && (
        <React.Fragment>
          <ListItem
            button
            component={RouterLink}
            to="/clubs"
            onClick={props.onCloseDrawer}
          >
            <ListItemIcon>
              <SearchIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary="חיפוש מועדון" />
          </ListItem>
          <ListItem
            button
            component={RouterLink}
            to={clubUrl(clubId, "calendar")}
            onClick={props.onCloseDrawer}
          >
            <ListItemIcon>
              <DateRangeIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary="לוח אירועים" />
          </ListItem>
        </React.Fragment>
      )}
    </List>
  );

  const renderBottomDrawerItems = () => (
    <List dense>
      <ListItem
        button
        component={RouterLink}
        to={clubUrl(clubId, "profile")}
        onClick={props.onCloseDrawer}
      >
        <ListItemIcon>
          <PersonIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText primary="הפרופיל שלי" />
      </ListItem>
      <ListItem
        button
        component={RouterLink}
        to={clubUrl(clubId, "profile/changePassword")}
        onClick={props.onCloseDrawer}
      >
        <ListItemIcon>
          <PasswordIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText primary="שינוי סיסמה" />
      </ListItem>

      <ListItem
        button
        component={RouterLink}
        to={clubUrl(clubId, "logout")}
        onClick={props.onCloseDrawer}
      >
        <ListItemIcon>
          <ExitToAppIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText primary="יציאה" />
      </ListItem>
    </List>
  );

  const renderTop = () => (
    <div className={classes.topContainer}>
      <Hidden xsDown>
        <div className={classes.appBarSpacer} />
      </Hidden>
      <Hidden smUp>
        <IconButton
          edge="start"
          className={classes.closeButton}
          color="inherit"
          onClick={props.onCloseDrawer}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      </Hidden>
      <RouterLink color="secondary" to={clubUrl(clubId)}>
        {props.clubConfig ? (
          <Image
            publicId={props.clubConfig.general.logo.name}
            version={props.clubConfig.general.logo.version}
            className={classes.logo}
            alt="Logo"
          />
        ) : (
          <Skeleton variant="circle" className={classes.logoPlaceholder} />
        )}
      </RouterLink>
      <Divider style={{ width: "100%" }} />
    </div>
  );

  const renderPlaceHolder = (count) => (
    <div className={classes.topContainer}>
      {[...Array(count).keys()].map((i) => (
        <Skeleton variant="text" key={i} width="100%">
          <ListItem button component={RouterLink} to={""}>
            <ListItemIcon>
              <HomeIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary="" />
          </ListItem>
        </Skeleton>
      ))}
    </div>
  );

  const renderContent = () => (
    <React.Fragment>
      {renderTop()}
      <div className={classes.drawerContainer}>
        {props.clubConfig ? renderTopDrawerItems() : renderPlaceHolder(5)}
        <Divider />
        <Hidden smUp>{renderBottomDrawerItems()}</Hidden>
        <div style={{ flexGrow: 1 }} />
        <Hidden smUp>
          <PoweredBy />
        </Hidden>
      </div>
    </React.Fragment>
  );

  return (
    <>
      <Hidden smDown>
        <MUDrawer
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {renderContent("desktop")}
        </MUDrawer>
      </Hidden>
      <Hidden mdUp>
        <MUDrawer
          variant="temporary"
          anchor="left"
          open={props.open}
          onClose={props.onCloseDrawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
            dir: "rtl",
          }}
        >
          <div className={classes.container}>{renderContent("mobile")}</div>
        </MUDrawer>
      </Hidden>
    </>
  );
}

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    background: theme.palette.background.drawer,
  },
  drawerContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflow: "auto",
    paddingTop: 10,
    marginBottom: 20,
    [theme.breakpoints.down("xs")]: {
      paddingTop: 0,
    },
  },
  appBarSpacer: { height: 70 },
  logo: {
    width: 140,
    height: 140,
    padding: 10,
    [theme.breakpoints.down("xs")]: {
      height: 90,
      width: 90,
      marginTop: 5,
      padding: 0,
    },
  },
  logoPlaceholder: {
    width: 120,
    height: 120,
    margin: 10,
    [theme.breakpoints.down("xs")]: {
      height: 90,
      width: 90,
      marginTop: 5,
      padding: 0,
    },
  },
  closeButton: {
    position: "absolute",
    top: 10,
    left: 10,
  },
  topContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  icon: {
    color: theme.drawer.icon,
  },
}));
