import React from "react";
import { Badge, Box, Button, makeStyles } from "@material-ui/core";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";

export default function ShoppingCartIndicator({
  cartItems,
  disabled,
  onClick,
}) {
  const classes = useStyles();
  const Component = disabled ? Box : Button;

  return (
    <Component className={classes.container} onClick={onClick}>
      <Badge
        badgeContent={cartItems}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        classes={{ badge: classes.badge }}
        style={{ margin: 10 }}
        showZero
      >
        <ShoppingCartOutlinedIcon />
      </Badge>
    </Component>
  );
}

const useStyles = makeStyles(() => ({
  container: {
    position: "fixed",
    right: -20,
    bottom: 70,
    width: 70,
    height: 50,
    border: "1px solid #ddd",
    borderRadius: 25,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  badge: {
    border: "1px solid #EEE",
    backgroundColor: "white",
  },
}));
