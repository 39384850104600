import React from "react";
import { Link as RouterLink } from "react-router-dom";

import PopUp from "../../components/base/PopUp";
import { clubUrl } from "../../lib/helpers";
import ActionButton from "../../components/ActionButton";
import { makeStyles } from "@material-ui/core";

export default function MissingIdDialog(props) {
  const classes = useStyles();
  const button = (
    <ActionButton
      variant="contained"
      component={RouterLink}
      to={clubUrl(props.clubId, `profile`)}
      color="primary"
    >
      עדכון פרטים
    </ActionButton>
  );

  return (
    <PopUp
      open={props.open}
      noNativeClose
      size="xs"
      title="עדכון פרטים אישיים"
      customButton={button}
      contentClassName={classes.content}
    >
      שמנו לב שפרטיכם האישיים אינם עדכניים. כדי לקבל שירות מיטבי אנא עדכנו את
      פרטיכם
    </PopUp>
  );
}

const useStyles = makeStyles(() => ({
  content: {
    paddingBottom: 10,
  },
}));
