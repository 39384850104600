import React from "react";
import { useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";

import Button from "../../../components/Button";
import { clubUrl } from "../../../lib/helpers";
import axios from "../../../lib/axios";

export default function SpecialRequestCompleted({
  subscription,
  clubId,
  onClose,
}) {
  const classes = useStyles();

  const requestAccepted =
    subscription &&
    subscription.specialRequest.discountRequest.status === "accepted";

  const setRequestAsSeen = async () => {
    axios.patch(
      `/store/subscriptions/${subscription._id}/setSpecialRequestAsSeen`
    );
  };

  useEffect(() => {
    if (subscription) {
      setRequestAsSeen();
    }
  }, [subscription]);

  const renderContent = () => {
    console.log(subscription);
    return (
      <DialogContent>
        {requestAccepted
          ? "אנחנו שמחים לבשר לכם שהמועדון "
          : "לצערנו המועדון לא "}{" "}
        אישר את הבקשה המיוחדת שהגשתם עבור
        {" " + subscription.clubPlayer.name} בגין הפעילות
        <strong> ב{subscription.team.name}</strong>
        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            component={RouterLink}
            to={clubUrl(
              clubId,
              `purchase?clubPlayerId=${subscription.clubPlayer._id}&mode=payment&subscriptionId=${subscription._id}`
            )}
          >
            לתשלום לחצו כאן
          </Button>
        </div>
      </DialogContent>
    );
  };

  return (
    <Dialog
      dir="rtl"
      open={subscription !== null}
      maxWidth="xs"
      onClose={onClose}
    >
      <DialogTitle onClose={onClose} disableTypography>
        <Typography variant="h6" style={{ marginLeft: 10 }}>
          {requestAccepted ? "בקשתכם אושרה!" : "הבקשה שהגשתם לא אושרה"}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {subscription && renderContent()}
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    marginTop: 30,
    marginBottom: 15,
    justifyContent: "center",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));
