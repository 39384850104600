import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";

export const baseTheme = () => ({
  direction: "rtl",
  palette: {
    background: {
      default: "#f8f9fa",
      drawer: "linear-gradient(to right, #DDD 0%, #FFF 100%)",
    },
  },
  footer: {
    background: "#fafbfc",
    border: "#e9ecef",
    link: "#6c757d",
    linkHover: "#495057",
  },
  paper: {
    header: null, // null === image
    borderColor: null,
  },
  playerCard: {
    hover: {
      background: "#f8f9fa",
      color: "#406ad8",
    },
  },
  drawer: {
    icon: "rgba(0, 0, 0, 0.54)",
  },
  appBar: {
    background: "linear-gradient(to top, #006494 0%, #006494 1%, #247BA0 100%)",
    color: "#FFF",
  },
  overrides: {
    MuiListItem: {
      root: {
        textAlign: "right",
      },
    },
  },
});

export const generateTheme = (theme) => {
  return responsiveFontSizes(createTheme(theme));
};
