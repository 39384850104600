import React from "react";
import { ListItem, ListItemText, makeStyles } from "@material-ui/core";
import moment from "moment";
import ScheduleIcon from "@material-ui/icons/Schedule";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import DirectionsBusIcon from "@material-ui/icons/DirectionsBus";

export default function Event(props) {
  const classes = useStyles();
  const { event } = props;
  const renderDetails = () => {
    const time = `יום ${moment(event.start).format("ddd")} ${moment(
      event.start
    ).format("DD/MM")}, ${moment(event.end).format("HH:mm")} - ${moment(
      event.start
    ).format("HH:mm")}`;

    let departureTime, resourceDesc;
    const awayGame =
      event.type === "game" && event.gameDetails.gameType === "away";
    if (awayGame) {
      departureTime = event.gameDetails.departureTime
        ? `${moment(event.gameDetails.departureTime).format("HH:mm")}`
        : null;
      resourceDesc = event.gameDetails.location;
    } else {
      resourceDesc = event.resourceName;
    }

    return (
      <div>
        <div className={classes.detailsRow}>
          <ScheduleIcon /> {time}
        </div>
        <div className={classes.detailsRow}>
          <LocationOnIcon /> {resourceDesc}
        </div>
        {awayGame && (
          <div className={classes.detailsRow}>
            <DirectionsBusIcon /> {departureTime}
          </div>
        )}
      </div>
    );
  };

  return (
    <ListItem className={classes.container}>
      <ListItemText primary={props.event.title} secondary={renderDetails()} />
    </ListItem>
  );
}

const useStyles = makeStyles({
  container: {
    backgroundColor: "#f8f9fa",
    border: "1px solid #EEE",
    borderRadius: 5,
    marginBottom: 10,
  },
  detailsRow: {
    alignItems: "center",
    display: "flex",
  },
});
