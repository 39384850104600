import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@material-ui/core";
import { withRouter } from "react-router";
import TermsText from "../TermsText";
import PrivacyText from "../PrivacyText";

function TermsDialog(props) {
  return (
    <Dialog
      dir="rtl"
      open={props.open}
      onClose={props.onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="scroll-dialog-title">
        תנאי שימוש ומדיניות פרטיות
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h5">תנאי שימוש</Typography>
        <TermsText />
        <Typography variant="h5">מדיניות פרטיות</Typography>
        <PrivacyText />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          סגור
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withRouter(TermsDialog);
