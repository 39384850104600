export const sendMessage = (type, params = {}) => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type,
        params,
      })
    );
  }
};
