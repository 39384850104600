import React from "react";
import {
  Typography,
  Divider,
  Paper as MuiPaper,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import AbstractBackground from "../resources/images/paperBackground.jpg";

export default function Paper(props) {
  const classes = useStyles();

  return (
    <MuiPaper
      style={props.style}
      {...props.paperProps}
      className={clsx(classes.container, props.className)}
    >
      {props.title && (
        <>
          <div
            className={clsx(classes.title, props.titleClassName, {
              [classes.bigTitle]: props.bigTitle,
              [classes.titleBackground]: props.titleBackground,
            })}
          >
            <Typography component="h2" variant={props.bigTitle ? "h5" : "h6"}>
              {props.title}
            </Typography>
            {props.leftItem}
          </div>
          <Divider
            className={clsx(classes.divider, props.titleDividerClassName)}
          />
        </>
      )}
      <div className={clsx(classes.body, props.bodyClassName)}>
        {props.children}
      </div>
    </MuiPaper>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    container: {
      border: theme.paper.border,
    },
    divider: {
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down("xs")]: {
        marginBottom: theme.spacing(1),
      },
    },
    body: {
      padding: theme.spacing(1, 4, 2, 4),
      marginBottom: theme.spacing(2), // same as divider
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(1, 2, 1, 2),
        marginBottom: theme.spacing(1),
      },
    },
    title: {
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(3),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    bigTitle: {
      padding: theme.spacing(2),
    },
    // accessibility
    ...(theme.paper.header
      ? {}
      : {
          titleBackground: {
            backgroundImage: `url(${AbstractBackground})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          },
        }),
  };
});
