import {
  CircularProgress,
  Hidden,
  Typography,
  withStyles,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import React, { Component } from "react";
import { connect } from "react-redux";

import axios from "../../../lib/axios";
import Paper from "../../../components/Paper";
import MobileSubscriptions from "./MobileSubscriptions";
import DesktopSubscriptions from "./DesktopSubscriptions";
import SpecialRequestCompleted from "./SpecialRequestCompleted";

class Subscriptions extends Component {
  state = {
    subscriptions: null,
    unseenCompletedSpecialRequest: null,
  };

  componentDidMount = () => {
    this.loadData();
  };

  loadData = async () => {
    this.setState({ isLoading: true });
    const { clubId } = this.props.match.params;
    const res = await axios.get(
      `/store/subscriptions?parentId${this.props.parentId}&clubInternalName=${clubId}&active=true&granularity=clubPlayer&granularity=product&granularity=team`
    );

    const unseenCompletedSpecialRequest =
      res.data.find(
        (s) =>
          s.specialRequest &&
          s.specialRequest.type === "discount" &&
          s.specialRequest.status === "close" &&
          !s.specialRequest.seenByParent &&
          s.paid === 0
      ) || null;

    this.setState({
      subscriptions: res.data,
      isLoading: true,
      unseenCompletedSpecialRequest,
    });
  };

  renderSubscriptions = () => {
    const { clubId } = this.props.match.params;

    return (
      <div>
        {this.state.subscriptions.length === 0 ? (
          <Typography className={this.props.classes.noDataText}>
            לא נמצאו מסגרות פעילות במועדון זה. לרישום לפעילות חדשה יש ללחוץ על
            ״רישום לפעילות״
          </Typography>
        ) : (
          <>
            <SpecialRequestCompleted
              subscription={this.state.unseenCompletedSpecialRequest}
              onClose={() =>
                this.setState({ unseenCompletedSpecialRequest: null })
              }
              clubId={clubId}
            />
            <Hidden xsDown>
              <DesktopSubscriptions
                subscriptions={this.state.subscriptions}
                clubId={clubId}
                clubConfig={this.props.clubConfig}
              />
            </Hidden>
            <Hidden smUp>
              <MobileSubscriptions
                subscriptions={this.state.subscriptions}
                clubId={clubId}
                clubConfig={this.props.clubConfig}
              />
            </Hidden>
          </>
        )}
      </div>
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <Paper
        title="פעילויות"
        bigTitle
        titleBackground
        bodyClassName={classes.paperBody}
        className={classes.container}
      >
        {this.state.subscriptions ? (
          this.renderSubscriptions()
        ) : (
          <CircularProgress />
        )}
      </Paper>
    );
  }
}

const styles = {
  container: {
    height: "100%",
  },
  paperBody: {
    padding: 0,
  },
  noDataText: {
    padding: 20,
  },
};

const mapStateToProps = (state) => {
  return {
    parentId: state.auth.parentId,
  };
};

function mapDispatchToProps() {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(Subscriptions)));
