import React from "react";
import {
  Collapse,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";

import MenuItem from "../../components/base/MenuItem";
import { useMemo } from "react";

export default function EnrollmentsForm(props) {
  const classes = useStyles();

  const handleChange = (updates) => {
    props.onChange(updates);
    if (Object.prototype.hasOwnProperty.call(updates, "pricingId")) {
      if (updates.pricingId) {
        const teamId = updates.teamId || props.state.teamId;
        const team = props.teams.find((t) => t._id === teamId);
        props.onSetSeasonProduct(team.product, updates.pricingId);
      } else {
        props.onRemoveSeasonProduct();
      }
    }
  };

  const handleTeamChange = (event) => {
    const teamId = event.target.value;
    const team = props.teams.find((t) => t._id === teamId);

    const updates = { teamId };
    if (team.product._id === "external") {
      updates.pricingId = "external";
    } else if (team.product.subscriptionDetails.singlePricing === true) {
      updates.pricingId = team.product.subscriptionDetails.pricings[0]._id;
    } else {
      updates.pricingId = "";
    }

    handleChange(updates);
  };

  const renderNoTeams = () => (
    <Grid item xs={12}>
      <Typography
        variant="h6"
        style={{ marginTop: 20, marginBottom: 20, textAlign: "center" }}
      >
        ההרשמה לעונה הקרובה תפתח בקרוב!
      </Typography>
    </Grid>
  );

  const showSubLabel =
    props.state.level !== "" && props.state.level.subLevels.length > 1;

  const team = useMemo(
    () => props.teams.find((t) => t._id === props.state.teamId),
    [props.teams, props.state.teamId]
  );
  const showSubscriptionOptions =
    team?.product?.subscriptionDetails?.singlePricing === false;

  const renderContent = () => (
    <>
      <Grid item xs={12}>
        <TextField
          fullWidth
          select
          required
          error={Object.prototype.hasOwnProperty.call(
            props.state.errors,
            "level"
          )}
          label="קטגוריה"
          value={props.state.level ? props.state.level._id : ""}
          onChange={async (e) => {
            const level = props.levels.find((l) => l._id === e.target.value);
            handleChange({
              level,
              subLevel: level.subLevels.length === 1 ? level.subLevels[0] : "",
              teamId: "",
              pricingId: "",
            });
          }}
          helperText={props.state.errors.level ? "שדה חובה" : ""}
        >
          {props.levels.map((level) => (
            <MenuItem key={level._id} value={level._id}>
              {level.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Collapse in={showSubLabel} className={classes.subCategoryCollapse}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            select
            required
            disabled={!props.state.level}
            label="תת קטגוריה"
            error={Object.prototype.hasOwnProperty.call(
              props.state.errors,
              "subLevel"
            )}
            value={props.state.subLevel ? props.state.subLevel._id : ""}
            onChange={async (e) => {
              handleChange({
                subLevel: props.state.level.subLevels.find(
                  (l) => l._id === e.target.value
                ),
                teamId: "",
                pricingId: "",
              });
            }}
            helperText={props.state.errors.seasonProduct ? "שדה חובה" : ""}
          >
            {props.state.level ? (
              props.state.level.subLevels.map((subLevel) => (
                <MenuItem key={subLevel._id} value={subLevel._id}>
                  {subLevel.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem />
            )}
          </TextField>
        </Grid>
      </Collapse>

      <Grid item xs={12}>
        <TextField
          fullWidth
          disabled={!props.state.subLevel}
          required
          error={Object.prototype.hasOwnProperty.call(
            props.state.errors,
            "teamId"
          )}
          label="קבוצה"
          select
          value={props.state.teamId}
          onChange={handleTeamChange}
          helperText={props.state.errors.teamId ? "שדה חובה" : ""}
          className={classes.teamTextField}
        >
          {props.state.subLevel ? (
            props.state.subLevel.teams.map((team) => {
              const open = team.isRegistrationOpen && !team.isFull;
              const alreadyRegistered =
                props.clubPlayer &&
                props.clubPlayer.activeTeams &&
                props.clubPlayer.activeTeams.indexOf(team._id) > -1;
              let text = team.name;
              if (alreadyRegistered) {
                text += " - הספורטאי/ת רשומים לקבוצה זו";
              } else if (team.isFull) {
                text += " - הקבוצה מלאה";
              } else if (!team.isRegistrationOpen) {
                text += " - ההרשמה נסגרה";
              }
              return (
                <MenuItem
                  key={team._id}
                  value={team._id}
                  disabled={alreadyRegistered || !open}
                >
                  {text}
                </MenuItem>
              );
            })
          ) : (
            <MenuItem />
          )}
        </TextField>
      </Grid>
      <Collapse
        in={showSubscriptionOptions}
        className={classes.subCategoryCollapse}
      >
        <Grid item xs={12}>
          <TextField
            fullWidth
            // showSubscriptionOptions => prevent some warnings
            select={showSubscriptionOptions}
            required
            label="סוג רישום"
            error={Object.prototype.hasOwnProperty.call(
              props.state.errors,
              "pricingId"
            )}
            value={props.state.pricingId}
            onChange={async (e) => handleChange({ pricingId: e.target.value })}
            helperText={props.state.errors.pricingId ? "שדה חובה" : ""}
          >
            {team?.product?.subscriptionDetails?.pricings?.map((pricing) => (
              <MenuItem key={pricing._id} value={pricing._id}>
                {pricing.title}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Collapse>
    </>
  );

  return (
    <>
      <Grid container spacing={3}>
        {props.levels.length === 0 ? renderNoTeams() : renderContent()}
      </Grid>
    </>
  );
}

const useStyles = makeStyles(() => ({
  subCategoryCollapse: {
    width: "100%",
    paddingRight: 12,
    paddingLeft: 12,
  },
  teamTextField: {
    "& .MuiInputBase-input": {
      whiteSpace: "normal", // break long team names into multiple lines (ellipsis doesn't work)
    },
  },
}));
