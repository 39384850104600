import React, { Component } from "react";

import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
} from "@material-ui/core";
import _ from "lodash";

import axios from "../../lib/axios";
import MenuItem from "../../components/base/MenuItem";
import { Image } from "cloudinary-react";
import { clubCategories } from "../../lib/dictionaries";
import { sendMessage } from "../../lib/wrapperHelper";

export default class Clubs extends Component {
  state = {
    clubs: null,
    categories: null,
    selectedCategory: "",
  };

  componentDidMount = () => {
    this.loadData();
  };

  loadData = async () => {
    let res = await axios.get("/clubs?granularity=parentsConfig");
    let clubs = res.data.filter((c) => c.internalName !== "presentation_club");
    clubs = _.groupBy(
      _.sortBy(clubs, (club) => club.clubConfig.general.name),
      "category"
    );

    let categories = _.sortBy(
      _.uniq(Object.keys(clubs)),
      (c) => clubCategories[c]
    );
    categories = [
      "soccer",
      "basketball",
      ...categories.filter((c) => c !== "basketball" && c !== "soccer"),
    ];

    this.setState({ clubs, categories });
  };

  renderSearchBar = () => {
    return (
      <TextField
        select
        fullWidth
        label="ענף"
        placeholder="בחרו ענף"
        value={this.state.selectedCategory}
        onChange={(e) => this.setState({ selectedCategory: e.target.value })}
      >
        {this.state.categories.map((category) => (
          <MenuItem key={category} value={category}>
            {clubCategories[category]}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  handleClubChange = (club) => () => {
    sendMessage("clubChange", {
      internalName: club.internalName,
      _id: club._id,
    });
    this.props.history.push(`/clubs/${club.internalName}`);
  };

  renderClubs = () => {
    return (
      <List>
        {(this.state.clubs[this.state.selectedCategory] || []).map((club) => (
          <ListItem key={club._id} button onClick={this.handleClubChange(club)}>
            <ListItemAvatar>
              <Avatar>
                <Image
                  publicId={club.clubConfig.general.logo.name}
                  version={club.clubConfig.general.logo.version}
                  height="40"
                />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={club.clubConfig.general.name}></ListItemText>
          </ListItem>
        ))}
      </List>
    );
  };

  render() {
    return (
      <div style={{ flex: 1 }}>
        {this.state.clubs && (
          <div>
            <Typography>בחרו את המועדון אליו אתם מועניינים להירשם:</Typography>

            {this.renderSearchBar()}
            {this.renderClubs()}
          </div>
        )}
      </div>
    );
  }
}
