import { Container, Grid } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import moment from "moment";

import Paper from "../../components/Paper";
import PasswordHelperText from "../../components/PasswordHelperText";
import PasswordTextField from "../../components/PasswordTextField";
import Snackbar from "../../components/Snackbar";
import Button from "../../components/Button";
import axios from "../../lib/axios";

function ChangePassword(props) {
  const [currentPassword, setCurrentPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [currentPasswordError, setCurrentPasswordError] = React.useState(null);
  const [newPasswordError, setNewPasswordError] = React.useState(null);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [isSending, setIsSending] = React.useState(false);

  const defaultTextParams = {
    fullWidth: true,
    variant: "outlined",
  };

  const validate = () => {
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;

    const currentError = currentPassword.length === 0 ? "סיסמה לא תקינה" : null;
    setCurrentPasswordError(currentError);

    const newError =
      newPassword.match(passwordRegex) === null ? "סיסמה לא תקינה" : null;
    setNewPasswordError(newError);

    return currentError === null && newError === null;
  };

  const handleChange = async () => {
    if (!validate()) {
      return;
    }

    try {
      setIsSending(true);
      await axios.post(`/users/${props.userId}/changePassword`, {
        currentPassword,
        newPassword,
      });
      setSnackbarOpen(moment().format());
    } catch (error) {
      if (
        error.response &&
        error.response.status === 422 &&
        error.response.data.error === "noMatch"
      ) {
        setCurrentPasswordError("סיסמה לא נכונה");
      }
    } finally {
      setIsSending(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper title="שינוי סיסמה" bigTitle titleBackground>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PasswordTextField
              {...defaultTextParams}
              label="סיסמה נוכחית"
              onChange={(e) => setCurrentPassword(e.target.value)}
              error={currentPasswordError !== null}
              helperText={currentPasswordError}
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordTextField
              {...defaultTextParams}
              label="סיסמה חדשה"
              onChange={(e) => setNewPassword(e.target.value)}
              error={newPasswordError !== null}
              helperText={newPasswordError}
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordHelperText password={newPassword} />
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={handleChange}
              isLoading={isSending}
            >
              שנה סיסמה
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Snackbar
        open={snackbarOpen !== false}
        onClose={() => setSnackbarOpen(false)}
        key={snackbarOpen}
        message="הסיסמה עודכנה בהצלחה"
        variant="success"
      ></Snackbar>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    parentId: state.auth.parentId,
  };
};

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
