import axios from "axios";
import https from "https";
import config from "../config";

const instance = axios.create({
  baseURL: config.apiUrl,
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
  headers: {
    common: {
      "request-from": "parents-web",
    },
  },
});

export default instance;
