export const leagueTypes = {
  katsalA: "קטסל א",
  katsalB: "קטסל ב",
  yeladimB: "ילדים ב",
  yeladimA: "ילדים ב",
  nearimA: "נערים א",
  nearimB: "נערים ב",
  noar: "נוער",
  bogrim: "בוגרים",
};

export const teamTypes = {
  league: "ליגה",
  school: "בית ספר",
};

export const genders = {
  male: "בנים",
  female: "בנות",
};

export const transactionKinds = {
  CheckTransaction: "צ׳ק",
  CreditCardTransaction: "כרטיס אשראי",
  CashTransaction: "מזומן",
};

export const paymentMethods = {
  check: "צ׳ק",
  creditCard: "כרטיס אשראי",
  cash: "מזומן",
};

export const creditCardTypes = {
  visa: "ויזה",
  isracard: "ישראכרט",
  amex: "אמריקן אקספרס",
  diners: "דיינרס",
  max: "מקס",
  unknown: null,
};

export const transactionStatuses = {
  approved: "תקינה",
  cancelled: "מבוטלת",
  settled: "תקינה",
};

export const classes = {
  0: "גן",
  1: "כיתה א",
  2: "כיתה ב",
  3: "כיתה ג",
  4: "כיתה ד",
  5: "כיתה ה",
  6: "כיתה ו",
  7: "כיתה ז",
  8: "כיתה ח",
  9: "כיתה ט",
  10: "כיתה י",
  11: "כיתה יא",
  12: "כיתה יב",
  13: "חייל",
  14: "בוגר",
};

export const clubCategories = {
  basketball: "כדורסל",
  soccer: "כדורגל",
  handball: "כדוריד",
  volleyball: "כדורעף ",
  football: "פוטבול",
  swimming: "שחיה",
  taekwondo: "טאקוונדו",
  badminton: "בדמינטון",
  waterpolo: "כדורמים ",
  fencing: "סייף",
  triathlon: "טריאתלון",
  judo: "ג׳ודו",
  chess: "שחמט",
  bouldering: "בולדר",
  athletics: "אתלטיקה",
  rugby: "רוגבי",
  rhythmic_gymnastics: "התעמלות אומנותית",
  artistic_gymnastics: "התעמלות מכשירים",
  catchball: "כדורשת",
  wrestling: "היאבקות",
  surfing: "גלישה",
  acrobatics: "אקרובטיקה",
  table_tennis: "טניס שולחן",
  archery: "קשתות",
  weightlifting: "הרמת משקולות",
  aerobic_gymnastics: "התעמלות אירובית",
  general: "כללי",
  climbing: "טיפוס",
  cycling: "אופניים",
  dancing: "ריקוד",
};
