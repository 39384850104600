import React from "react";
import {
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  ListItemText,
  Divider,
  Button,
  Typography,
  makeStyles,
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/PersonOutline";
import ExitToAppIcon from "@material-ui/icons/ExitToAppOutlined";
import PasswordIcon from "@material-ui/icons/VpnKeyOutlined";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { clubUrl } from "../../lib/helpers";

function ProfileMenu(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const history = useHistory();
  const { clubId } = useParams();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleItemClick = (to) => (event) => {
    history.push(clubUrl(clubId, to));
    handleClose(event);
  };

  return (
    <div>
      <Button
        onClick={handleToggle}
        ref={anchorRef}
        className={classes.profile}
        disableRipple
      >
        <PersonIcon className={classes.profileIcon} />
        <Typography variant="h6">
          {props.parent && props.parent.name}
        </Typography>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper elevation={0} className={classes.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open}>
                  <MenuItem
                    onClick={handleItemClick("profile")}
                    className={classes.menuItem}
                  >
                    <div className={classes.menuItemIcon}>
                      <PersonIcon fontSize="small" />
                    </div>
                    <ListItemText primary="הפרופיל שלי" />
                  </MenuItem>
                  <MenuItem
                    onClick={handleItemClick("profile/changePassword")}
                    className={classes.menuItem}
                  >
                    <div className={classes.menuItemIcon}>
                      <PasswordIcon fontSize="small" />
                    </div>
                    <ListItemText primary="שינוי סיסמה" />
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={handleItemClick("logout")}
                    className={classes.menuItem}
                  >
                    <div className={classes.menuItemIcon}>
                      <ExitToAppIcon fontSize="small" />
                    </div>
                    <ListItemText primary="יציאה" />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    border: "1px solid #DDD",
    // boxShadow:
    padding: 0,
    // margin: 0,
    width: 150,
  },
  profile: {
    minWidth: 100,
    color: theme.appBar.color,
  },
  profileIcon: {
    fontSize: 30,
    border: "1px solid ",
    borderRadius: 20,
    padding: 1,
    marginRight: 5,
  },
  menuItem: { paddingLeft: 15, paddingRight: 15 },
  menuItemIcon: {
    width: 30,
    display: "flex",
    alignItems: "center",
  },
}));

const mapStateToProps = (state) => {
  return {
    parent: state.auth.parent,
    isLoadingParent: state.auth.isLoadingParent,
  };
};

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMenu);
