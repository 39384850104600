import React from "react";
import { Container, Grid, TextField } from "@material-ui/core";

import MenuItem from "../../components/base/MenuItem";
import { classes as playerClasses } from "../../lib/dictionaries";

export default function UpdatePlayerDetailsForm(props) {
  return (
    <div style={{ justifyContent: "flex-start" }}>
      <Container style={{ margin: 0 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="מספר הטלפון של הספורטאי/ת"
              fullWidth
              value={props.state.phone}
              onChange={(e) => props.onChange({ phone: e.target.value })}
              error={Object.prototype.hasOwnProperty.call(
                props.state.errors,
                "phone"
              )}
              helperText={
                props.state.errors.phone ||
                "במידה ולספורטאי/ת אין טלפון יש להשאיר שדה זה ריק"
              }
              margin="none"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              error={Object.prototype.hasOwnProperty.call(
                props.state.errors,
                "class"
              )}
              label="כיתה"
              select
              value={props.state.class}
              onChange={(e) => props.onChange({ class: e.target.value })}
              helperText={props.state.errors.class}
              margin="none"
            >
              {Object.entries(playerClasses).map(([id, title]) => (
                <MenuItem key={id} value={id}>
                  {title}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="school"
              name="school"
              label="בית ספר"
              fullWidth
              autoComplete="school"
              value={props.state.school}
              onChange={(e) => props.onChange({ school: e.target.value })}
              error={Object.prototype.hasOwnProperty.call(
                props.state.errors,
                "school"
              )}
              helperText={props.state.errors.school}
              margin="none"
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
