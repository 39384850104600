import React from "react";
import {
  makeStyles,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table as MUTable,
} from "@material-ui/core";
import clsx from "clsx";

export default function Table(props) {
  const classes = useStyles();
  return (
    <MUTable>
      <TableHead>
        <TableRow>
          {props.columns.map((column) => (
            <TableCell
              className={clsx(classes.header, classes.tableCell)}
              key={column.key}
              style={{
                ...(column.width ? { width: column.width } : {}),
                ...(column.minWidth ? { minWidth: column.minWidth } : {}),
              }}
            >
              {column.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {props.rows.map((row) => (
          <TableRow key={row._id} className={classes.row}>
            {props.columns.map((column) => (
              <TableCell
                key={column.key}
                {...column.tableCellProps}
                className={classes.tableCell}
              >
                {props.renderCell(row, column)}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </MUTable>
  );
}

const useStyles = makeStyles(() => ({
  tableCell: {
    border: 0,
  },
  header: {
    color: "#495057",
    fontWeight: "bold",
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: "rgba(0,0,0,0.03)",
    },
    "&:hover": {
      backgroundColor: "#e0f3ff",
    },
  },
}));
