import React from "react";
import { Container } from "@material-ui/core";

import Paper from "../components/Paper";
import TermsText from "./TermsText";

function Privacy() {
  return (
    <Container maxWidth="md">
      <Paper title="תנאי שימוש">
        <TermsText />
      </Paper>
    </Container>
  );
}

export default Privacy;
