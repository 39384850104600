import React from "react";
import {
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
  Button,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { clubUrl } from "../../lib/helpers";

export default function ErrorDialog(props) {
  const handleClose = () => !props.permanent && props.onClose();
  return (
    <Dialog
      dir="rtl"
      open={props.open}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>{props.error.title}</DialogTitle>
      {props.error.text && (
        <DialogContent dividers>{props.error.text}</DialogContent>
      )}
      <DialogActions>
        {props.permanent ? (
          <Button
            component={RouterLink}
            to={clubUrl(props.clubInternalName, "")}
            variant="outlined"
          >
            חזרה לדף הבית
          </Button>
        ) : (
          <Button variant="outlined" onClick={props.onClose}>
            אישור
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
