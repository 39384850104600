import { Container } from "@material-ui/core";
import React from "react";
import { withRouter } from "react-router";

import Paper from "../components/Paper";
import axios from "../lib/axios";

function TermsDialog(props) {
  const [terms, setTerms] = React.useState(null);

  React.useEffect(async () => {
    const res = await axios.get(`/clubs/${props.match.params.clubId}/terms`);
    setTerms(res.data);
  }, [props.match.params.clubId]);

  return (
    <Container maxWidth="md">
      <Paper title="תקנון הרישום ותשלום" bigTitle titleBackground>
        <div
          dangerouslySetInnerHTML={{
            __html: terms,
          }}
        />
      </Paper>
    </Container>
  );
}

export default withRouter(TermsDialog);
