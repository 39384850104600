import React from "react";
import { Container } from "@material-ui/core";

import Paper from "../components/Paper";
import PrivacyText from "./PrivacyText";

function Privacy() {
  return (
    <Container maxWidth="md">
      <Paper title="מדיניות פרטיות">
        <PrivacyText />
      </Paper>
    </Container>
  );
}

export default Privacy;
