import React from "react";
import { Button, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

import Table from "../../../components/table/Table";
import { clubUrl, displayPriceNG } from "../../../lib/helpers";

const columns = [
  { key: "player", label: "ספורטאי/ת" },
  { key: "team", label: "קבוצה" },
  // { key: "coach", label: "מאמן" },
  { key: "price", label: "עלות" },
  { key: "actions", label: "" },
];

export default function DesktopSubscriptions(props) {
  const classes = useStyles();
  let desc;

  const renderCell = (subscription, column) => {
    switch (column.key) {
      case "player":
        return (
          <ListItem className={classes.listItem}>
            <ListItemText primary={subscription.clubPlayer.firstName} />
          </ListItem>
        );

      case "team":
        return (
          <ListItem className={classes.listItem}>
            <ListItemText
              primary={subscription.team.baseName}
              secondary={subscription.product ? subscription.product.title : ""}
            />
          </ListItem>
        );

      case "coach":
        return (
          <ListItem className={classes.listItem}>
            <ListItemText
              primary={subscription.team.clubCoach.name}
              secondary={subscription.team.clubCoach.phone}
            />
          </ListItem>
        );

      case "price":
        desc = subscription.product
          ? subscription.debt > 0
            ? ` יתרת תשלום - ${displayPriceNG(subscription.debt)}`
            : `שולם`
          : "";

        if (
          subscription.specialRequest &&
          subscription.specialRequest.status === "open" &&
          subscription.specialRequest.type === "discount"
        ) {
          desc = (
            <span>
              {desc}
              <div>
                <strong>הוגשה בקשה מיוחדת</strong>
              </div>
            </span>
          );
        }

        return (
          <ListItem className={classes.listItem} ContainerComponent="div">
            <ListItemText
              primary={
                subscription.product
                  ? displayPriceNG(subscription.totalPrice)
                  : "-"
              }
              secondary={desc}
            />
          </ListItem>
        );

      case "actions":
        return (
          subscription.debt > 0 &&
          (props.clubConfig.paymentMethods === "cc" ||
            props.clubConfig.paymentMethods === "all") && (
            <Button
              variant="contained"
              color="primary"
              component={RouterLink}
              to={clubUrl(
                props.clubId,
                `purchase?clubPlayerId=${subscription.clubPlayer._id}&mode=payment&subscriptionId=${subscription._id}`
              )}
            >
              תשלום
            </Button>
          )
        );

      default:
        break;
    }
  };

  return (
    <Table
      columns={columns}
      rows={props.subscriptions}
      renderCell={renderCell}
    />
  );
}

const useStyles = makeStyles(() => ({
  listItem: {
    padding: 0,
  },
  bottomContainer: {
    height: 50,
    padding: 20,
    paddingBottom: 5,
    display: "flex",
    alignItems: "center",
  },
}));
