import React from "react";

export default function PrivacyText() {
  return (
    <div>
      <p>
        TrainLit (&quot;החברה&quot;, &quot;אנחנו&quot; או &quot;אנו&quot;)
        מחויבים להגן על פרטיות המשתמשים שלנו (&quot;משתמש/ים&quot; או
        &quot;אתה&quot;). מדיניות פרטיות זו (&quot;מדיניות הפרטיות&quot;) נוצרה
        כדי ליידע אותך לגבי הדרך שבה אנו מנהלים, אוספים, מאחסנים ועושים שימוש
        במידע שאתה מספק לנו באמצעות אתר האינטרנט של TrainLit
        (&quot;האפליקציה&quot;). בנוסף, מדיניות הפרטיות שלהלן מתארת את האמצעים
        בהם אנו נוקטים על מנת להגן על בטיחותו של המידע הנאסף על ידנו וכיצד ניתן
        ליצור איתנו קשר בנוגע לנוהלי הפרטיות שלנו.
      </p>

      <p>
        אנא שים לב שהיקף מדיניות פרטיות זו מוגבל רק למידע שנאסף על-ידינו באמצעות
        השימוש שאתה עושה בשירותי האפליקציה שלנו (בין שהשימוש כאמור הוא באמצעות
        כל מחשב בו נעשה שימוש כדי לקבל גישה לשירותי החברה, לרבות ללא הגבלה, מחשב
        נייח, מחשב נייד, טלפון נייד או כל מכשיר אלקטרוני אחר). באמצעות שימוש
        באפליקציה שלנו, אתה מסכים למדיניות הפרטיות שלנו.
      </p>

      <p>
        TrainLit פועלת לקידום מועדוני ספורט, פיתוח הספורטאי הישראלי ועידוד
        פעילות ספורטיבית בקרב בני נוער. באמצעות TrainLit מועדוני הספורט בישראל
        יוכלו לחסוך זמן רב המושקע כיום בעבודה מנהלית, ולנצל את הזמן שנחסך לטובת
        קידום ופיתוח ספורטאים צעירים. כחלק מתפקידה, החברה פועלת לעידוד אימון
        עצמאי לאחר שעות האימונים הרשמיות, תרגול נוסף ולמידה אישית, ויצירת מארג
        חניכה צמוד בין מאמן למתאמן. TrainLit מאפשרת למאמנים ולמנהל המועדון לנהל
        את כל המידע הרלוונטי לגבי השחקנים הרשומים במסגרת הפעילות השנתית של
        המועדון - תשלומים, מעקבי נוכחות, פרטי התקשרות, מעקב יכולות אישיות,
        סרטוני ביצוע ועוד. (&quot;השירות &quot;)
      </p>

      <b>
        <u>
          בעת השימוש באתר שלנו, אנו עשויים לאסוף ממך שני סוגי מידע עליך בקשר
          לשירותי האפליקציה:
        </u>
      </b>

      <p>
        <b>&quot;מידע אישי&quot;</b> – משמעו מידע עליך שניתן להשתמש בו על מנת
        ליצור עמך קשר או לזהות אותך. חלק מהמידע מזהה אותך באופן אישי, כגון שמך,
        כתובתך, דואר האלקטרוני שלך, מספר הטלפון שלך וכיו&quot;ב. זהו המידע שאתה
        מוסר ביודעין, בהסכמתך, לטובת קבלת מידע ושירותים שמוצגים באפליקציה שלנו.
      </p>

      <p>
        <b>&quot;מידע לא אישי&quot;</b>– משמעו מידע שכשלעצמו, לא מאפשר לעשות
        שימוש לשם זיהוי או יצירת קשר איתך. זהו מידע סטטיסטי ומצטבר. לדוגמה,
        פרסומות שקראת באתר, העמודים שבהם צפית, ההצעות ושירותי האפליקציה שעניינו
        אותך ועוד.
      </p>

      <p>
        אנו רשאים לאסוף מידע לא אישי ולהשתמש בו לכל מטרה באמצעות כל אחת מהשיטות
        לעיל וכן באופן אוטומטי או באופן אחר באמצעות השימוש שתעשה בשירותי
        האפליקציה.
      </p>

      <p>
        אינך מחויב לפי חוק למסור לנו את פרטי המידע האישי המתוארים להלן, אך לצורך
        השימוש בשירותי האפליקציה באופן המיטבי, הינך נדרש לשתף עם האפליקציה את
        פרטי המידע האישי המפורטים. במידה ותסרב למסור חלק מהמידע האישי שלך, ייתכן
        ולא תוכל לעשות שימוש בשירותי האפליקציה או שתוכל לעשות שימוש חלקי בלבד.
      </p>

      <ol>
        <li>
          <b>
            <u>כיצד אנו אוספים את המידע שלך? </u>
          </b>
          <p>
            אנו אוספים מידע אישי ומידע לא אישי ממך באמצעות יצירת חשבון שתתבצע
            לאחר הרשמה לשירות.
          </p>

          <ol>
            <li>
              <u>רישום שחקן:</u> כדי להירשם לאתר יש להזין שם מלא, כתובת, טלפון,
              אימייל, כיתה, תאריך לידה, שמות ההורים וטלפונים. לאחר ההרשמה
              הראשונית, גישה לשירות תתאפשר באמצעות יצירת חשבון שבמסגרתו תקבל שם
              משתמש וסיסמה. חשבון זה יאפשר לך לשתף באמצעות האפליקציה סרטוני
              ביצוע, כלומר סרטונים קצרים שבאמצעותם תציג את התרגילים המקצועיים
              שנדרשת לבצע, לטובת לקבלת משוב ביצוע.
            </li>

            <li>
              <u>רישום מאמן:</u> במידה והינך מאמן כדי להירשם יש להזין שם מלא,
              טלפון וכתובת אימייל. לאחר ההרשמה הראשונית, גישה לשירות תתאפשר
              באמצעות יצירת חשבון שבמסגרתו תקבל שם משתמש וסיסמה. חשבון זה יאפשר
              לך לצפות בסרטוני הביצוע שהשחקנים משתפים, להעניק להם משוב מושכל,
              ולבצע מעקבי ביצוע, נוכחות, פרטי התקשרות.
            </li>
            <li>
              <u>רישום מנהל:</u> במידה והינך מנהל, הרישום יתבצע באמצעות צוות
              החברה, כאשר הפרטים שתתבקש להזין יכללו את שמך, כתובת הדואר
              האלקטרוני שלך, פרטי המועדון שאליו אתה משתייך ועוד. לאחר ההרשמה
              הראשונית, גישה לשירות תתאפשר באמצעות יצירת חשבון שבמסגרתו תקבל שם
              משתמש וסיסמה. חשבון זה יאפשר לך לבצע מעקב אחר פעילות המאמנים
              בשירות, וכן את היכולת לבחון את הביצוע, קבלת התשלומים, נוכחות
              השחקנים והמאמנים, ופרטי ההתקשרות שלהם.
            </li>
            <li>
              <u>צור קשר:</u> במידה ואתה מעוניין ליצור עימנו קשר, באפשרותך לעשות
              זאת באמצעות האפליקציה תוך הזנת שמך וכתובת המייל שלך. איסוף מידע זה
              יאפשר לנו לספק לך את השירותים המבוקשים על ידך.
            </li>
          </ol>
          <p></p>
        </li>

        <li>
          <b>
            <u>כיצד אנו משתמשים בפרטים שלך? </u>
          </b>
          <p>אנו משתמשים בפרטים שנאספו ממך באופן הבא:</p>

          <ol>
            <li>
              <u>ניהול המערכת ואספקת שירותי האפליקציה:</u> אנו עשויים להשתמש
              במידע אישי ובמידע לא אישי על מנת לספק לך את שירותי האפליקציה, ועל
              מנת לנהל, לתמוך, לפתור בעיות טכניות, להגן ולשפר את שירותי
              האפליקציה, לוודא תפעול נאות של השירות שלנו וכן למטרות יצירת קשר
              איתך.
            </li>
            <li>
              <u>התראות:</u> אנו עשויים להשתמש במידע האישי על מנת לשלוח לך
              הודעות הקשורות לשירות האפליקציה, ניהול אירועים, משימות, וכן לצורך
              מתן הוראות או עזרה הקשורות לשירותי האפליקציה ו/או לשימוש שלך בו.
              כל אימת שתעשה שימוש בשירותי האפליקציה, אנו רשאים להשתמש במידע
              האישי שלך על מנת להשלים כל פעולה רלוונטית דרושה וליצור איתך קשר
              בנוגע לשירותי האפליקציה או לשאלה אחרת.
            </li>
            <p>
              אנו משתמשים בכתובת האימייל שסיפקת כדי לשלוח לך מידע ועדכונים
              הנוגעים לחשבון שלך בשירות, חדשות מזדמנות, מידע על מוצרים שיכולים
              להיות רלוונטים וכד&apos;. אם בשלב כלשהו תעדיף להסיר את עצמך מרשימת
              התפוצה, על מנת שלא לקבל אימיילים בעתיד, אנו מצרפים הוראות מפורטות
              להסרה מרשימת התפוצה בתחתית כל הודעה אלקטרונית.
            </p>

            <li>
              <u>צירוף מידע אנונימי:</u> ככל המותר על פי הדין החל, אנו רשאים
              לצרף את סוגי הנתונים השונים שאנו אוספים ממך עם סוגי נתונים אחרים
              שאנו אוספים ממך, מהלקוחות והשותפים העסקיים האחרים שלנו. איסוף זה
              יתבצע באופן אנונימי ומצטבר ובצורה שלא מזהה אותך באופן אישי לרבות
              באמצעות העברת מידע שעבר גיבוב (“hashing”). מידע אנונימי, סטטיסטי
              או מצטבר זה, ישמש רק על מנת לשפר את המוצרים והשירות שלנו. אם מידע
              לא אישי נאסף לצורך פעילות שדורשת גם מידע אישי, אנו רשאים לצרף את
              המידע הלא אישי שלך עם המידע האישי שלך בניסיון לספק לך חווית משתמש
              טובה יותר, לשפר את ערך ואיכות שירות האפליקציה ולנתח כיצד נעשה
              שימוש בשירות.
            </li>
          </ol>
          <p></p>
        </li>

        <li>
          <b>
            <u>מסירת המידע האישי שלך</u>
          </b>
          <p>
            אתה מבין ומסכים לכך שייתכן ונידרש למסור מידע אישי אם נידרש לכך על פי
            חוק או אם נהיה סבורים כי מסירה כאמור דרושה באופן סביר על מנת להימנע
            מחבות משפטית שלנו, לשם ציות להליכים משפטיים, לרבות, ומבלי לגרוע,
            זימון לדין, לפי דרישת חוק, צו חיפוש או צו בית משפט, או על מנת להגן
            על רכוש וזכויות שלנו או של צד שלישי, להגן על בטיחות הציבור או של כל
            אדם, או למנוע או לעצור פעילות שנמצא שהיא בלתי חוקית, בלתי אתית או
            ברת תביעה או שיש סכנה שתהיה כזו.
          </p>

          <p>
            בכל המקרים של גישה ואיסוף נתונים, המידע האישי שתספק לא יימסר, יושכר,
            יושאל, יוחכר, יימכר או יופץ באופן אחר מרצון לצדדים שלישיים (להוציא
            שותפים עסקיים ו/או צדדים אחרים המפורטים במדיניות פרטיות זו) ואנו לא
            נשתף את המידע האישי אודותיך עם צדדים שלישיים למטרות דיוור (לרבות
            מטרות דיוור ישיר) ללא רשותך, אלא כפי שמפורט בתנאי מדיניות פרטיות זו.
            אנא שים לב שאנו רשאים למכור, להעביר או לשתף באופן אחר חלק או את כל
            הנכסים שלנו, לרבות המידע האישי שלך בקשר למיזוג, רכישה, ארגון מחדש או
            מכירה של הנכסים שלנו או במקרה של פשיטת רגל.
          </p>

          <p>
            אנו רשאים ועשויים לשתף מידע אישי עם צדדים שלישיים אמינים המסייעים
            לנו בתפעול האפליקציה שלנו, בניהול העסק שלנו או באספקת שירותי
            האפליקציה כגון שירותי סליקה שבאמצעותם מתבצעת העברת התשלומים לרכישת
            מוצר דרך האפליקציה. צדדים שלישיים או סוכנים אלה רשאים להשתמש במידע
            האישי שלך רק ככל הדרוש לבצע את השירותים שהם מספקים לנו והם נדרשים
            לנקוט באמצעי הגנה סבירים על מנת להגן ולשמור בסודיות על המידע האישי
            שלך.
          </p>

          <p>
            אנו רשאים ועשויים לשכור חברות ויחידים שהם צדדים שלישיים על מנת לסייע
            בביצוע שירותי האפליקציה, לספק את שירותי האפליקציה, לבצע משימות
            (לדוגמה, ללא הגבלה, שירותי תחזוקה, ניהול בסיס נתונים, ניתוח אתרים
            ושיפור השירותים) או לסייע לנו בניתוח האופן בו נעשה שימוש באתר.
          </p>
        </li>

        <li>
          <b>
            <u>שמירת המידע שלך </u>
          </b>
          <p>
            החברה מאחסנת מידע שנאסף בקשר לשירותי האפליקציה על שרתיה המקומיים,
            המספקים רמת אבטחת מידע גבוהה למידע שלך. כמו כן, אנו נוקטים בצעדי
            הגנה סבירים מבחינה מסחרית על מנת להגן על המידע האישי שלך. על מנת
            להפחית את הסיכון של גישה או מסירה בלתי מאושרת, ולטובת ניהול נתונים
            באופן מדויק ווידוא שימוש הולם במידע אישי, אנו נוקטים באמצעים פיזיים,
            אלקטרוניים ומנהליים מתאימים על מנת לשמור ולהגן על המידע אותו אנו
            מעבדים. במקרה של מסירה רשלנית, ננקוט בצעדים סבירים מבחינה מסחרית על
            מנת להגביל ולתקן את המסירה. עם זאת, אין אנו יכולים לערוב לכך שצדדים
            שלישיים בלתי מאושרים לא יצליחו לעולם לעקוף הליכים אלה או לעשות שימוש
            במידע כלכלי או במידע אישי למטרות בלתי הולמות. זוהי אחריותך להגן על
            כל הסיסמאות ושמות המשתמש בהם אתה עושה שימוש בגישה לאתר ולהודיע לנו
            מיד אם אתה יודע או חושד שהסודיות של כל סיסמה ו/או שם משתמש הופרו.
            אתה האחראי הבלעדי לכל שימוש בלתי מאושר שמתבצע באמצעות הסיסמה ושם
            המשתמש שלך.
          </p>
        </li>

        <li>
          <b>
            <u>שימוש ב-Cookies </u>
          </b>
          <p>
            אנו משתמשים ב-Cookies לצורך תפעולה השוטף והתקין של האפליקציה, ובכלל
            זה כדי לאסוף נתונים סטטיסטים אודות השימוש באתר, לאימות פרטים, כדי
            להתאים את האפליקציה להעדפותיך האישיות ולצרכי אבטחת מידע. דפדפנים
            מודרניים כוללים אפשרות להימנע מקבלת Cookies. אם אינך יודע כיצד לעשות
            זאת, בדוק בקובץ העזרה של הדפדפן שבו אתה משתמש.
          </p>
        </li>

        <li>
          <b>
            <u>שינוי או מחיקת המידע שלך</u>
          </b>
          <p>
            אנו נשמור את המידע האישי שלך כל עוד חשבונך פעיל או לפי הצורך על מנת
            לספק לך את שירותי האפליקציה, או כנדרש למלא אחר התחייבויותינו
            החוקיות, לפתור סכסוכים ולאכוף את ההסכמים שלנו. במידה ותרצה שנשנה או
            נמחק את הרישומים שלך במערכת שלנו, אנא צור עימנו קשר ב:
            support@trainlitapp.comואנו ננסה למלא את בקשתך. אנא שים לב שייתכן
            שיהיה בלתי אפשרי למחוק לחלוטין רישומים מבלי לשמור שאריות מידע בשל
            האופן שבו גיבוי נתונים נשמר על ידי הרשות או ספקיה.
          </p>
        </li>

        <li>
          <b>
            <u>פרטיות של קטינים </u>
          </b>
          <p>
            אנו מחויבים להגן על צרכי הפרטיות של ילדים ואנו ממליצים להורים
            ולאפוטרופוסים לקחת תפקיד פעיל בפעילויות ובאינטרסים של ילדיהם
            באינטרנט. האפליקציה שלנו מיועדת בין השאר, לשחקנים בין הגילאים 6 עד
            18. השימוש בשירות לשחקנים אלו, כפוף להסכמת ההורים, שתינתן למועדון
            שאליו הם משתייכים. אם הורה או אפוטרופוס נעשה/ית מודע לכך שילדו/ה
            מסר/ה לנו מידע אישי ללא הסכמתו/ה, עליו/ה לפנות אלינו ואנו נפעל באופן
            מיידי למחיקת המידע כאמור.
          </p>
        </li>
        <li>
          <b>
            <u>שינויים במדיניות הפרטיות </u>
          </b>
          <p>
            אנו שומרים לעצמנו את הזכות לשנות מדיניות פרטיות זו מעת לעת. הגרסה
            העדכנית ביותר של המדיניות היא הגרסה הקובעת לעניין השימוש שנעשה במידע
            האישי ובמידע הלא אישי שלך. במקרה שאנו, לפי שיקול דעתנו הבלעדי, נחליט
            שעדכונים של מדיניות הפרטיות מהווים שינוי מהותי, אנו נודיע לך על
            השינויים כאמור בהודעה שתפורסם באמצעות האפליקציה. על אף האמור לעיל,
            אין אנו מחויבים להודיע למשתמש על שינויים במדיניות זו שאינם מהותיים
            ולכן על המשתמשים לבקר מעת לעת בעמוד זה על מנת לעיין במדיניות הפרטיות
            העדכנית שלה הם כפופים.
          </p>
        </li>

        <li>
          <b>
            <u>זכות לעיין במידע </u>
          </b>
          <p>
            במידה ותרצה לעיין במידע האישי שלך שנמצא ברשותנו וכן לצורך בירור
            פרטים אחרים הקשורים למידע שלך שנמצא ברשותנו, הינך מוזמן ליצור עימנו
            קשר בכתובת support@trainlitapp.com.
          </p>
        </li>

        <li>
          <b>
            <u> יצירת קשר </u>
          </b>
          <p>
            אם יש לך שאלות או חששות בנוגע למדיניות פרטיות זו או בנוגע לכל נושא
            אחר בעניין שירותי האפליקציה, אנא צור עימנו קשר ב:
            support@trainlitapp.com
          </p>
        </li>

        <p>עדכון אחרון: 19 ביולי, 2020.</p>
      </ol>
      <p></p>
    </div>
  );
}
