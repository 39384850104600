import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Avatar,
  CircularProgress,
  Container,
  Grid,
  Hidden,
  Snackbar,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import moment from "moment";

import * as Actions from "../../store/actions";
import ParentForm from "../Parent/ParentForm";
import Button from "../../components/Button";
import { validateParent } from "../Purchase/helpers/validator";
import axios from "../../lib/axios";
import Paper from "../../components/Paper";
import AbstractBackground from "../../resources/images/paperBackground.jpg";
import { normalizeServerValidationErrors } from "../../lib/helpers";

class Profile extends Component {
  state = {
    parent: null,
    errors: {},
    instantValidate: false,
    isSnackOpen: false,
    snackKey: undefined,
    snackStatus: null,
    isLoading: false,
    uniqueIdError: null,
  };

  initParent = () => {
    this.setState({
      parent: { ...this.props.parent, id: this.props.parent.id || "" },
    });
  };

  componentDidMount = () => {
    if (this.props.parent) {
      this.initParent();
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (!prevProps.parent && this.props.parent) {
      this.initParent();
    }
  };

  onChange = async (changes) => {
    let errors;
    if (this.state.instantValidate) {
      errors = await validateParent({ ...this.state.parent, ...changes }, true);
    }

    this.setState((prevState) => {
      return {
        ...prevState,
        parent: { ...prevState.parent, ...changes },
        errors: errors || prevState.errors,
        uniqueIdError: changes.id ? false : prevState.uniqueIdError,
        isSnackOpen: false,
      };
    });
  };

  updateParent = async () => {
    const res = await axios.get(
      `/parents/${this.state.parent._id}?granularity=clubs`
    );
    this.props.setParent(res.data);
  };

  handleSubmit = async () => {
    const errors = await validateParent(this.state.parent, true);
    const isValid = Object.keys(errors).length === 0;

    if (isValid) {
      try {
        const { parent } = this.state;
        const data = {
          firstName: parent.firstName,
          lastName: parent.lastName,
          email: parent.email,
          street: parent.street,
          houseNumber: parent.houseNumber,
          city: parent.city,
          secondParentName: parent.secondParentName,
          secondParentPhone: parent.secondParentPhone,
        };
        if (!this.props.parent.id) {
          // add id for V0 parents
          data.id = parent.id;
        }
        this.setState({ isLoading: true });
        const res = await axios.patch(
          `/parents/${this.state.parent._id}`,
          data
        );

        this.updateParent();

        this.setState({
          isPopUpOpen: true,
          success: true,
          parentId: res.data._id,
          isLoading: false,
          isSnackOpen: true,
          snackStatus: "success",
          snackKey: moment().format(),
        });
      } catch (error) {
        let errors = {};
        let uniqueIdError = null;
        let isSnackOpen = true;

        if (
          error.response &&
          error.response.status === 422 &&
          error.response.data &&
          error.response.data.errors
        ) {
          uniqueIdError = error.response.data.errors.id === "unique";
          errors = normalizeServerValidationErrors(error.response.data.errors);
          // don't need to show both
          if (uniqueIdError) errors.id = "";
          isSnackOpen = false;
        }

        this.setState({
          success: false,
          isLoading: false,
          isSnackOpen,
          snackStatus: "error",
          uniqueIdError,
          errors,
        });
      }
    } else {
      this.setState({ errors, instantValidate: true });
    }
  };

  handleCloseSnack = () => {
    this.setState({ isSnackOpen: false });
  };

  renderAlert = () => (
    <Alert
      onClose={this.handleCloseSnack}
      severity={this.state.snackStatus}
      className={this.props.classes.alert}
    >
      {this.state.snackStatus === "success"
        ? "הפרופיל עודכן בהצלחה"
        : "אירעה שגיאה. אנא נסו מאוחר יותר"}
    </Alert>
  );

  render() {
    const { classes, parent } = this.props;
    return (
      <Container maxWidth="md">
        <Paper bodyClassName={classes.headerContainer}>
          <div className={classes.headPaper}>
            <Avatar />
            <Typography variant="h5">
              {parent && `${parent.firstName} ${parent.lastName}`}
            </Typography>

            {parent && (
              <div>
                <span style={{ fontWeight: "bold" }}>טלפון</span> {parent.phone}
                {parent.id && (
                  <span>
                    <span style={{ fontWeight: "bold", paddingRight: 30 }}>
                      ת.ז
                    </span>{" "}
                    {parent.id}
                  </span>
                )}
              </div>
            )}
          </div>
        </Paper>
        <Paper
          title="פרטים אישיים"
          paperProps={{ className: classes.container }}
        >
          <Grid container spacing={3}>
            {this.state.uniqueIdError && (
              <Grid item xs={12}>
                <Alert severity="error">
                  תעודת הזהות {this.state.parent.id} קיימת כבר במערכת
                </Alert>
              </Grid>
            )}
            <Grid item>
              {this.state.parent ? (
                <ParentForm
                  editMode
                  editId={!this.props.parent.id}
                  state={this.state.parent}
                  errors={this.state.errors}
                  onChange={this.onChange}
                  clubId={this.props.match.params.clubId}
                />
              ) : (
                <CircularProgress />
              )}
            </Grid>
            <Hidden xsDown>
              {this.state.isSnackOpen && this.renderAlert()}
            </Hidden>
            <Grid item container justifyContent="flex-end">
              <Button
                theme="action"
                onClick={this.handleSubmit}
                isLoading={this.state.isLoading}
                debounce
              >
                שמור
              </Button>
            </Grid>
          </Grid>
          <Hidden smUp>
            <Snackbar
              open={this.state.isSnackOpen}
              autoHideDuration={6000}
              onClose={this.handleCloseSnack}
              key={this.state.isSnackOpen ? this.state.snackKey : undefined}
            >
              {this.renderAlert()}
            </Snackbar>
          </Hidden>
        </Paper>
      </Container>
    );
  }
}

const styles = (theme) => ({
  headerContainer: {
    ...(theme.paper.header
      ? {}
      : {
          backgroundImage: `url(${AbstractBackground})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }),
  },
  headPaper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    margin: 10,
  },
  container: {
    marginTop: 20,
  },
  alert: {
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
  },
});

const mapStateToProps = (state) => {
  return {
    parent: state.auth.parent,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setParent: (parent) => dispatch(Actions.auth.setParent(parent)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Profile));
