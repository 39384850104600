import {
  Button as MuiButton,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import _ from "lodash";

export default function Button(props) {
  const classes = useStyles();
  const buttonProps = { ...props };
  delete buttonProps.isLoading;
  delete buttonProps.debounce;

  const onClick = props.debounce
    ? _.debounce(props.onClick, 200)
    : props.onClick;

  const handleClick = (e) => {
    e.preventDefault();
    onClick(e);
  };

  return (
    <MuiButton
      variant="outlined"
      {...buttonProps}
      onClick={props.onClick ? handleClick : null}
      disabled={props.disabled || props.isLoading}
      className={clsx(props.className, {
        [classes.action]: props.theme === "action",
      })}
    >
      {props.isLoading ? (
        <CircularProgress
          size={24}
          className={clsx({
            [classes.progressAction]: props.theme === "action",
          })}
        />
      ) : (
        props.children
      )}
    </MuiButton>
  );
}

const useStyles = makeStyles((theme) => ({
  action: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  progressAction: {
    color: "white",
  },
}));
