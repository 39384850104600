import axios from "../../lib/axios";

export const auth = (parentId, token, parent, version) => {
  return {
    type: "LOGIN",
    parentId,
    token,
    parent,
    version,
  };
};

export const login = (parentId, token, parent) => {
  return (dispatch) => {
    localStorage.setItem("parentId", parentId);
    localStorage.setItem("token", token);
    localStorage.setItem("version", "1");
    axios.defaults.headers.common["Authorization"] = token;
    dispatch(auth(parentId, token, parent));
  };
};

export const autoLogin = () => {
  return async (dispatch) => {
    const parentId = localStorage.getItem("parentId");
    const token = localStorage.getItem("token");
    const version = localStorage.getItem("version");

    dispatch(autoLoginStarted());

    if (parentId !== null && token !== null && version === "1") {
      axios.defaults.headers.common["Authorization"] = token;
      dispatch(auth(parentId, token, null, version));
    } else {
      dispatch(auth(null, null, null, null));
    }
    dispatch(autoLoginCompleted());
  };
};

export const logout = () => {
  localStorage.removeItem("parentId");
  localStorage.removeItem("token");
  localStorage.removeItem("version");
  delete axios.defaults.headers.common["Authorization"];

  return {
    type: "LOGOUT",
  };
};

export const autoLoginStarted = () => {
  return {
    type: "STATUS",
    loading: true,
  };
};

export const autoLoginCompleted = () => {
  return {
    type: "STATUS",
    loading: false,
  };
};

export const loadParent = () => {
  return async (dispatch, getState) => {
    const { parentId } = getState().auth;
    dispatch(setIsLoadingParent(true));
    const res = await axios.get(`/parents/${parentId}?granularity=clubs`);
    dispatch(setParent(res.data));
    dispatch(setIsLoadingParent(false));
  };
};

export const setParent = (parent) => {
  return {
    type: "SETPARENT",
    parent,
  };
};

export const setIsLoadingParent = (isLoadingParent) => {
  return {
    type: "SETISLOADINGPARENT",
    isLoadingParent,
  };
};
