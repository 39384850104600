import React from "react";
import { Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { withRouter } from "react-router";
import { clubUrl } from "../../lib/helpers";
import { Alert } from "@material-ui/lab";

function Confirmation(props) {
  const messages = {
    subscription: "ההרשמה הסתיימה בהצלחה!",
    payment: "התשלום בוצע בהצלחה!",
    store: "רכישת המוצרים הסתיימה בהצלחה!",
    discountRequest: "הבקשה הועברה למועדון!",
  };

  const renderContent = () => {
    const texts = [];
    if (
      props.mode === "subscription" &&
      props.subscriptionType === "internal" &&
      props.paymentMethod === "other"
    ) {
      texts.push(
        <Alert severity="info" key="1">
          שימו לב, ההרשמה תופעל רק לאחר הסדרת התשלום מול המועדון
        </Alert>
      );
    }

    if (props.paid) {
      texts.push(
        <Typography variant="subtitle1" key="2">
          <div>מספר אסמכתא: {props.transactionId}.</div> הקבלה נשלחה ל{" "}
          {props.email}
        </Typography>
      );
    }

    return texts;
  };

  return (
    <div>
      <Typography
        variant="h4"
        gutterBottom
        style={{ textAlign: "center", marginBottom: 40 }}
      >
        {props.discountRequest
          ? messages.discountRequest
          : messages[props.mode]}
      </Typography>
      {renderContent()}
      <div style={{ textAlign: "left", marginTop: 30 }}>
        <RouterLink color="secondary" to={clubUrl(props.match.params.clubId)}>
          חזרה לעמוד הבית
        </RouterLink>
      </div>
    </div>
  );
}

export default withRouter(Confirmation);
