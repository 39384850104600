const formatter = new Intl.NumberFormat("he-IL", {
  style: "currency",
  currency: "ILS",
  minimumFractionDigits: 0,
});

export const displayPriceNG = (price) => {
  return formatter.format(price);
};

export const displayPrice = (price) => {
  if (price >= 0) {
    return `${price} \u20AA`;
  } else {
    return `${Math.abs(price)}- \u20AA`;
  }
};

export const clubUrl = (clubId, path) =>
  `/clubs/${clubId}${path ? `/${path}` : ""}`;

export const generateErrorMessage = (type, formErrors, errorMessages = {}) => {
  if (type === "phone" && formErrors.phone === "unique") {
    return "מספר הטלפון קיים במערכת";
  }

  if (type === "email" && formErrors.email === "unique") {
    return "כתובת האימייל קיימת במערכת";
  }

  if (formErrors[type] === "required") {
    return errorMessages.required || "שדה חובה";
  }

  if (formErrors[type] === "invalid") {
    return errorMessages.invalid || "שדה לא תקין";
  }

  return formErrors[type];
};

export const normalizeServerValidationErrors = (serverErrors) => {
  if (Array.isArray(serverErrors)) {
    const errors = {};
    serverErrors.forEach((e) => {
      if (e.param === "phone" && e.error === "unique") {
        errors[e.param] = "מספר הטלפון קיים במערכת";
      } else {
        errors[e.param] = "שדה לא תקין";
      }
    });
    return errors;
  }

  if (serverErrors) {
    if (serverErrors.phone === "unique") {
      serverErrors.phone = "מספר הטלפון קיים במערכת";
    }

    if (serverErrors.id === "unique") {
      serverErrors.id = "תעודת הזהות קיימת במערכת";
    }
  }

  return serverErrors;
};

export const roundPrice = (price) => {
  return Math.round(price * 100) / 100;
};
