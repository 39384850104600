import React from "react";
import { Route } from "react-router-dom";

import Layout from "./Layout";
import clubsConfig from "../config/clubs.json";
import PageNotFound from "../containers/PageNotFound";
import NoAuthLayout from "../containers/Auth/NoAuthLayout";

const LayoutRoute = ({ component: Component, ...rest }) => {
  const LayoutComponent = rest.noAuth ? NoAuthLayout : Layout;

  return (
    <Route
      {...rest}
      render={(matchProps) => {
        const clubId = matchProps.match.params.clubId;

        return rest.noClub || clubsConfig.clubs[clubId] ? (
          <LayoutComponent {...matchProps} {...rest.layoutProps}>
            <Component {...matchProps} />
          </LayoutComponent>
        ) : (
          <PageNotFound />
        );
      }}
    />
  );
};

export default LayoutRoute;
