import React from "react";
import EditIcon from "@material-ui/icons/Edit";
import {
  Grid,
  TextField,
  MenuItem,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  withStyles,
} from "@material-ui/core";

import config from "../../config";
import PciLogo from "../../images/pci4.png";
import VisaLogo from "../../images/visa.png";
// import AmexLogo from "../../images/amex.png";
import MastercardLogo from "../../images/mastercard.png";

class CreditCardForm extends React.Component {
  state = { open: false };

  handleToken = (e) => {
    if (e.data && e.data.messageType === "newCard") {
      if (e.data.success) {
        this.props.onChange({
          token: e.data.TranzilaTK,
          expiration: `${e.data.expmonth}/${e.data.expyear}`,
          ccResponse: e.data,
          last4: e.data.TranzilaTK.slice(e.data.TranzilaTK.length - 4),
        });
        this.setState({ open: false });
      } else {
        this.setState({ error: true });
      }
    }
  };

  componentWillUnmount = async () => {
    window.removeEventListener("message", this.handleToken);
  };

  componentDidMount = async () => {
    window.addEventListener("message", this.handleToken);
  };

  render() {
    const { classes } = this.props;
    const successUrl = decodeURIComponent(
      `${config.selfUrl}/iframeResponder?success=true&`
    );

    const failureUrl = decodeURIComponent(
      `${config.selfUrl}/iframeResponder?success=false&`
    );

    const terminalName =
      process.env.NODE_ENV === "production"
        ? (this.props.clubConfig.payments || {}).terminalName
        : "tltest";
    const transmode = terminalName === "tltest" ? "AK" : "VK";

    const url = `https://direct.tranzila.com/${terminalName}/iframenew.php?nologo=1&buttonLabel=המשך&sum=1&hidesum=1&currency=1&cred_type=1&tranmode=${transmode}&lang=il&trTextColor=000000&success_url_address=${successUrl}&fail_url_address=${failureUrl}`;

    let numberOfPayments =
      (this.props.clubConfig.payments || {}).numberOfPayments === "noOptions"
        ? [this.props.maxPaymentsNumber]
        : [...Array(this.props.maxPaymentsNumber).keys()].map((i) => i + 1);

    {
      /* patch for hapoel pt school - only paying for subproduct (fees) */
    }
    if (
      this.props.cart.seasonProducts &&
      this.props.cart.seasonProducts.length > 0 &&
      this.props.cart.seasonProducts[0].price === 0
    ) {
      numberOfPayments = [1];
    }

    return (
      <React.Fragment>
        <Dialog
          dir="rtl"
          onClose={() => this.setState({ open: false })}
          open={this.state.open}
        >
          <DialogTitle id="simple-dialog-title">הוספת כרטיס אשראי</DialogTitle>

          <DialogContent dividers>
            {this.state.error ? (
              <div>פרטי האשראי אינם תקינים</div>
            ) : (
              <React.Fragment>
                <iframe
                  width="100%"
                  height="400px"
                  title="test"
                  style={{
                    borderWidth: 0,
                  }}
                  scrolling="no"
                  src={url}
                />

                <div className={classes.imageRow}>
                  <img src={PciLogo} className={classes.pciLogo} />
                </div>

                <div className={classes.imageRow}>
                  <img src={VisaLogo} className={classes.creditCardLogo} />
                  <img
                    src={MastercardLogo}
                    className={classes.creditCardLogo}
                  />
                  {/* <img src={AmexLogo} className={classes.creditCardLogo} /> */}
                </div>
              </React.Fragment>
            )}
          </DialogContent>
          {this.state.error && (
            <DialogActions>
              <Button
                onClick={() => this.setState({ open: false, error: false })}
              >
                אישור
              </Button>
            </DialogActions>
          )}
        </Dialog>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={7}>
            <TextField
              id="ccNumber"
              name="ccNumber"
              label="מספר כרטיס"
              error={this.props.state.errors.cc !== undefined}
              helperText={this.props.state.errors.cc}
              value={
                this.props.state.last4
                  ? `xxxx-xxxx-xxxx-${this.props.state.last4}`
                  : ""
              }
              onClick={() => {
                this.setState({ open: true });
              }}
              fullWidth
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment
                    position="start"
                    onClick={() => {
                      this.props.onChange({ token: null, ccResponse: null });
                      this.setState({ open: true });
                    }}
                  >
                    <EditIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {this.props.showPayments && (
            <Grid item xs={12} sm={7}>
              <TextField
                id="lastName"
                name="lastName"
                label="מספר תשלומים"
                fullWidth
                select
                value={this.props.state.numberOfPayments}
                onChange={(event) =>
                  this.props.onChange({ numberOfPayments: event.target.value })
                }
                error={this.props.state.errors.numberOfPayments !== undefined}
                helperText={this.props.state.errors.numberOfPayments}
              >
                {numberOfPayments.map((i) => (
                  <MenuItem value={i} key={i} className={classes.menuItem}>
                    {i}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
        </Grid>
      </React.Fragment>
    );
  }
}

const styles = {
  menuItem: {
    display: "flex",
    justifyContent: "flex-end",
  },
  creditCardLogo: {
    height: 30,
    marginLeft: 10,
    marginRight: 10,
  },
  pciLogo: {
    height: 70,
    selfAlign: "center",
  },
  imageRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

export default withStyles(styles)(CreditCardForm);
