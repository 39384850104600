import React, { useState } from "react";
import {
  Grid,
  Hidden,
  makeStyles,
  Snackbar,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import { Alert } from "@material-ui/lab";

import PurchaseProduct from "../../components/store/PurchaseProduct";
import PurchaseProductDialog from "../../components/store/PurchaseProductDialog";

export default function MerchandiseForm(props) {
  const classes = useStyles();
  const [currentProduct, setCurrentProduct] = useState(null);
  const [snackbarDetails, setSnackbarDetails] = useState({
    isOpen: false,
    key: undefined,
    status: null,
  });

  React.useEffect(() => {
    const onHashChange = () => {
      if (window.location.hash !== "#productDialog") {
        setCurrentProduct(null);
      }
    };
    window.addEventListener("hashchange", onHashChange);
    return () => window.removeEventListener("hashchange", onHashChange);
  }, []);

  const openProductDialog = (product) => () => {
    setCurrentProduct(product);
    window.location.hash = "#productDialog";
  };

  const handleChange = (quantity, playerId, optionId) => {
    const player = props.players.find((p) => p._id === playerId);
    props.onProductChange(currentProduct, quantity, player, optionId);

    setSnackbarDetails({ isOpen: true, key: moment().format() });
    window.history.back();
  };

  const renderMerchandise = () => (
    <div>
      <PurchaseProductDialog
        isOpen={currentProduct !== null}
        product={currentProduct}
        onClose={() => {
          window.history.back();
        }}
        players={props.players}
        onAddItem={handleChange}
      />

      <Grid container spacing={3}>
        {props.merchandise.map((product) => (
          <Grid item xs={6} sm={12} key={product._id}>
            <PurchaseProduct
              handleCartChange={props.onProductChange}
              cart={props.cart}
              product={product}
              onShowProductDialog={openProductDialog(product)}
            />
          </Grid>
        ))}
      </Grid>

      {props.mode === "store" && props.state.errors.merchandise && (
        <div style={{ color: "red", fontSize: 18, marginTop: 10 }}>
          *יש לבחור מוצרים
        </div>
      )}
    </div>
  );

  const renderSnackbar = (position) => (
    <Snackbar
      anchorOrigin={{ vertical: position, horizontal: "center" }}
      open={snackbarDetails.isOpen}
      autoHideDuration={5000}
      onClose={() => setSnackbarDetails({ isOpen: false, key: null })}
      key={snackbarDetails.key}
    >
      <Alert elevation={6} variant="filled" severity="success">
        הפריטים הוספו בהצלחה
      </Alert>
    </Snackbar>
  );

  return (
    <>
      <Grid container spacing={3}>
        {props.mode !== "store" && (
          <Grid item xs={12}>
            <Typography variant="h6">מוצרים נלווים (לא חובה)</Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          {props.merchandise.length > 0 ? (
            renderMerchandise()
          ) : (
            <Typography className={classes.noData}>
              חנות המוצרים תפתח בקרוב!
            </Typography>
          )}
        </Grid>
      </Grid>
      <Hidden smUp>{renderSnackbar("top")}</Hidden>
      <Hidden smDown>{renderSnackbar("bottom")}</Hidden>
    </>
  );
}

const useStyles = makeStyles(() => ({
  noData: {
    marginTop: 20,
    fontWeight: "bold",
    marginBottom: 20,
    textAlign: "center",
  },
}));
