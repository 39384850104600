import React from "react";
import {
  Grid,
  Hidden,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Image } from "cloudinary-react";
import AddIcon from "@material-ui/icons/Add";

import { displayPrice } from "../../lib/helpers";

export default function PurchaseProduct({ product, onShowProductDialog }) {
  const classes = useStyles();

  const renderDesktop = () => (
    <Grid container spacing={2} className={classes.container}>
      <Grid item xs={5} sm={3} container direction="column">
        <Grid item>
          <Image
            version={product.imageVersion}
            className={classes.image}
            publicId={product.imagePublicId}
            height="120"
            width="120"
            alt="Logo"
          />
        </Grid>
      </Grid>
      <Grid item xs={7} sm={6} container>
        <Grid item xs container direction="column" spacing={2}>
          <Grid item xs>
            <Typography gutterBottom variant="subtitle1">
              {product.title}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {product.description || ""}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {displayPrice(product.price)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        sm={3}
        direction="column"
        alignItems="flex-end"
        justifyContent="center"
      >
        <Grid item>
          <IconButton onClick={onShowProductDialog}>
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderMobile = () => (
    <Grid container onClick={onShowProductDialog}>
      <Grid item xs={12}>
        <Image
          version={product.imageVersion}
          className={classes.image}
          publicId={product.imagePublicId}
          height="120"
          width="120"
          alt="Logo"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography gutterBottom variant="subtitle1">
          {product.title}
          <Typography variant="body2" color="textSecondary">
            {product.description || "כללי"}
          </Typography>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body2" color="textSecondary">
          {displayPrice(product.price)}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Hidden xsDown>{renderDesktop()}</Hidden>
      <Hidden smUp>{renderMobile()}</Hidden>
    </>
  );
}

const useStyles = makeStyles(() => ({
  container: {
    border: "1px solid #EEE",
    borderRadius: 5,
  },
  image: {
    borderRadius: 5,
  },
}));
