import React, { useEffect } from "react";
import { useState } from "react";
import AccessibleIcon from "@material-ui/icons/Accessibility";
import MonochromePhotosIcon from "@material-ui/icons/MonochromePhotos";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import UndoIcon from "@material-ui/icons/Undo";
import FormatUnderlinedIcon from "@material-ui/icons/FormatUnderlined";
import InvertColorsIcon from "@material-ui/icons/InvertColors";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import clsx from "clsx";
import {
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import { baseTheme } from "../layout/Theme";

const baseFeatures = {
  lowContrast: false,
  highContrast: false,
  underlineLinks: false,
  monochrome: false,
  fontSize: 1,
  allFeaturesOff: true,
  hide: false,
};

export default function Accessibility({ updateTheme }) {
  const [features, setFeatures] = useState({ ...baseFeatures });
  const classes = useStyles();

  useEffect(() => {
    load();
  }, []);

  const [isVisible, setIsVisible] = useState(false);

  const save = (newFeatures) => {
    localStorage.setItem("accessibilityFeatures", JSON.stringify(newFeatures));
  };

  const load = async () => {
    const json = await localStorage.getItem("accessibilityFeatures");
    if (json) {
      const newFeatures = JSON.parse(json);
      setFeatures(newFeatures);
      createTheme(newFeatures);
    }
  };

  const toggleFeature =
    (feature, key = null) =>
    () => {
      const newFeatures = { ...features };
      if (feature === "lowContrast" && !features.lowContrast) {
        newFeatures.highContrast = false;
      } else if (feature === "highContrast" && !features.highContrast) {
        newFeatures.lowContrast = false;
      }

      if (key) {
        newFeatures[feature] = key;
      } else {
        newFeatures[feature] = !newFeatures[feature];
      }
      newFeatures.allFeaturesOff =
        !newFeatures.lowContrast &&
        !newFeatures.highContrast &&
        !newFeatures.underlineLinks &&
        !newFeatures.monochrome &&
        newFeatures.fontSize === 1;

      setFeatures(newFeatures);
      createTheme(newFeatures);
      save(newFeatures);
    };

  const resetAllFeatures = () => () => {
    const newFeatures = { ...baseFeatures };
    setFeatures(newFeatures);
    createTheme(newFeatures);
    save(newFeatures);
  };

  const createTheme = (updatedFeatures) => {
    const theme = baseTheme();

    if (updatedFeatures.highContrast) {
      setContrast(theme, "high");
    }

    if (updatedFeatures.lowContrast) {
      setContrast(theme, "low");
    }

    if (updatedFeatures.underlineLinks) {
      setUnderlineLinks(theme);
    }

    if (updatedFeatures.monochrome) {
      setMonochrome(theme);
    }

    if (updatedFeatures.fontSize > 1) {
      setFontSize(theme, updatedFeatures.fontSize);
    }

    updateTheme(theme);
  };

  const setMonochrome = (theme) => {
    theme.overrides = theme.overrides || {};
    theme.overrides.MuiCssBaseline = theme.overrides.MuiCssBaseline || {};
    theme.overrides.MuiCssBaseline["@global"] =
      theme.overrides.MuiCssBaseline["@global"] || {};
    theme.overrides.MuiCssBaseline["@global"].img = {
      "-webkit-filter": "grayscale(100%)",
      filter: "grayscale(100%)",
    };
    theme.overrides.MuiAppBar = {
      root: {
        filter: "grayscale(100%)",
      },
    };
  };

  const setUnderlineLinks = (theme) => {
    theme.overrides = theme.overrides || {};
    theme.overrides.MuiCssBaseline = theme.overrides.MuiCssBaseline || {};
    theme.overrides.MuiCssBaseline["@global"] =
      theme.overrides.MuiCssBaseline["@global"] || {};
    theme.overrides.MuiCssBaseline["@global"].a =
      theme.overrides.MuiCssBaseline["@global"].a || {};
    theme.overrides.MuiCssBaseline["@global"].a.textDecoration =
      "underline !important";

    theme.overrides.MuiListItem.button = {
      textDecoration: "underline !important",
    };
  };

  const setContrast = (theme, contrast) => {
    let backgroundColor = contrast === "high" ? "#000" : "#c2d3fc";
    let hoverBackgroundColor = contrast === "high" ? "black" : "#a0abfa";
    let color = contrast === "high" ? "#FFFF00" : "#000";

    theme.palette.background.default = backgroundColor;
    theme.palette.background.paper = backgroundColor;
    theme.paper.header = backgroundColor;
    theme.playerCard.hover.background = hoverBackgroundColor;
    theme.playerCard.hover.color = color;
    theme.footer.background = backgroundColor;
    theme.footer.link = color;
    theme.footer.linkHover = color;
    theme.palette.background.drawer = backgroundColor;
    theme.drawer.icon = color;
    theme.appBar.background = backgroundColor;
    theme.appBar.color = color;
    theme.palette.text = { primary: color };
    theme.paper.border = `1px solid ${color}`;
    theme.palette.divider = color;

    if (contrast === "high") {
      theme.overrides = theme.overrides || {};
      theme.overrides.MuiCssBaseline = theme.overrides.MuiCssBaseline || {};
      theme.overrides.MuiCssBaseline["@global"] =
        theme.overrides.MuiCssBaseline["@global"] || {};

      theme.overrides.MuiCssBaseline["@global"].body = {
        color,
      };

      theme.overrides.MuiCssBaseline["@global"].a =
        theme.overrides.MuiCssBaseline["@global"].a || {};
      theme.overrides.MuiCssBaseline["@global"].a.color = `${color} !important`;

      theme.overrides.MuiCssBaseline["@global"].input = {
        color,
        borderColor: color,
      };

      theme.overrides.MuiFormLabel = {
        root: {
          color: `${color} !important`,
          borderColor: color,
        },
      };

      theme.overrides.MuiInputBase = {
        input: {
          color: `${color} !important`,
          borderBottom: "2px solid #FFFF00",
        },
      };

      theme.overrides.MuiCheckbox = {
        root: {
          color,
          "&$checked": { color: `${color} !important` },
        },
      };

      theme.overrides.MuiTypography = {
        root: { color: `${color} !important` },
      };

      theme.overrides.MuiStepIcon = {
        text: {
          color: `${color} !important`,
          fill: null,
        },
      };

      theme.overrides.MuiButton = {
        contained: {
          backgroundColor: `${color} !important`,
          color: `${backgroundColor} !important`,
        },
        outlined: {
          backgroundColor: `${color} !important`,
          color: `${backgroundColor} !important`,
        },
      };
    }
  };

  const setFontSize = (theme, size) => {
    const sizes = { 1: 16, 2: 15, 3: 12, 4: 10 };
    theme.typography = { htmlFontSize: sizes[size] };
  };

  return (
    <>
      <Tooltip title="תפריט נגישות">
        <Button
          onClick={() => setIsVisible(true)}
          className={classes.accessibilityButton}
          style={{ visibility: features.hide ? "hidden" : "visible" }}
        >
          <AccessibleIcon style={{ border: "0px solid" }} />
        </Button>
      </Tooltip>
      <Drawer
        variant="temporary"
        anchor="right"
        open={isVisible}
        onClose={() => setIsVisible(false)}
        style={{ zIndex: 1200 }}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
          dir: "rtl",
        }}
      >
        <div style={{ height: 70 }} />
        <List style={{ width: "100%" }}>
          <ListItem divider style={{ textAlign: "right", padding: 12 }}>
            <ListItemIcon>
              <IconButton size="small" onClick={() => setIsVisible(false)}>
                <CloseIcon />
              </IconButton>
            </ListItemIcon>
            <ListItemText>
              <Typography variant="h5" gutterBottom>
                תפריט נגישות
              </Typography>
            </ListItemText>
          </ListItem>

          <ListItem
            divider
            button
            onClick={toggleFeature("lowContrast")}
            selected={features.lowContrast}
            style={{ textAlign: "right" }}
          >
            <ListItemIcon>
              <InvertColorsIcon />
            </ListItemIcon>
            <ListItemText primary="ניגודיות עדינה" />
          </ListItem>
          <ListItem
            divider
            button
            onClick={toggleFeature("highContrast")}
            selected={features.highContrast}
          >
            <ListItemIcon>
              <InvertColorsIcon />
            </ListItemIcon>
            <ListItemText primary="ניגודיות גבוהה" />
          </ListItem>

          <ListItem
            divider
            button
            onClick={toggleFeature("monochrome")}
            selected={features.monochrome}
          >
            <ListItemIcon>
              <MonochromePhotosIcon />
            </ListItemIcon>
            <ListItemText primary="מונוכרום" />
          </ListItem>

          <ListItem
            divider
            button
            onClick={toggleFeature("underlineLinks")}
            selected={features.underlineLinks}
          >
            <ListItemIcon>
              <FormatUnderlinedIcon />
            </ListItemIcon>
            <ListItemText primary="הדגשת קישורים" />
          </ListItem>

          <ListItem divider>
            <ListItemIcon>
              <TextFieldsIcon />
            </ListItemIcon>
            <ListItemText style={{ margin: 0 }}>
              <span
                onClick={toggleFeature("fontSize", 1)}
                className={clsx(classes.fontSizeFeature, {
                  [classes.underline]: features.fontSize === 1,
                })}
                style={{ fontSize: 20 }}
              >
                א
              </span>
              <span
                onClick={toggleFeature("fontSize", 2)}
                className={clsx(classes.fontSizeFeature, {
                  [classes.underline]: features.fontSize === 2,
                })}
                style={{ fontSize: 28 }}
              >
                א
              </span>
              <span
                onClick={toggleFeature("fontSize", 3)}
                className={clsx(classes.fontSizeFeature, {
                  [classes.underline]: features.fontSize === 3,
                })}
                style={{ fontSize: 36 }}
              >
                א
              </span>

              <span
                onClick={toggleFeature("fontSize", 4)}
                className={clsx(classes.fontSizeFeature, {
                  [classes.underline]: features.fontSize === 4,
                })}
                style={{
                  fontSize: 42,
                }}
              >
                א
              </span>
            </ListItemText>
          </ListItem>

          <ListItem
            divider
            button
            onClick={() => {
              toggleFeature("hide")();
              setIsVisible(false);
            }}
            disabled={!features.allFeaturesOff}
          >
            <ListItemIcon>
              <VisibilityOffIcon />
            </ListItemIcon>
            <ListItemText primary="הסתר תפריט זה" />
          </ListItem>

          <ListItem button onClick={resetAllFeatures()}>
            <ListItemIcon>
              <UndoIcon />
            </ListItemIcon>
            <ListItemText primary="איפוס הגדרות" />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  accessibilityButton: {
    position: "absolute",
    left: -16,
    top: 100,
    display: "flex",
    flexDirection: "column",
    border: "1px solid",
    borderRadius: "20px",
    padding: "5px 0px 5px 0px",
    backgroundColor: theme.palette.background.default,
  },
  drawerPaper: {
    width: 260,
    padding: 0,
    alignItems: "center",
  },
  text: {
    fontSize: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid",
  },
  button: { width: 160 },
  fontSizeFeature: {
    marginLeft: 15,
    cursor: "pointer",
    "&:hover": { textDecoration: "underline" },
  },
  underline: {
    textDecoration: "underline",
  },
}));
