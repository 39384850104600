import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Image } from "cloudinary-react";
import clsx from "clsx";

import { clubUrl } from "../../lib/helpers";
import Paper from "../../components/Paper";
import { sendMessage } from "../../lib/wrapperHelper";

function MyClubs(props) {
  const clubs = props.parent ? props.parent.clubs : [];
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();

  const handleClubChange = (club) => () => {
    sendMessage("clubChange", {
      internalName: club.internalName,
      _id: club._id,
    });
    history.push(clubUrl(club.internalName));
  };

  const renderClubs = () => (
    <List>
      {clubs
        .filter((c) => !c.clubConfig.general.disabled)
        .map((club) => {
          const selected = params.clubId === club.internalName;
          return (
            <ListItem button onClick={handleClubChange(club)} key={club._id}>
              <ListItemAvatar>
                <Avatar>
                  <Image
                    publicId={club.clubConfig.general.logo.name}
                    version={club.clubConfig.general.logo.version}
                    height="40"
                    className={classes.logo}
                  />
                </Avatar>
              </ListItemAvatar>

              <ListItemText
                primary={club.clubConfig.general.name}
                primaryTypographyProps={{
                  className: clsx({ [classes.selected]: selected }),
                }}
              />
            </ListItem>
          );
        })}
    </List>
  );

  const renderNoClubs = () => {
    return (
      <Typography component="span" className={classes.noClub}>
        <div>לא נמצאו מועדונים</div>
        <div>לאחר הרישום לפעילות המועדון יופיע כאן</div>
      </Typography>
    );
  };

  return (
    <Paper
      className={classes.paper}
      title="המועדונים שלי"
      bigTitle
      bodyClassName={classes.paperBody}
      titleBackground
    >
      {clubs.length === 0 ? renderNoClubs() : renderClubs()}
    </Paper>
  );
}

const useStyles = makeStyles(() => ({
  paper: {
    height: "100%",
    width: "100%",
  },
  paperBody: {
    padding: 0,
  },
  logo: {
    backgroundColor: "#FFF",
    border: "1px solid #FAFAFA",
    borderRadius: 30,
  },
  noClub: {
    padding: 10,
    textAlign: "center",
  },
  selected: { fontWeight: "bold" },
}));

const mapStateToProps = (state) => {
  return {
    parent: state.auth.parent,
    isLoadingParent: state.auth.isLoadingParent,
    clubConfig: state.general.clubConfig,
  };
};

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(MyClubs);
