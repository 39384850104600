const initialState = {
  parentId: null,
  token: null,
  loading: true,
  isLoadingParent: false,
  parent: null,
  version: null,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        parentId: action.parentId,
        token: action.token,
        parent: action.parent,
        version: action.version,
      };
    case "LOGOUT":
      return {
        ...state,
        parentId: null,
        token: null,
        parent: null,
        isLoadingParent: false,
        version: null,
      };
    case "STATUS":
      return {
        ...state,
        loading: action.loading,
      };

    case "SETPARENT":
      return {
        ...state,
        parent: action.parent,
      };

    case "SETISLOADINGPARENT":
      return {
        ...state,
        isLoadingParent: action.isLoadingParent,
      };

    default:
      return state;
  }
};

export default auth;
