const initialState = {
  clubConfig: null,
  club: null,
  isLoadingClub: false,
  isMobile: false,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CLUB":
      return {
        ...state,
        clubConfig: action.clubConfig,
        club: action.club,
      };

    case "SET_LOADING_CLUB":
      return {
        ...state,
        isLoadingClub: action.isLoadingClub,
      };

    case "UNLOAD_CLUB":
      return {
        ...state,
        clubConfig: null,
        club: null,
      };

    case "SET_IS_MOBILE":
      return {
        ...state,
        isMobile: action.isMobile,
      };

    default:
      return state;
  }
};

export default auth;
