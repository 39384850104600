import React from "react";
import {
  Toolbar,
  AppBar as MUAppBar,
  Typography,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import BackIcon from "@material-ui/icons/ArrowBackIosRounded";
import { Link as RouterLink, useParams, useHistory } from "react-router-dom";

export default function AppBar(props) {
  const classes = useStyles();
  const { clubId } = useParams();
  const history = useHistory();

  const title = props.customTitle
    ? props.customTitle
    : props.clubConfig?.general.shortName;

  return (
    <MUAppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        {props.pageType === "clubSelect" ? (
          <div className={classes.clubSelectContainer}>
            <Typography variant="h5" className={classes.clubName} noWrap>
              חיפוש מועדון
            </Typography>
            <IconButton
              size="small"
              className={classes.clubName}
              onClick={() => history.goBack()}
            >
              <BackIcon />
            </IconButton>
          </div>
        ) : (
          <>
            <div className={classes.clubSelected}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={props.onDrawerToggle}
                className={classes.clubName}
              >
                <MenuIcon />
              </IconButton>
              <RouterLink
                color="secondary"
                to={`/clubs/${clubId}`}
                className={classes.homeLink}
              >
                <Typography variant="h5" className={classes.clubName} noWrap>
                  {title}
                </Typography>
              </RouterLink>

              {props.noBackButton ? (
                <span style={{ width: 32 }} />
              ) : (
                <IconButton
                  size="small"
                  className={classes.clubName}
                  onClick={() =>
                    props.clubId
                      ? history.replace(`/clubs/${props.clubId}`)
                      : history.goBack()
                  }
                >
                  <BackIcon />
                </IconButton>
              )}
            </div>
          </>
        )}
      </Toolbar>
    </MUAppBar>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    height: 65,
    background: theme.appBar.background,
    display: "flex",
    justifyContent: "center",
  },
  clubName: {
    color: theme.appBar.color,
  },
  homeLink: {
    textDecoration: "none",
  },
  clubSelectContainer: {
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
  },
  clubSelected: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "space-between",
  },
}));
