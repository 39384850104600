import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import clsx from "clsx";
import { connect } from "react-redux";
import { CloudinaryContext } from "cloudinary-react";
import { useParams } from "react-router-dom";

import RTL from "../../layout/Rtl";
import NoAuthAppBar from "./NoAuthAppBar";
import * as Actions from "../../store/actions";
import TrainlitLoader from "../../components/TrainlitLoader";

function NoAuthLayout(props) {
  const params = useParams();

  useEffect(() => {
    if (!props.club && !props.isLoadingClub && params.clubId) {
      props.loadClub(params.clubId);
    }
  }, []);

  const classes = useStyles();

  return (
    <RTL>
      <CloudinaryContext cloudName="dmixnaxp6">
        <div dir="rtl" className={classes.root}>
          <CssBaseline />
          {!params.clubId || props.club ? (
            <React.Fragment>
              <NoAuthAppBar
                {...props.appBarProps}
                clubConfig={props.clubConfig}
              />
              <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <div
                  className={clsx({ [classes.container]: !props.noPadding })}
                >
                  {props.children}
                </div>
              </main>
            </React.Fragment>
          ) : (
            <div>
              <TrainlitLoader />
            </div>
          )}
        </div>
      </CloudinaryContext>
    </RTL>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: "100vh",
  },
  appBarSpacer: { height: 80 },
  content: {
    flexGrow: 1,
    overflow: "auto",
  },
  container: {
    padding: theme.spacing(5),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
}));

const mapStateToProps = (state) => {
  return {
    club: state.general.club,
    isLoadingClub: state.general.isLoadingClub,
    clubConfig: state.general.clubConfig,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    loadClub: (clubInternalName) =>
      dispatch(Actions.general.loadClub(clubInternalName)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NoAuthLayout);
