import React from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";
import Logo from "../resources/images/logoBlack.png";

export default function TrainlitLoader() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <CircularProgress thickness={1} size={250} className={classes.progress} />
      <img src={Logo} className={classes.logo} />
    </div>
  );
}

const useStyles = makeStyles(() => ({
  progress: {
    position: "absolute",
    color: "#000",
    top: -100,
    right: -25,
  },
  logo: {
    width: 200,
  },
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 10,
    border: "0px solid",
  },
}));
