import {
  Toolbar,
  AppBar as MUAppBar,
  Typography,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import { Image } from "cloudinary-react";
import React from "react";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";
import BackIcon from "@material-ui/icons/ArrowBackIosRounded";
import clsx from "clsx";

export default function AppBar(props) {
  const classes = useStyles();
  const { clubId } = useParams();
  const history = useHistory();

  const renderStaticLinks = () => {
    return (
      <div className={classes.linksRow}>
        <RouterLink
          color="secondary"
          to={`/clubs/${clubId}/about`}
          className={classes.link}
          key="about"
        >
          אודות
        </RouterLink>
        <div className={classes.linkSepartor} />
        <RouterLink
          color="secondary"
          to={`/clubs/${clubId}/clubTerms`}
          className={classes.link}
          key="clubTerms"
        >
          תקנון רישום
        </RouterLink>
        <div className={classes.linkSepartor} />
        <RouterLink
          color="secondary"
          to={`/clubs/${clubId}/contact`}
          className={classes.link}
          key="contact"
        >
          צור קשר
        </RouterLink>
      </div>
    );
  };

  return (
    <MUAppBar position="absolute" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.toolbarContainer}>
          <div
            className={clsx(classes.clubNameContainer, {
              [classes.mobileContainer]: !props.clubConfig,
            })}
          >
            {props.clubConfig ? (
              <RouterLink
                color="secondary"
                to={`/clubs/${clubId}`}
                className={classes.clubNameLink}
              >
                <Typography variant="h4" className={classes.clubName} noWrap>
                  {props.clubConfig.general.name}
                </Typography>
              </RouterLink>
            ) : (
              // no club => mobile
              <React.Fragment>
                <div className={classes.sideCell} />
                <Image
                  publicId={`general/trainlitLogoWhite`}
                  alt="Logo"
                  style={{ width: 150 }}
                />
                <div className={classes.sideCell}>
                  {props.noBackButton ? (
                    <span />
                  ) : (
                    <IconButton
                      size="small"
                      className={classes.clubName}
                      onClick={() => history.replace(`/login`)}
                    >
                      <BackIcon />
                    </IconButton>
                  )}
                </div>
              </React.Fragment>
            )}
          </div>

          {props.clubConfig && renderStaticLinks()}
        </div>
      </Toolbar>
    </MUAppBar>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    height: 80,
    background: theme.appBar.background,
  },
  toolbar: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  toolbarContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  link: {
    textDecoration: "none",
    color: theme.appBar.color,
  },
  clubName: {
    color: theme.appBar.color,
  },
  clubNameContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
  },
  mobileContainer: {
    justifyContent: "space-between",
    marginTop: 10,
  },
  clubNameLink: {
    textDecoration: "none",
  },

  linkSepartor: {
    width: 20,
  },
  linksRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  sideCell: {
    width: 30,
  },
}));
