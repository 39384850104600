import React from "react";
import clsx from "clsx";
import CheckIcon from "@material-ui/icons/Check";
import { makeStyles } from "@material-ui/core";

export default function PasswordHelperText(props) {
  const classes = useStyles();

  const pwd = props.password || "";
  const number = pwd.match(/\d/);
  const lower = pwd.match(/[a-z]/);
  const capital = pwd.match(/[A-Z]/);
  const length = pwd.match(/^[0-9a-zA-Z]{8,}$/);

  const renderRow = (text, isMatch) => (
    <div className={clsx(classes.row, { [classes.noMatch]: !isMatch })}>
      <CheckIcon className={clsx({ [classes.noMatchIcon]: !isMatch })} />
      {text}
    </div>
  );

  return (
    <div>
      על הסיסמה להכיל:
      {renderRow("אות גדולה", capital !== null)}
      {renderRow("אות קטנה", lower !== null)}
      {renderRow("ספרה", number !== null)}
      {renderRow("לפחות 8 תווים", length !== null)}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    alignItems: "center",
  },
  noMatch: {
    color: theme.palette.text.disabled,
  },
  noMatchIcon: {
    color: "#DADADA",
  },
}));
