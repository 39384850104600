import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { withRouter } from "react-router";

function TermsDialog(props) {
  return (
    <Dialog
      dir="rtl"
      open={props.open}
      onClose={props.onClose}
      maxWidth="md"
      fullWidth
      style={{ border: "1px solid black" }}
    >
      <DialogTitle id="scroll-dialog-title">תקנון הרישום ותשלום</DialogTitle>
      <DialogContent dividers>
        <div
          dangerouslySetInnerHTML={{
            __html: props.terms,
          }}
        ></div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          סגור
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withRouter(TermsDialog);
