import React from "react";

import {
  Typography,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core";

import { displayPrice } from "../../lib/helpers";
import ReviewItem from "./ReviewItem";
import MerchandiseReviewItem from "./MerchandiseReviewItem";

export default function Review(props) {
  const classes = useStyles();

  const renderSeasonProductPrice = (cartItem, seasonProduct) => {
    const monthly =
      ["season", "camp"].indexOf(
        seasonProduct.subscriptionDetails.subscriptionType
      ) > -1;
    return (
      <div style={{ marginTop: 10 }}>
        <span className={classes.price}>{displayPrice(cartItem.price)}</span>{" "}
        {monthly && (
          <>
            ({displayPrice(cartItem.pricing.unitPrice)}
            {" \u00D7 "}
            {seasonProduct.subscriptionDetails.monthsLeft} חודשים)
          </>
        )}
      </div>
    );
  };

  const renderSeasonProduct = (item, subscription) => {
    if (!item) {
      return null;
    }
    const selectedTeam = props.teams.find(
      (team) => team._id === subscription.teamId
    );

    const seasonProduct = item.seasonProduct;
    const external = seasonProduct._id === "external";

    const subscriptionItem = [
      <ReviewItem
        key={item.seasonProduct._id}
        title={`${selectedTeam.name}${
          item.pricing.title ? ` - ${item.pricing.title}` : ""
        }`}
        description={props.player.name}
        customPrice={
          external
            ? "התשלום לקבוצה זו לא מתבצע דרך המועדון"
            : renderSeasonProductPrice(item, seasonProduct)
        }
      />,
    ];

    const additionalItems = (seasonProduct.subProducts || []).map(
      (subProduct) => (
        <ReviewItem
          key={subProduct._id}
          title={subProduct.title}
          description={props.player.name}
          price={subProduct.price}
        />
      )
    );

    return [subscriptionItem, ...additionalItems];
  };

  const renderMerchandiseItems = () =>
    Object.values(props.cart.merchandise).map((item) => (
      <MerchandiseReviewItem
        key={`${item.product._id}||${item.player._id}||${item.optionId}`}
        title={item.product.title}
        description={renderMerchandiseDescription(item)}
        price={item.product.price}
        item={item}
        onQuantityChange={handleMerchandiseChange(item)}
      />
    ));

  const renderMerchandiseDescription = (item) => {
    const option = item.optionId
      ? `, ${item.product.optionsType} ${item.optionTitle}`
      : "";

    return (
      <div>
        {item.player.name}
        {option}
      </div>
    );
  };

  const handleMerchandiseChange = (item) => (quantityDiff) => {
    props.onMerchandiseChange(
      item.product,
      quantityDiff,
      item.player,
      item.optionId
    );
  };

  const renderSubscriptionPayments = () =>
    Object.values(props.cart.subscriptionPayments).map((item) => {
      const reviewItems = [];
      reviewItems.push(
        <ReviewItem
          key={item.subscription._id}
          title={item.subscription.team.name}
          description={item.subscription.clubPlayer.name}
          price={item.subscription.debt - item.subscription.feesDebt}
        />
      );

      if (item.subscription.feesDebt > 0) {
        reviewItems.push(
          <ReviewItem
            key={item.subscription.product.subProducts[0]._id}
            title={
              item.subscription.product.subProducts.length === 1
                ? item.subscription.product.subProducts[0].title
                : "דמי רישום"
            }
            price={item.subscription.feesDebt}
          />
        );
      }

      return reviewItems;
    });

  if (!props.cart.seasonProduct && props.cart.total === 0) {
    return <div className={classes.noItems}>סל הקניות שלך ריק</div>;
  }

  return (
    <>
      <Typography variant="h6" style={{ marginRight: 10 }}>
        סיכום
      </Typography>
      <List>
        {renderSeasonProduct(
          props.cart.seasonProduct,
          props.forms.subscription
        )}
        {renderMerchandiseItems()}
        {renderSubscriptionPayments()}

        <ListItem className={classes.totalListItem}>
          {/* the empty tag pushes the price to the left size. dont remove it */}
          <ListItemText />
          <Typography variant="subtitle1" className={classes.total}>
            סה״כ: {displayPrice(props.cart.total)}
          </Typography>
        </ListItem>
      </List>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  totalListItem: {
    padding: theme.spacing(7, 1, 1, 1),
  },
  total: {
    fontWeight: "700",
  },
  noItems: {
    borderBottom: "1px solid #EEE",
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 20,
  },
  price: { fontWeight: "bold" },
}));
