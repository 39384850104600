import React, { Component } from "react";
import moment from "moment";
import { Typography, Grid, Container, withStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Link as RouterLink } from "react-router-dom";

import axios from "../../lib/axios";
import { validatePlayer } from "../Purchase/helpers/validator";
import PlayerForm from "../Purchase/PlayerForm";
import MedicalForm from "../Purchase/MedicalForm";
import PopUp from "../../components/base/PopUp";
import ActionButton from "../../components/ActionButton";
import { clubUrl, normalizeServerValidationErrors } from "../../lib/helpers";
import Paper from "../../components/Paper";
import Button from "../../components/Button";

let PREFILLED = process.env.NODE_ENV === "production" ? false : false;

class NewPlayer extends Component {
  state = {
    player: {
      firstName: PREFILLED ? "יוסי" : "",
      lastName: PREFILLED ? "משפחה" : "",
      class: PREFILLED ? "4" : "",
      dobDay: PREFILLED ? "4" : "",
      dobMonth: PREFILLED ? "4" : "",
      dobYear: PREFILLED ? "2004" : "",
      phone: "",
      id: PREFILLED ? "012345674" : "",
      gender: PREFILLED ? "" : "",
      hasMedicalIssues: null,
      medicalIssues: "",
      medicalIssuesUpdate: false,
      school: "",
    },
    errors: {},
    isPopUpOpen: false,
    success: null,
    instantValidate: false,
    isLoading: false,
  };

  onChange = async (changes) => {
    let errors;
    if (this.state.instantValidate) {
      errors = await validatePlayer({ ...this.state.player, ...changes });
    }

    if (changes.hasMedicalIssues === false) {
      changes.medicalIssues = "";
    }

    this.setState((prevState) => {
      errors = errors || prevState.errors;

      return {
        ...prevState,
        player: {
          ...prevState.player,
          ...changes,
        },
        errors,
        uniqueIdError: changes.id ? false : prevState.uniqueIdError,
        uniquePhoneError: changes.phone ? false : prevState.uniquePhoneError,
      };
    });
  };

  handleSubmit = async () => {
    const errors = await validatePlayer(this.state.player);
    const isValid = Object.keys(errors).length === 0;

    if (isValid) {
      try {
        const { player } = this.state;
        const data = {
          firstName: this.state.player.firstName,
          lastName: player.lastName,
          id: player.id,
          class: player.class,
          gender: player.gender,
          dateOfBirth: moment([
            player.dobYear,
            player.dobMonth - 1,
            player.dobDay,
          ]).format("YYYY-MM-DD"),
          hasMedicalIssues: player.hasMedicalIssues,
          medicalIssues: player.medicalIssues,
          ...(player.phone ? { phone: player.phone } : {}),
          school: player.school,
        };
        if (!data.phone || !data.phone.trim() === "") {
          delete data.phone;
        }
        this.setState({ isLoading: true });
        const res = await axios.post("/players", data);
        this.setState({
          isPopUpOpen: true,
          success: true,
          playerId: res.data._id,
          isLoading: false,
        });
      } catch (error) {
        let errors = {};
        let uniqueIdError = null;

        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          uniqueIdError = error.response.data.errors.id === "unique";
          errors = normalizeServerValidationErrors(error.response.data.errors);
        }
        this.setState({
          success: false,
          isLoading: false,
          errors,
          uniqueIdError,
          instantValidate: true,
        });
      }
    } else {
      this.setState({ errors, instantValidate: true });
    }
  };

  handlePopUpClose = () => this.setState({ isPopUpOpen: false });

  render() {
    const { classes } = this.props;
    const clubId = this.props.match.params.clubId;

    return (
      <Container maxWidth="md">
        <Paper
          title="הוספת ספורטאי/ת חדש/ה"
          bigTitle
          titleBackground
          bodyClassName={classes.paper}
        >
          <Grid container spacing={3}>
            {this.state.uniqueIdError && (
              <Grid item xs={12}>
                <Alert severity="error">
                  קיימים ספורטאי/ת עם תעודת זהות {this.state.player.id} בחשבון
                  שלכם
                </Alert>
              </Grid>
            )}
            <Grid item>
              <PlayerForm
                state={this.state.player}
                errors={this.state.errors}
                onChange={this.onChange}
                uniquePhoneError={this.state.uniquePhoneError}
              />
            </Grid>
            <Grid item>
              <Typography variant="h6" gutterBottom>
                הצהרת בריאות
              </Typography>
              <MedicalForm
                state={this.state.player}
                errors={this.state.errors}
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={12} className={classes.buttonContainer}>
              <Button
                className={classes.addPlayerButton}
                variant="contained"
                onClick={this.handleSubmit}
                color="primary"
                debounce
                isLoading={this.state.isLoading}
              >
                הוספה
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <PopUp
          open={this.state.isPopUpOpen}
          onClose={this.handlePopUpClose}
          noNativeClose
        >
          <div className={classes.successPopup}>
            <Typography variant="h4" style={{ marginBottom: 10 }}>
              חשבון הספורטאי/ת נוצר בהצלחה!
            </Typography>
            שימו לב, ההרשמה למועדון עדין לא הסתיימה. להשלמת ההרשמה יש ללחוץ על
            ״רישום לפעילות״
            <ActionButton
              variant="contained"
              className={classes.subscribeButton}
              component={RouterLink}
              to={clubUrl(clubId, `purchase?playerId=${this.state.playerId}`)}
              color="primary"
            >
              רישום לפעילות
            </ActionButton>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <RouterLink to={clubUrl(clubId)}>חזרה לדף הבית</RouterLink>
          </div>
        </PopUp>
      </Container>
    );
  }
}

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    },
  },
  buttonContainer: {
    justifyContent: "flex-end",
    display: "flex",
  },
  addPlayerButton: {
    width: 200,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  subscribeButton: {
    width: 200,
    margin: 30,
  },
  successPopup: {
    minHeight: 150,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
});

export default withStyles(styles)(NewPlayer);
