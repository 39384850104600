import React from "react";
import { connect } from "react-redux";
import { Container, Grid, Hidden } from "@material-ui/core";

import axios from "../../lib/axios";
import PlayerCard from "./PlayerCard";
import Subscirptions from "./Subscriptions/Subscirptions";
import MyClubs from "./MyClubs";
import NoData from "./NoData";

function Home(props) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [players, setPlayers] = React.useState(null);

  React.useEffect(() => {
    if (props.match.params.clubId) {
      loadData();
    }
  }, [props.match.params.clubId]);

  const loadData = async () => {
    setIsLoading(true);
    const { clubId } = props.match.params;

    let res = await axios.get(`/players?parentId=${props.parentId}`);
    const players = res.data;
    res = await axios.get(
      `/clubPlayers?parentId=${props.parentId}&clubInternalName=${clubId}&granularity=activeSubscriptions`
    );
    const clubPlayers = res.data;

    clubPlayers.forEach((clubPlayer) => {
      const player = players.find((p) => p._id === clubPlayer.playerId);
      player.clubPlayer = clubPlayer;
    });

    setPlayers(players);
    setIsLoading(false);
  };

  const renderContent = () => (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12} lg={8} style={{ maxWidth: 700 }}>
        <Grid container spacing={2}>
          {players.map((player) => (
            <Grid item xs={12} sm={6} key={player._id}>
              <PlayerCard key={player._id} player={player} />
            </Grid>
          ))}
          {players.length % 2 === 1 && (
            <Hidden xsDown>
              <Grid item xs={12} sm={6}>
                <PlayerCard player={{ new: true }} />
              </Grid>
            </Hidden>
          )}
          <Grid item xs={12}>
            <Subscirptions clubConfig={props.clubConfig} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={4} style={{ maxWidth: 700 }}>
        <MyClubs />
      </Grid>
    </Grid>
  );

  return (
    <div style={{ flex: 1 }}>
      {/* <div>{props.isMobile ? "mobile" : "not mobile"}</div> */}

      {!isLoading &&
        (players.length > 0 ? (
          renderContent()
        ) : (
          <Container maxWidth="md">
            <NoData clubId={props.match.params.clubId} />
          </Container>
        ))}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    parent: state.auth.parent,
    isLoadingParent: state.auth.isLoadingParent,
    parentId: state.auth.parentId,
    clubConfig: state.general.clubConfig,
    isMobile: state.general.isMobile,
  };
};

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
