import React, { Component } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Typography,
  Paper,
  Grid,
  Link,
  CssBaseline,
  TextField,
  Collapse,
} from "@material-ui/core";
import { connect } from "react-redux";
import validator from "validator";
import BackIcon from "@material-ui/icons/ArrowBackIosRounded";

import * as Actions from "../../store/actions";
import axios from "../../lib/axios";
import { clubUrl } from "../../lib/helpers";
import { withStyles } from "@material-ui/styles";
import { Image } from "cloudinary-react";
import NoAuthFooter from "./NoAuthFooter";
import PasswordTextField from "../../components/PasswordTextField";
import Button from "../../components/Button";
import { Alert } from "@material-ui/lab";
import { sendMessage } from "../../lib/wrapperHelper";

const PREFILLED = process.env.NODE_ENV === "production" ? false : true;

const customBackgrounds = [
  "soccer",
  "football",
  "judo",
  "athletics",
  "rhythmic_gymnastics",
  "artistic_gymnastics",
  "bouldering",
  "triathlon",
  "swimming",
  "waterpolo",
  "rugby",
  "chess",
  "taekwondo",
];

class Login extends Component {
  initState = () => ({
    phone: PREFILLED ? "0583924172" : "",
    password: PREFILLED ? "Password1" : "",
    showPassword: false,
    passwordError: null,
    loginType: "password",
    phoneStatus: "new",
    loginError: null,
    isLoading: false,
    code: "",
    codeSentStatus: null,
    codeError: null,
    passwordLoginDisabled: false,
  });

  state = this.initState();

  generateUrl = (path) => {
    return this.props.isMobile
      ? path
      : clubUrl(this.props.match.params.clubId, path);
  };

  verifyPhone = async () => {
    const valid = validator.isMobilePhone(this.state.phone.trim(), "he-IL");
    if (valid) {
      this.setState({ isLoading: true });

      const res = await axios.post("/parents/checkPhoneAvailability", {
        phone: this.state.phone.trim(),
        userType: "parent",
      });

      if (res.data.exist) {
        if (res.data.valid) {
          const inactive = res.data.inactive === true;
          const passwordLoginDisabled = inactive;
          const loginType = inactive ? "code" : this.state.loginType;
          this.setState({
            phoneStatus: "found",
            passwordLoginDisabled,
            loginType,
          });
        } else {
          // coach / manager
          this.setState({ phoneStatus: "invalidUser" });
        }
      } else {
        this.setState({ phoneStatus: "notFound" });
      }
    } else {
      this.setState({ phoneStatus: "invalid" });
    }
    this.setState({ isLoading: false });
  };

  phoneStatusMessage = () => {
    switch (this.state.phoneStatus) {
      case "invalid":
        return "מספר טלפון לא תקין";

      case "notFound":
        return (
          <span>
            מספר הטלפון לא קיים במערכת. ליצירת חשבון{" "}
            <Link
              component={RouterLink}
              to={this.generateUrl(
                `parents/new?phone=${this.state.phone.trim()}`
              )}
            >
              לחצו כאן
            </Link>
          </span>
        );

      case "invalidUser":
        return (
          <span>
            לא ניתן להתחבר באמצעות מספר טלפון זה. לפרטים נוספים אנא פנו למועדון
          </span>
        );

      default:
        return null;
    }
  };

  sendCode = async () => {
    try {
      this.setState({ isLoading: true });
      const res = await axios.post("/users/verifyAccount", {
        phone: this.state.phone.trim(),
        type: "login",
        userType: "parent",
      });

      this.setState({ codeSentStatus: "sent" });

      if (res.data.status === "codeAlreadySent") {
        this.setState({ codeSentStatus: "alreadySent" });
      } else {
        this.setState({ code: "" });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  codeLogin = async () => {
    if (this.state.code.match(/^\d{6}$/g) === null) {
      this.setState({ codeError: "invalid" });
    } else {
      this.setState({ isLoading: true });
      try {
        const res = await axios.post("/users/login", {
          phone: this.state.phone.trim(),
          code: this.state.code,
          loginTypeNg: "otc",
          loginType: "phone",
          userType: "parent",
        });
        const parent = res.data;

        this.setState({ codeError: null });
        this.notifyWrapper(parent, res.headers.authorization);
        this.props.login(parent._id, res.headers.authorization, parent);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.expired
        ) {
          this.setState({ codeError: "expired" });
        } else {
          this.setState({ codeError: "loginFailed" });
        }
      } finally {
        this.setState({ isLoading: false });
      }
    }
  };

  notifyWrapper = (parent, token) => {
    sendMessage("login", {
      parentId: parent._id,
      token,
      fcmToken: parent.fcmToken,
    });
  };

  login = async () => {
    if (this.state.password.length < 5) {
      this.setState({ passwordError: "invalid" });
    } else {
      try {
        this.setState({
          isLoading: true,
          loginError: false,
          passwordError: null,
        });
        const res = await axios.post("/users/login", {
          loginType: "phone",
          phone: this.state.phone.trim(),
          password: this.state.password,
          userType: "parent",
        });
        const parent = res.data;

        this.notifyWrapper(parent, res.headers.authorization);
        this.props.login(parent._id, res.headers.authorization, parent);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.status === "notActive"
        ) {
          console.log("inactive");
        }

        this.setState({
          loginError: true,
          isLoading: false,
        });
      }
    }
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  textFieldDefaults = {
    variant: "outlined",
    margin: "normal",
    fullWidth: true,
  };

  submitButtonDefaults = () => ({
    type: "submit",
    fullWidth: true,
    variant: "contained",
    className: this.props.classes.submit,
    disabled: this.state.phoneStatus === "invalidUser",
    isLoading: this.state.isLoading,
    theme: "action",
  });

  renderForm = (clubId) => {
    switch (this.state.phoneStatus) {
      case "new":
      case "invalidUser":
      case "invalid":
        return this.renderNewPhone();

      case "notFound":
        return this.renderPhoneNotFound(clubId);

      case "found":
        return this.renderPhoneFound();

      default:
        break;
    }
  };

  handlePhoneChange = (e) => {
    this.setState({ ...this.initState(), phone: e.target.value });
  };

  renderNewPhone = () => (
    <React.Fragment>
      <Grid item xs={12}>
        <TextField
          {...this.textFieldDefaults}
          label="הכנס את מספר הטלפון שלך"
          autoFocus
          onChange={this.handlePhoneChange}
          value={this.state.phone}
          error={this.state.phoneStatus === "invalid"}
          helperText={
            this.state.phoneStatus === "invalid" && this.phoneStatusMessage()
          }
        />
      </Grid>

      <Grid item xs={12}>
        <Button
          {...this.submitButtonDefaults()}
          onClick={this.verifyPhone}
          debounce
        >
          המשך
        </Button>
      </Grid>
    </React.Fragment>
  );

  renderPhoneNotFound = () => (
    <React.Fragment>
      <Grid item xs={12}>
        <TextField
          {...this.textFieldDefaults}
          label="מספר טלפון"
          autoFocus
          onChange={this.handlePhoneChange}
          value={this.state.phone}
          error={this.state.phoneStatus === "invalid"}
          helperText={
            this.state.phoneStatus === "invalid" && this.phoneStatusMessage()
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          {...this.submitButtonDefaults()}
          component={RouterLink}
          to={this.generateUrl(`parents/new?phone=${this.state.phone.trim()}`)}
        >
          יצירת חשבון חדש
        </Button>
      </Grid>
    </React.Fragment>
  );

  renderPhoneFound = () => (
    <React.Fragment>
      <Grid item xs={12}>
        <TextField
          {...this.textFieldDefaults}
          label="מספר טלפון"
          autoFocus
          onChange={this.handlePhoneChange}
          value={this.state.phone}
          error={this.state.phoneStatus === "invalid"}
          helperText={
            this.state.phoneStatus === "invalid" && this.phoneStatusMessage()
          }
        />
      </Grid>
      <Grid item xs={12}></Grid>
      {this.state.loginType === "password" && this.renderPasswordLogin()}
      {this.state.loginType === "code" && this.renderCodeLogin()}
    </React.Fragment>
  );

  renderPasswordLogin = () => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <PasswordTextField
            {...this.textFieldDefaults}
            label="סיסמה"
            onChange={(e) => this.setState({ password: e.target.value })}
            value={this.state.password}
            error={this.state.passwordError}
            helperText={this.state.passwordError && "סיסמה לא תקינה"}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            {...this.submitButtonDefaults()}
            onClick={this.login}
            debounce
          >
            התחברות
          </Button>
        </Grid>

        <Grid item xs={6}>
          <Link href="#" onClick={() => this.setState({ loginType: "code" })}>
            התחברות עם קוד חד פעמי לנייד
          </Link>
        </Grid>
      </React.Fragment>
    );
  };

  renderCodeLogin = () => {
    let message;

    switch (this.state.codeSentStatus) {
      case "sent":
        message = (
          <React.Fragment>
            <span>הקוד נשלח בהצלחה למספר {this.state.phone}.</span>
            <div>שימו לב, הוא תקף ל-10 הדקות הקרובות</div>
          </React.Fragment>
        );
        break;

      case "alreadySent":
        message = "המתינו מספר דקות לקבלת קוד נוסף";
        break;

      default:
        message = "נשלח לך קוד כדי לאמת את החשבון שלך";
        break;
    }

    let errorMessage;
    switch (this.state.codeError) {
      case "expired":
        errorMessage = "הקוד שהוזן פג תוקף";
        break;

      case "loginFailed":
        errorMessage = "הקוד שגוי";
        break;

      case "invalid":
        errorMessage = "יש להזין קוד בעל 6 ספרות";
        break;

      default:
        break;
    }

    const { classes } = this.props;

    return (
      <React.Fragment>
        <Grid item xs={12}>
          <div className={classes.codeLoginMessageContainer}>
            <Typography style={{ textAlign: "center" }} component="div">
              {message}
            </Typography>
          </div>
        </Grid>
        {this.state.codeSentStatus && (
          <TextField
            {...this.textFieldDefaults}
            label="הזינו את הקוד שקיבלתם כאן"
            onChange={(e) => this.setState({ code: e.target.value })}
            value={this.state.code}
            error={this.state.codeError ? true : false}
            helperText={errorMessage}
          />
        )}
        <Grid item xs={12}>
          <Button
            {...this.submitButtonDefaults()}
            onClick={this.state.codeSentStatus ? this.codeLogin : this.sendCode}
            debounce
          >
            {this.state.codeSentStatus ? "התחברות" : "שלחו לי קוד"}
          </Button>
        </Grid>
        <Grid item xs={6}>
          {!this.state.passwordLoginDisabled && (
            <Link
              href="#"
              onClick={() => this.setState({ loginType: "password" })}
            >
              התחברות עם סיסמה
            </Link>
          )}
        </Grid>
        {this.state.codeSentStatus && (
          <Grid item xs={6}>
            <div className={classes.resendCodeContainer}>
              <Link href="#" onClick={this.sendCode}>
                שלחו לי קוד חדש
              </Link>
            </div>
          </Grid>
        )}
      </React.Fragment>
    );
  };

  renderLogin = () => {
    const { classes } = this.props;
    const { clubId } = this.props.match.params;

    return (
      <React.Fragment>
        <div>
          <Collapse in={this.state.loginError} className={classes.collapse}>
            <Alert severity="error" className={classes.alert}>
              מספר הטלפון / סיסמה אינם נכונים
            </Alert>
          </Collapse>

          <Collapse
            in={this.state.phoneStatus === "notFound"}
            className={classes.collapse}
          >
            <Alert severity="info" className={classes.alert}>
              {this.phoneStatusMessage()}
            </Alert>
          </Collapse>
          <Collapse
            in={this.state.phoneStatus === "invalidUser"}
            className={classes.collapse}
          >
            <Alert severity="error" className={classes.alert}>
              {this.phoneStatusMessage()}
            </Alert>
          </Collapse>
        </div>
        <form className={classes.form} noValidate>
          <Grid container>
            {this.renderForm(clubId)}
            {this.state.phoneStatus == "new" && (
              <Grid item xs={6}>
                <RouterLink
                  to={this.generateUrl(`parents/new`)}
                  variant="body2"
                >
                  משתמש חדש?
                </RouterLink>
              </Grid>
            )}
          </Grid>
        </form>
      </React.Fragment>
    );
  };

  renderClub = () => {
    const { classes } = this.props;

    let backgroundImage;

    backgroundImage =
      customBackgrounds.indexOf(this.props.club.category) > -1
        ? this.props.club.category
        : "default";

    return (
      <React.Fragment>
        <Grid
          item
          xs={12}
          md={6}
          lg={4}
          component={Paper}
          elevation={6}
          square
          className={classes.rightSideContainer}
        >
          <div className={classes.paper}>
            <Image
              publicId={this.props.clubConfig.general.logo.name}
              version={this.props.clubConfig.general.logo.version}
              className={classes.logo}
              alt="Logo"
            />
            <Typography component="h1" variant="h5">
              התחברות למועדון - הורים
            </Typography>
            {this.renderLogin()}
          </div>
          <div style={{ flexGrow: 1 }} />
          <div className={classes.trainlitLogoContainer}>
            מופעל באמצעות
            <Image
              publicId={`general/trainlitLogoBlack`}
              alt="Logo"
              style={{ width: 150, marginTop: 10 }}
            />
          </div>
          <div style={{ alignSelf: "stretch" }}>
            <NoAuthFooter />
          </div>
        </Grid>
        <Grid
          item
          xs={false}
          md={6}
          lg={8}
          className={classes.image}
          style={{
            backgroundImage: `url(https://res.cloudinary.com/dmixnaxp6/image/upload/v1/parentsWeb/${backgroundImage})`,
          }}
        />
      </React.Fragment>
    );
  };

  renderNoClub = () => {
    const { classes } = this.props;

    return (
      <div className={classes.rightSideContainer}>
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            התחברות - הורים
          </Typography>
          {this.renderLogin()}
        </div>
        <div style={{ flexGrow: 1 }} />
        <div className={classes.noClubBackButtonContainer}>
          <Button
            variant="clear"
            onClick={() => {
              sendMessage("backFromLogin");
            }}
          >
            <div className={classes.backButton}>
              חזרה
              <BackIcon />
            </div>
          </Button>
        </div>
        <div style={{ alignSelf: "stretch" }}>
          <NoAuthFooter noClub />
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Grid container component="main" className={this.props.classes.root}>
          <CssBaseline />

          {this.props.isMobile ? this.renderNoClub() : this.renderClub()}
        </Grid>
      </React.Fragment>
    );
  }
}

const styles = (theme) => {
  return {
    root: {
      minHeight: "calc(100vh - 80px)",
    },
    image: {
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    paper: {
      marginTop: 20,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      flex: 1,
      width: "80%",
      maxWidth: "400px",
    },
    form: {
      width: "100%", // Fix IE 11 issue.
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      height: "40px",
    },
    rightSideContainer: {
      justifyContent: "space-between",
      display: "flex",
      flexDirection: "column",
      flex: 1,
      alignItems: "center",
    },
    alert: {
      margin: theme.spacing(2, 0),
    },
    phoneFoundTextField: {
      "& input:valid + fieldset": {
        borderColor: "green",
        borderWidth: 1,
      },
    },
    logo: {
      maxWidth: 120,
      marginBottom: 20,
    },
    trainlitLogoContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingBottom: 20,
    },
    trainlitLogo: {
      width: 150,
      marginTop: 10,
    },
    resendCodeContainer: {
      textAlign: "right",
    },
    collapse: {
      width: "100%",
    },
    codeLoginMessageContainer: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      marginTop: 30,
    },
    noClubBackButtonContainer: {
      display: "flex",
      alignSelf: "stretch",
      justifyContent: "flex-end",
    },
    backButton: {
      display: "flex",
      alignItems: "center",
      fontSize: 18,
    },
  };
};

const mapStateToProps = (state) => ({
  club: state.general.club,
  clubConfig: state.general.clubConfig,
  isMobile: state.general.isMobile,
});

function mapDispatchToProps(dispatch) {
  return {
    login: (parentId, token, parent) =>
      dispatch(Actions.auth.login(parentId, token, parent)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Login));
