import React from "react";
import {
  CircularProgress,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
// import { Link } from "react-router-dom";
import PopUp from "../../components/base/PopUp";
import Button from "../../components/Button";

import axios from "../../lib/axios";

export default function VerifyParentPhonePopUp(props) {
  const [code, setCode] = React.useState("");
  // const [codeSentStatus, setCodeSentStatus] = React.useState(null);
  const [message, setMessage] = React.useState(null);
  const [isSending, setIsSending] = React.useState(false);
  const [error, setError] = React.useState(false);
  const classes = useStyles();

  React.useEffect(() => {
    // if (props.shouldSend) {
    //   sendCode();
    // } else {
    setMessage(messages("sent"));
    // }
  }, [props.open]);

  const messages = (type) => {
    return {
      sent: (
        <div>
          שלחנו לך קוד למספר {props.phone} כדי לאמת את החשבון שלך.
          <div>שימו לב, הוא תקף ל-10 הדקות הקרובות</div>
        </div>
      ),
      resent: `שלחנו קוד חדש למספר ${props.phone}`,
      alreadySent: `המתינו מספר דקות לקבלת קוד נוסף`,
    }[type];
  };

  // const sendCode = async () => {
  // try {
  //   setIsSending(true);
  //   const res = await axios.post("/parents/verifyPhone", {
  //     phone: props.phone,
  //     code,
  //   });
  //   setCodeSentStatus(codeSentStatus ? "resent" : "sent");
  //   setMessage(codeSentStatus ? messages.sent : messages.resent);
  //   if (res.data.status === "codeAlreadySent") {
  //     setCodeSentStatus("alreadySent");
  //     setMessage(messages.alreadySent);
  //   } else {
  //     setCode("");
  //   }
  // } catch (error) {
  //   console.log(error);
  // } finally {
  //   setIsSending(false);
  // }
  // };

  const handleVerification = async () => {
    if (code.match(/^\d{6}$/g) === null) {
      setError("יש להזין קוד בן 6 ספרות");
      return;
    }

    setIsSending(true);
    const res = await axios.post(`/parents/verifyPhone`, {
      phone: props.phone,
      code,
    });

    const { status } = res.data;

    switch (status) {
      case "match":
        setMessage("האימות הושלם! הנך מועבר לאתר");
        props.login(res.data.parent, res.headers.authorization);
        break;

      case "noMatch":
        setError("הקוד שהוזן שגוי");
        break;

      case "expired":
        setError("הקוד שהוזן פג תוקף");
        break;

      default:
        break;
    }

    setIsSending(false);
  };

  return (
    <PopUp open={props.open} onClose={props.onClose} noNativeClose size="sm">
      <div className={classes.title}>
        <Typography variant="h4" gutterBottom>
          החשבון נוצר בהצלחה!
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            {isSending ? (
              <CircularProgress />
            ) : (
              <Typography component="div">{message}</Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="dense"
              fullWidth
              label="הזינו את הקוד שקיבלתם כאן"
              onChange={(e) => setCode(e.target.value)}
              value={code}
              error={error ? true : false}
              helperText={error}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleVerification}
              debounce
            >
              המשך
            </Button>
          </Grid>
          {/* <Grid item xs={12}>
            <div style={{ textAlign: "left" }}>
              <Link to="#" onClick={sendCode}>
                שלחו לי קוד חדש
              </Link>
            </div>
          </Grid> */}
        </Grid>
      </div>
    </PopUp>
  );
}

const useStyles = makeStyles(() => ({
  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));
