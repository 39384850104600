import React from "react";

export default function TermsText() {
  return (
    <div>
      <ol>
        <li>
          <b>
            <u>קבלת תנאים</u>
          </b>
          <p>
            תנאי שימוש אלה (&quot;התנאים&quot;) מהווים הסכם מחייב משפטית בין
            חברת Trainlit (&quot;Trainlit&quot;, &quot;אנחנו&quot; או
            &quot;אנו&quot;) לבינך (&quot;אתה&quot;, &quot;משתמש&quot;). על ידי
            שימוש באפליקציה או באתר של Trainlit (&quot;השירות&quot;), אתה מאשר
            שקראת והבנת תנאים אלה ושאתה מקבל ומסכים להיות כפוף להם.
          </p>
          <p>
            TrainLit פועלת לקידום מועדוני ספורט ועידוד פעילות ספורטיבית בקרב בני
            נוער כאשר מאפשרת למועדוני הספורט בישראל לייעל את עבודתם המנהלית תוך
            קידום ופיתוח יכולותיהם של ספורטאים צעירים. אם כן, שירותיה של
            TrainLit מותאמים לספורטאים צעירים, הורים, מאמנים ומנהלי מועדוני
            ספורט.
          </p>

          <div style={{ display: "flex" }}>
            <span>1.1</span>
            <span style={{ marginRight: 15, marginBottom: 10 }}>
              במידה והנך ספורטאי הרשום במועדון ספורט (&quot;<b>שחקן</b>
              &quot;), האפליקציה של TrainLit תספק לך פלטפורמה לאימון עצמאי
              ויצירת מארג חניכה צמוד בינך לבין מאמנך. ברישום לשירות, תתבקש לבצע
              אימות לחשבונך על בסיס מספר הטלפון שהוזן על ידי ההורה במעמד הרישום
              כמפורט במדיניות הפרטיות הזמינה ב- admin.trainlitapp.com/privacy.
              בהתאם לגילך ולקבוצה אליך אתה משויך שהוזן TrainLit תציג לך תוכנית
              אימונים שנבנתה בהתאם לקבוצת הגיל שאליה אתה משתייך. למען הסר ספק,
              תכנית האימונים אינה מותאמת לפי יכולות הספורטאי הספציפי אלא בנויה
              על פי הערכה ממוצעת של יכולות הספורטאים בקבוצת הגיל הנתונה. כל
              תכנית כאמור, מכילה מבחר תרגילים לשיפור היכולות האישיות, אשר ניתנות
              לביצוע בזמנך החופשי. מעבר בין תכנית אימונים ברמת קושי אחת, לתכנית
              אימונים ברמת קושי גבוהה יותר, כפופה לביצוע תרגיל המסכם את יכולות
              הביצוע שתורגלו בתכנית שניתנה לך, כאשר על ביצוע זה להיות מאושר על
              ידי המאמן שלך. אם כן, כדי להשלים תכנית אימון, יהיה עליך לצלם את
              ביצועך בתרגיל המסכם, ולשלוח למאמנך האישי לאישור ביצוע התרגיל
              ומעברך לתכנית הבאה. בנוסף, תוכל לצפות בלוח הזמנים, לקבל עדכונים
              והתראות ולצפות בטבלאות ותוצאות רלוונטיות. למען הסר ספק, הממשק
              המקצועי לשחקנים קיים למועדוני כדורסל בלבד.
            </span>
          </div>

          <div style={{ display: "flex" }}>
            <span>1.2</span>
            <span style={{ marginRight: 15, marginBottom: 10 }}>
              במידה והנך הורה השחקן, האפליקציה של TrainLit תספק לך אפשרות רישום
              ותשלום לשירותי המועדון, צפייה בלוח הזמנים והאירועים של הספורטאי
              וקבלת עדכונים והתראה בנוגע לכך, זיהוי בעיות בנוכחות והגעה לאימונים
              ומשחקים, צפייה בסרטונים שמעלה השחקן לאפליקציה וצפייה בטבלאות
              ותוצאות המשחקים.
            </span>
          </div>

          <div style={{ display: "flex" }}>
            <span>1.3</span>
            <span style={{ marginRight: 15, marginBottom: 10 }}>
              במידה והנך מאמן, השימוש בשירות יאפשר לך לבצע פעולות שונות שיסייעו
              לך בביצוע עבודתך המנהלית מול מועדון הספורט ועבודתך המקצועית מול
              השחקן הספורטאי. השירות של TrainLit מאפשר לך לנהל את רשימות הקשר של
              השחקנים, לבצע מעקבי נוכחות, לצפות בסרטוני הביצוע של השחקנים שישלחו
              בהתאם לאמור לעיל, לספק לשחקן משוב חיובי או שלילי בהתאם לביצוע
              התרגיל המסכם שאותו תידרש לאשר ולנהל מעקב אחר נתונים מקצועיים
              הנוגעים לשחקן שיבואו לידי ביטוי בגרף שיפור הביצועים.
            </span>
          </div>

          <div style={{ display: "flex" }}>
            <span>1.4</span>
            <span style={{ marginRight: 15, marginBottom: 10 }}>
              במידה והנך מנהל מועדון ספורט, השימוש בשירות יספק לך מערכת ניהול
              ממוחשבת שתאפשר לך לבצע פעולות אדמיניסטרטיביות ומקצועיות. המערכת
              תאפשר שליטה בכל הליך הרישום והתשלום של לקוחות המועדון לרבות הנחות,
              קופונים, דו״חות וניהול כוח אדם שוטף. באשר לשחקני המועדון, תוכל
              לבצע מעקב בנוגע לתשלומי השתתפות, האם השחקן עבר בדיקות רפואיות
              והתקבלו האישורים הנדרשים (תוצאות הבדיקות עצמן לא יהיו זמינות
              באמצעות השירות), פרטי התקשרות של השחקן, פרטי התקשרות של הורי
              השחקן, השתייכות לקבוצה, מועדי אימונים, מעקב נוכחות והשלב שבו השחקן
              נמצא בתוכנית האימונים שהוקצעה לו. באשר למאמני המועדון, תינתן לך
              גישה לפרטי ההתקשרות שלהם, תעודות הסמכה רלוונטיות וכן אמצעי מעקב על
              מספר סרטוני תרגילים מסכמים שנותרו למאמן לאשר. בהתאם לכך, אתה
              מתחייב כי תספק לשחקנים ולמאמני המועדון מידע אודות השירות, תוך ציון
              פרטי החברה כמספקת השירות, וכן תקבל את הסכמת הורי השחקן להעברת
              פרטיו לחברה לצורך מתן השירות, וכי הסכמה זו תועבר ל- TrainLit על פי
              דרישה. טופס ההסכמה לשימוש בשירות יספוק על ידי המועדון והעומדים
              בראשו ובאחריותם בלבד. במידה והמועדון משתמש בשירות התשלומים, הסכמת
              ההורה לשימוש השחקנים באפליקציה תעשה באמצעות הסכמה לתקנון המועדון
              במעמד הרישום.
            </span>
          </div>

          <p>
            TrainLit שומרת לעצמה את הזכות, לפי שיקול דעתה הבלעדי, לתקן ולשנות
            תנאים אלה בכל זמן, ואתה מסכים להיות כפוף לתיקונים ושינויים אלה. אתה
            אחראי לבדוק תנאים אלה מפעם לפעם. במקרה ש-TrainLit לפי שיקול דעתה
            הבלעדי, תחליט שעדכונים של תנאי השימוש מהווים שינוי מהותי, אנו עשויים
            להודיע לך על השינויים כאמור בהודעה שתפורסם באמצעות השירות. על אף
            האמור לעיל, אין אנו מחויבים להודיע למשתמש על שינויים בתנאי השימוש
            ולכן על המשתמשים לבקר מעת לעת בעמוד זה על מנת לעיין בתנאי השימוש
            העדכניים שלנו. המשך שימושך בשירות לאחר שנעשה שינוי או תיקון בתנאים
            אלה, מהווה הסכמה מדעת להסכמתך וקבלתך של התנאים המתוקנים. כל התנאים
            החדשים ו/או המתוקנים ייכנסו לתוקף מיד עם פרסומם ויחולו על השימוש שלך
            בשירות מאותו תאריך ואילך, להוציא שינויים מהותיים שיתכן שייכנסו לתוקף
            3 ימים לאחר שהשינוי נעשה וזוהה כמהותי. אם אינך מסכים לתנאים, התרופה
            היחידה העומדת לך הינה הפסקת השימוש בשירות וביטול החשבון (כפי שמונח
            זה יוגדר להלן) שנוצר לצורך שימוש בשירות.
          </p>
          <p>
            במידה ותפר תנאים אלו, TrainLitשומרת לעצמה את הזכות להנפיק לך אזהרה
            בנוגע להפרה או להפסיק או להשעות מיד את כל או חלק מהחשבון שיצרנו לך
            לצורך שימוש בשירות. אתה מסכים ש-TrainLit אינה נדרשת למסור לך הודעה
            לפני סיום או השעיית החשבון שלך, אולם היא רשאית למסור לך הודעה כאמור
            לפי שיקול דעתה הבלעדי.
          </p>
        </li>

        <li>
          <b>
            <u>גישה לשירות</u>
          </b>
          <p>
            TrainLit אינה מספקת ציוד לטובת גישה ו/או שימוש בשירות. לכן, באחריותך
            לוודא שהמחשב בו אתה משתמש, לרבות ללא הגבלה, מחשב נייח, מחשב נייד,
            טלפון נייד או כל מכשיר אלקטרוני אחר, עומד בכל המפרטים הטכניים
            הדרושים לגישה ושימוש בשירות. הינך אחראי לכל התשלומים, הוצאות, אגרות
            וכדומה, הנגבים על ידי צדדים שלישיים הקשורים לגישה ולשימוש שלך בשירות
            (לדוגמה, חיובים על ידי ספקי שירותי אינטרנט או חיובים בגין זמן
            אוויר). אין ביכולתה של TrainLit לערוב לכך שהשירות יפעל תמיד ללא
            הפרעות, עיכובים או שגיאות. קיימים מספר גורמים שעלולים להשפיע על
            איכות התקשורת והשימוש שלך בשירות ולהוביל לאי יכולת מצדך ליצור
            תקשורת, לרבות, ללא הגבלה, הרשת המקומית שלך, חומת האש, ספק שירותי
            האינטרנט שלך, האינטרנט הציבורי וספק הכוח שלך. TrainLit אינה נוטלת כל
            אחריות בגין כל הפרעה, שיבוש או עיכוב הנגרמים בשל כל בעיה או אי התאמה
            באיזה מהגורמים הללו או בגורמים אחרים שעליהם אין ל- TrainLitשליטה
            עליהם, לרבות, וללא הגבלה, בעיות או עיכובים הנובעים מסכסוכי עבודה,
            שביתות, הפסקת פעילות, מחסור או אי יכולת להשיג חשמל, חומרי גלם או
            ציוד, מלחמה, טרור, מהומות או כוח עליון.
          </p>
        </li>

        <li>
          <b>
            <u>זכות להשתמש בשירות</u>
          </b>
          <p>
            בכפוף להסכמתך לתנאים אלה ולכך שתפעל לפיהם, TrainLit מעניקה לך זכות
            אישית, מוגבלת, לא בלעדית ושאינה ניתנת להעברה לגישה ושימוש בשירות.
            אתה מאשר בזאת כי זכותך לגישה ושימוש בשירות מוגבלת על ידי תנאים אלה
            וכי אם תפר או אם בכל שלב שהוא, תסרב להסכים לאיזה מתנאים אלו, זכותך
            לגישה ושימוש בשירות תסתיים באופן מיידי. במידה ויקבע שהשירות או כל
            חלק ממנו הינו בלתי חוקיי על פי חוקי המדינה שבה אתה נמצא, לא תוענק לך
            כל זכות להשתמש או לקחת חלק בהם.
          </p>
        </li>

        <li>
          <b>
            <u>פרטי חשבון</u>
          </b>
          <p>
            במסגרת שימושך בשירות, תוכל לפתוח חשבון אישי. לשם כך, יהיה עליך לספק
            לנו פרטים כגון: שמך, טלפון וכתובת הדואר האלקטרוני שלך. לאחר מתן
            פרטים אלו, אנו ניצור עבורך חשבון משתמש (&quot;<b>חשבון</b>
            &quot;). במסגרת יצירת החשבון, תידרש לבחור שם משתמש וסיסמה שיאפשרו
            גישה לחשבונך (&quot;
            <b>פרטי ההתחברות</b>&quot;). אם הינך הורה, תבצע פעולה זו באופן עצמאי
            לך ולטובת ילדיך בעת הרישום והתשלום לפעילות המועדון.
          </p>
          <p>הכללים הבאים מסדירים את אופן אבטחת החשבון ופרטי ההתחברות שלך:</p>
          <ol>
            <li>
              אין לשתף את החשבון או את פרטי ההתחברות ואין לאפשר לאף אחד אחר גישה
              לחשבונך. כמו כן, אין לעשות דבר שיסכן את אבטחת החשבון שלך;
            </li>
            <li>
              במקרה שייוודע לך או ככל ותחשוד באופן סביר כי תנאי הבטיחות הופרו או
              נפרצו, לרבות, ללא הגבלה, כל אובדן, גניבה או מסירה בלתי מאושרת של
              פרטי ההתחברות שלך או גישה בלתי מאושרת לחשבון שלך, עליך להודיע מיד
              ל-TrainLit ולשנות את פרטי ההתחברות שלך;
            </li>
            <li>
              הינך אחראי באופן בלעדי לשמירה על פרטי ההתחברות שלך בסודיות וכן לכל
              שימוש בפרטי ההתחברות שלך;
            </li>
            <li>
              הינך אחראי על כל פעולה שמתרחשת באמצעות חשבונך, בין שהפעולות הללו
              התבצעו על ידך ובין שלא, לרבות, למען הסר ספק, פעולות שהתבצעו על ידי
              צדדים שלישיים. לכן, הינך מאשר בזאת כי ניתן לסגור את חשבונך במידה
              ואדם אחר משתמש בו על מנת לעסוק בכל פעילות שמפרה תנאים אלה או שהיא
              איננה נאותה או בלתי חוקית באופן אחר;
            </li>
            <li>
              הינך מתחייב לפקח על חשבונך ולהגביל את השימוש בו על ידי כל אדם
              המוגבל מלקבל תנאים אלה ו/או להשתמש בשירות, על פי ההוראות המפורטות
              להלן או על פי כל חוק שחל. הינך מקבל אחריות מלאה בגין כל שימוש בלתי
              מאושר בשירות על ידי כל אחד מהאמורים לעיל;
            </li>
            <li>
              TrainLit שומרת לעצמה את הזכות להסיר או לתבוע בחזרה כל שם משתמש בכל
              זמן ומכל סיבה, לרבות, ללא הגבלה, תביעות על ידי צד שלישי ששם משתמש
              מפר את זכויות הצד השלישי כאמור.
            </li>
          </ol>
          <p>
            כל המידע האישי שתספק ל- TrainLitבעת שתיצור או תעדכן את חשבונך או
            תשתמש בשירות, לרבות פרטי ההתחברות, יוחזק וייעשה בו שימוש בהתאם
            לתנאים אלה ומדיניות הפרטיות שלנו הזמינה ב:
            http://admin.trainlitapp.com/privacy אתה מסכים לספק לנו מידע נכון
            ומלא וכן לעדכן את המידע הזה מיד עם שינויו. אתה מתחייב ומאשר כי יש לך
            זכות וסמכות מלאות לספק ל- TrainLitאת המידע האמור לעיל, לרבות, ללא
            הגבלה, הסכמה של כל צד שלישי ובפרט הסכמת ההורה או האפוטרופוס הממונה
            עליך (ככל הדרוש על פי כל חוק החל).
          </p>
        </li>

        <li>
          <b>
            <u>הסדרי תשלום למתן השירות</u>
          </b>
          <p>
            אם הינך הורה, מאמן או שחקן ההרשמה לשירות והשימוש בו הינם ללא תשלום.
            יתכן ובשלב כלשהו, קבלת השירות תהא כרוכה בתשלום. ל-TrainLit עומדת
            הזכות לשנות/לעדכן/לבטל את תנאי השירות, לשנות את גובה דמי השימוש
            בשירות, לשנות את סוג חשבון המשתמש ו/או בסיסו, ו/או את מספר התשלומים,
            וזאת בכפוף לפרסום כל שינוי שכזה מראש באתר של TrainLit.
          </p>
          <p>
            אם הינך מנהל המועדון, סעיף זה אינו רלוונטי למועדון הספורט המתקשר עם
            המועדון בהסכם רישיון נפרד ובו פירוט העלויות השימוש.
          </p>
          <p>
            למען הסר ספק, במידה והינך הורה ו/או שחקן, יתכן כי תתבקש על ידי
            מועדון הספורט לבצע תשלומים למועדון הספורט באמצעות אפליקציית
            TrainLit. למען הסר ספק כלל הסדרי התשלומים, ביניהם הסכום לתשלום וכן
            כל החזר כספי אשר ידרשו בהקשר זה הינם באחריות מועדון הספורט. הינך
            מצהיר בזאת כי לא תהיה לך כל טענה כלפי TrainLit עבור תשלומים שבוצעו
            למועדון הכדורסל דרך אפליקציית TrainLit.
          </p>
        </li>

        <li>
          <b>
            <u> הפסקת חשבון על ידי TrainLit</u>
          </b>
          <p>
            TrainLit רשאית לסרב להעניק גישה לשירות, להשעות או להפסיק את חשבונך
            ללא הודעה, מכל סיבה או חשד כי תנאים אלה הופרו, או כי נעשה שימוש בלתי
            חוקי או בלתי הולם בחשבונך, או שימוש בלתי חוקי או בלתי הולם בשירות,
            במוצרים או בקניין רוחני של TrainLit, כפי שייקבע על ידי TrainLit לפי
            שיקול דעתה הבלעדי. אתה עלול לאבד את שם המשתמש שלך כתוצאה מסיום או
            השעיית החשבון, ללא כל אחריות מצידה של TrainLit לכל נזק שייגרם מהאמור
            לעיל. במידה והינך בעל יותר מחשבון אחד, TrainLit רשאית להשעות או
            לסיים את כלל חשבונותיך.
          </p>
          <p>
            הינך מאשר ש-TrainLit אינה נדרשת לספק לך הודעה לפני השעיה או סיום של
            חשבונך. במקרה ש- TrainLit תסיים את חשבונך, לא תוכל להשתתף או להשתמש
            בשירות שוב ללא הסכמה מפורשת של TrainLit. TrainLit שומרת לעצמה את
            הזכות לסרב לשמור חשבונות ולספק גישה לשירות לכל אדם שהוא. כמו כן, כפי
            שצוין, נאסר עלייך לאפשר לאדם שחשבונותיו הופסקו על ידינו להשתמש
            בחשבון שלך. במידה והינך סבור כי ננקטה פעולה כלשהי נגד חשבונך, אנא
            צור עימנו קשר ב: support@trainlitapp.com
          </p>
        </li>

        <li>
          <b>
            <u>כללי התנהגות ושימוש בשירות</u>
          </b>
          <p>
            הינך מתחייב ומאשר כי לך הזכות והסמכות המלאה להשתמש בשירות ולהיות
            מחויב על פי תנאים אלו. הינך מסכים למלא אחר תנאים אלו במלואם וכן
            לציית לכל החוקים, התקנות, החיקוקים והפקודות המקומיים והבינלאומיים
            הרלוונטיים לשימושך בשירות כאמור. מבלי להגביל את האמור לעיל ומתוך
            הכרה באופי הגלובלי של האינטרנט, אתה מסכים למלא אחר כל הכללים
            המקומיים והבינלאומיים בנוגע להתנהגות באינטרנט. כמו כן, הינך מסכים
            למלא אחר כל החוקים החלים המשפיעים על העברת תכנים או על פרטיותם של
            אנשים.
          </p>
          <p>
            הינך מתחייב שלא תונה או תנסה להונות את TrainLit או משתמשים אחרים וכי
            לא תפעל בחוסר תום לב בשימושך בשירות. במידה ו-TrainLit תקבע שפעלת
            בחוסר תום לב ו/או תוך הפרת תנאים אלה, או כי פעולותיך אינן נהוגות לפי
            סטנדרטים סבירים, TrainLit רשאית, לפי שיקול דעתה הבלעדי, לסיים את
            חשבונך ולאסור עליך להשתמש בשירות.
          </p>
          <p>בנוסף על האמור, וכדוגמה ולא כהגבלה, הינך מסכים שלא:</p>
          <ol>
            <li>
              ליצור חשבון או להשתמש בשירות אם תהיה חסום מלקבל את השירות על פי
              הוראות תנאים אלה או כל חוק רלוונטי;
            </li>
            <li>
              להעלות, לפרסם, להעביר או להפיץ באופן אחר כל חומר שהוא וולגרי,
              מגונה, גס, פורנוגרפי, בעל אופי מיני או שהוא, לדעת אדם סביר, פוגעני
              או מעורר התנגדות באופן אחר;
            </li>
            <li>
              להוציא דיבה, ללעוג, להכפיש, ללגלג, לעקוב, להפחיד, לאיים, להטריד או
              לפגוע באיש באיבה, על בסיס גזעי, אתני או בכל אופן אחר;
            </li>
            <li>
              להעתיק, לשכור, להחכיר, למכור, להעביר, להמחות, להעניק ברישיון משנה,
              לפרק, לפתח לאחור או להנדס לאחור (אלא אם מאושר באופן מפורש על ידי
              TrainLit או על פי חוק סטטוטורי רלוונטי), להתאים או לשנות כל חלק
              מהשירות;
            </li>
            <li>
              להעלות או להעביר (או לנסות להעלות או להעביר) קבצים המכילים
              וירוסים, סוסים טרויאנים, תולעים (worms), פצצות זמן (time bombs),
              רובוטי ביטול (cancelbots), קבצים או נתונים פגומים או כל תוכנה או
              תוכנית דומה אחרת שעלולה לפגוע בתפעול השירות או המחשבים או המכשירים
              הניידים של משתמשים אחרים בשירות;
            </li>
            <li>
              להפר זכויות חוזיות, אישיות, של קניין רוחני או אחרות של כל צד,
              לרבות על ידי שימוש, העלאה, העברה, הפצה או פרסום באופן אחר של כל
              מידע או חומר שהפך זמין באמצעות השירות בכל דרך המפרה זכויות יוצרים,
              סימני מסחר, פטנטים, סודות מסחריים או כל זכות אחרת של כל צד (לרבות
              זכויות של פרטיות או פרסום);
            </li>
            <li>
              ליצור זהות שקרית, מספר זהויות, חשבונות משתמש רבים, ליצור חשבון
              מטעם מישהו מלבד עצמך, להשתמש בבוטים או בתוכניות תכנות אוטומטי
              אחרות על מנת להונות או שמפרים באופן אחר תנאים אלה ו/או תנאי שירות
              של צדדים שלישיים;
            </li>
            <li>
              לנסות להשיג סיסמאות או מידע אישי אחר מחברים אחרים, לרבות מידע
              המאפשר זיהוי אישי (בין אם בצורת טקסט, תמונה או וידאו), מסמכי זיהוי
              או מידע פיננסי;
            </li>
            <li>
              להכין ו/או להציג מצגים בנוגע ו/או בקשר עם TrainLit אותם TrainLit
              לא אישרה מראש ובכתב. עליך לקבל אישור בכתב ומראש מ- TrainLit לגבי
              תוכן של כל מודעת שיווק וביחס לכל שימוש בשם המסחרי ו/או בסימני
              המסחר ו/או במדגמים של TrainLit בקשר לשירות;
            </li>
            <li>
              לשכור, להחכיר, למכור, לסחור, לתת במתנה, להעניק בירושה או להעביר
              באופן אחר את חשבונך לכל אחד ללא הסכמה בכתב ומראש של TrainLit;
            </li>
            <li>
              להיכנס או להשתמש בחשבון שהושכר, הוחכר, נמכר, נסחר, ניתן במתנה,
              הוענק בירושה או הועבר באופן אחר מיוצר החשבון ללא הסכמה בכתב ומראש
              של TrainLit;
            </li>
            <li>לעסוק בכל פעולת הונאה ביחס לשיטות תשלום;</li>
            <li>
              להפר כל חוק או תקנה רלוונטיים או לעודד או לקדם כל פעילות בלתי
              חוקית לרבות וללא הגבלה הפרת זכויות יוצרים, הפרת סימני מסחר, הכפשה,
              חדירה לפרטיות, גניבת זהות, פריצה למחשבים, פריצה או הפצת תוכנות
              מזויפות, או תרמיות או פריצה לשירות; ו/או
            </li>
            <li>
              לנסות להתערב, לפרוץ או לפענח שדרים מהשרתים ואליהם עבור השירות.
            </li>
          </ol>
          <p></p>
        </li>

        <li>
          <b>
            <u>בעלות בקניין רוחני</u>
          </b>
          <p>
            TrainLit ו/או החברות הקשורות לה שומרות לעצמן את כל הזכויות בשירות
            (לרבות, אולם ללא הגבלה, באפליקציות, תוכנות, מדגמים, שיטות, גרפיקה,
            טקסטים, מידע, תמונות, וידאו, קול, מוזיקה וקבצים אחרים ובבחירה
            ובארגון שלהם) (ביחד, &quot;חומרי השירות&quot;). כל חומרי השירות
            מוגנים על פי החוקים הרלוונטיים של זכויות יוצרים, חוזי המוצר, פטנטים
            וסמני מסחר, אמנות בינלאומיות וחוקים אחרים המגנים על קניין רוחני ועל
            זכויות קנייניות קשורות. נאסר עליך בעצמך וכן נאסר עליך לגרום לכל צד
            אחר להתאים, להנדס לאחור, לפרק, לפתח לאחור, להעתיק, להעביר, ליצור
            עבודות נגזרות, להשכיר, להעניק רישיון משנה, לחלק, לשכפל, למסגר, לפרסם
            מחדש, לאסוף, להוריד, להציג, להעביר, להעלות, להחכיר או למכור בכל צורה
            או בכל אמצעי, באופן מלא או חלקי, להשתמש לכל מטרה לבד מלצורך שימוש
            בשירות על פי תנאים אלה או להשתמש באופן אחר באיזה מחומרי השירות ללא
            הסכמה מפורשת, מראש ובכתב של TrainLit. כל יתר השימושים בחומרי זכויות
            יוצרים או סימני מסחר, לרבות כל שימוש נגזר, דורשים הסכמה מפורשת, מראש
            ובכתב של TrainLit. כל שכפול או הפצה מחדש של חומרים שלא בהתאם לתנאים
            אלה אסורים בהחלט ויובילו לסיום חשבונך ויהיו כפופים לעונשים אזרחיים
            ופליליים.
          </p>
          <p>
            אינך נדרש לספק ל-TrainLit כל משוב או הצעה בנוגע לשירות, או לאיזה
            מחומרי השירות. עם זאת, אם תספק ל- TrainLit הערות או הצעות לשינוי,
            תיקון, שיפור או השבחת, כי אז, בכפוף לתנאים ולהתניות של תנאים אלה,
            אתה מעניק בזאת להרשות רישיון לא בלעדי, כלל עולמי, בלתי חוזר וללא
            תמלוגים, לרבות זכות להעניק רישיון משנה, להשתמש ולמסור את ההערות
            וההצעות כאמור בכל אופן אותו TrainLit תבחר ולהציג, לבצע, להעתיק, לבצע
            העתקה, ליצור, לבצע יצירה, להשתמש, למכור, להציע למכירה ולמסור באופן
            אחר את המוצרים והתכנים של TrainLitושל בעלי רישיונות המשנה שלה,
            המגלמים את ההערות או ההצעות כאמור בכל דרך ובאמצעות כל מדיום ש -
            TrainLit תבחר, אולם ללא התייחסות למקור של ההערות או ההצעות כאמור.
          </p>
          <p>
            כל משוב, הצעות, דעות ועצות כאמור וכל המידע האחר המבוטא על ידי צדדים
            שלישיים כלשהם בשירות מייצגים את דעותיהם שלהם ולא את אלה של TrainLit.
            אין להסתמך על המשוב, ההצעות, הדעות, העצות או המידע האחר כאמור.
            TrainLitואף אחד מהחברות הקשורות לה או הסוכנים, הדירקטורים, העובדים,
            ספקי המידע, מעניקי הרישיון, בעלי הרישיון, נושאי המשרה ו/או הסניפים
            המתאימים שלהם לא יהיו אחראים ולא יהיו בעלי כל חבות בגין כל החלטה
            שתעשה על בסיס המידע כאמור.
          </p>
        </li>

        <li>
          <b>
            <u>הגבלת אחריות</u>
          </b>
          <p>
            הינך מסכים שהשימוש שלך בשירותים יהיה על אחריותך ועל סיכונך בלבד.
            השירותים מסופקים על בסיס &quot;כפי שהם&quot; (AS IS) ו-&quot;כפי שהם
            זמינים&quot; (AS AVAILABLE). אתה אחראי באופן בלעדי לכל פעולה ומחדל
            שיתבצעו או ייעשו תוך הסתמכות על השירותים. ככל המותר על פי החוק,
            TrainLit, בעלי המניות, הדירקטורים, נושאי המשרה, הסוכנים, הקבלנים,
            השותפים, היועצים ו/או העובדים שלה לא נושאים בשום אחריות, מפורשת או
            מכללא, בקשר לשירות והשימוש שלך בו.
          </p>
          <p>
            יודגש כי תיתכן אפשרות שבמידע המופיע בשירות זה נפלו טעויות הגהה,
            טעויות ניסוח, טעויות סופר וכיוצא בזה, וכן, טעויות שנגרמו עקב חדירה
            של גורמים לא מורשים. כמו כן, כפי שצוין לעיל TrainLit אינה מתאימה כל
            תכנית אימונים לשחקנים ספציפיים, והשתתפות בתכנית כאמור הינה על אחריות
            המשתמש בלבד. אם כן, TrainLit לא תהיה אחראית לנזק שנגרם עקב טעויות
            מכל סוג כאמור או עקב אי התאמה של תכנית אימונים לשחקן מסוים. לאור
            זאת, TrainLit לא נוטלת כל חבות או אחריות בגין כל:
          </p>
          <ol>
            <li>טעות, שגיאה, אי דיוק או חוסר התאמה של כל תוכן;</li>
            <li>
              נזקים ישירים, עקיפים, מיוחדים, מקריים, עונשיים או תוצאתיים, לרבות
              מבלי לגרוע, נזק אישי, נזק לרכוש ו/או נזקים כספיים, נזק רפואי, מכל
              סוג, הנובעים משימוש בשירות;
            </li>
            <li>
              גישה או שימוש בלתי מאושרים לחומרי צד שלישי, שרתים מאובטחים ו/או כל
              מידע אישי ו/או מידע פיננסי המאוחסן בהם;
            </li>
            <li>הפרעה או הפסקה של העברה אל השירות או ממנו;</li>
            <li>
              באג, וירוס, סוס טרויאני וכיוצ&quot;ב שיועברו אל או באמצעות השירות
              על ידי כל צד שלישי;
            </li>
            <li>תוצאות שיושגו כתוצאה משימוש בשירות;</li>
            <li>איכות כל מידע או חומר אחר שהושגו על ידך באמצעות השירות;</li>
            <li>
              חומר שמפורסם, מוצג ו/או מוצע באמצעות השירות, שלמותו, דייקנותו ו/או
              מהימנותו; או
            </li>
            <li>
              טעות או השמטה בכל תוכן או בגין אובדן או נזק מכל סוג שייגרם כתוצאה
              מהשימוש בכל תוכן שהועלה, נשלח בהודעה, הועבר או הפך זמין באופן אחר
              באמצעות השירות.
            </li>
          </ol>
          <p>
            TrainLit לא תהיה אחראית לכל נזק, הפסד, חסרון כיס או הוצאה שייגרמו לך
            אם תפעל על בסיס המידע, התכנים או המסרים המועברים באמצעות השירות וכל
            הסתמכות עליהם הינה באחריותך הבלעדית. הנתונים המוצגים בשירות מוטמעים
            על ידי TrainLit אך מוזנים על ידי המשתמש, ולכן אין ל- TrainLit אחריות
            לתוכנם, לנכונותם או למהימנותם.
          </p>
          <p>
            מבלי לגרוע מהאמור לעיל, TrainLit, בעלי המניות, הדירקטורים, נושאי
            המשרה, הסוכנים, הקבלנים, השותפים, היועצים ו/או העובדים שלה לא יהיו
            בשום מקרה אחראים כלפיך או כלפי כל צד שלישי בגין כל נזקים מיוחדים,
            ישירים, עקיפים, מקריים, עונשיים או תוצאתיים, לרבות כל אובדן רווחים
            או אובדן נתונים, הנובעים מהשימוש שלך בשירות או בחומרים אחרים שאליהם
            נחשפת באמצעות השירות, בין שעל בסיס אחריות, חוזה, נזיקין או כל
            תיאוריה משפטית אחרת ובין ש- TrainLitקיבלה הודעה על האפשרות של נזקים
            אלה ובין שלא. מבלי לגרוע מהאמור לעיל, בכל מקרה, החבות המרבית של
            TrainLit בכל המקרים ובשל כל העילות תוגבל להחזר הכספי או לזיכוי
            המשתמש בגובה סכום דמי השימוש לשירות ששולמו בפועל בלבד, במשך 12
            החודשים שקדמו לאירוע(ים) שיצר את החבות, אלא אם החוק מחייב אחרת.
          </p>
        </li>

        <li>
          <b>
            <u>חומרים של צדדים שלישיים; מנועי חיפוש</u>
          </b>
          <p>
            אתה עשוי להיות רשאי להיכנס, לבדוק, להציג או להשתמש בשירותים,
            במשאבים, בתכנים, במידע או בקישורים של צדדים שלישיים, לאתרים או
            למשאבים אחרים (&quot;חומרי צדדים שלישיים&quot;) באמצעות השירות.
            חומרי הצדדים השלישיים אינם תחת שליטתה של TrainLit ואתה מאשר כי הינך
            נוטל אחריות בלעדית ונוטל את כל הסיכונים הנובעים מכניסה, שימוש או
            הסתמכות על חומרי צדדים שלישיים כאמור. TrainLitלא תישא בשום חבות
            שתיגרם לך כתוצאה מכניסה, שימוש או הסתמכות על חומרי צדדים שלישיים
            באמצעות השירות. אתה מאשר ומסכים ש- TrainLit:
          </p>
          <ol>
            <li>
              אינה אחראית על זמינות, דיוק, שלמות, איכות ו/או חוקיות חומרי הצדדים
              השלישיים כאמור ו/או המוצרים ו/או השירותים הזמינים בחומרי הצדדים
              השלישיים כאמור;{" "}
            </li>
            <li>
              אינה אחראית כלפיך או כלפי כל צד שלישי בגין כל נזק, פגיעות או
              הפסדים כתוצאה מכניסתך או שימושך בחומרי הצדדים השלישיים כאמור; ו-{" "}
            </li>
            <li>
              אינה מציגה כל הבטחה להסיר את אפשרות הגישה לחומרי צדדים שלישיים
              באמצעות השירות. יכולתך להיכנס לקישור לחומרי צדדים שלישיים או
              לשירותי צדדים שלישיים אינה מצביעה על כל מתן חסות על ידי TrainLit
              לחומרי צדדים שלישיים או לשירותים של צדדים שלישיים כלשהם.
            </li>
          </ol>
          <p></p>
        </li>

        <li>
          <b>
            <u>הודעות וניוזלטר</u>
          </b>
          <p>
            כתובת המייל ומספר הטלפון שתמסור לנו, ישמשו בין היתר כדי להציע לך
            שירותים שונים מעת לעת ו/או הצעות שיווקיות שונות ו/או &quot;דבר
            פרסומת&quot; בהתאם לחוק התקשורת (בזק ושירותים), התשמ&quot;ב-1982,
            בכל אמצעי התקשורת המפורטים בחוק התקשורת, לרבות דואר אלקטרוני והודעת
            מסר קצר, אלא אם תודיע ל- TrainLit על אי רצונך לקבל הודעות מסוג זה.
            בנוסף, TrainLit רשאית לשלוח לך הודעות על שינויים בתנאים אלה או בנוגע
            לעניינים אחרים, לרבות הודעות הקשורות לשימוש בשירות והודעות תפעוליות
            אחרות. אתה מסכים שכל ההסכמים, ההודעות, הגילויים וכל תקשורת אחרת
            ש-TrainLit שולחת כאמור לעיל עומדים בכל דרישה חוקית שהתכתובת כאמור
            תהיה בכתב. כל ההודעות הנשלחות אליך יהוו משלוח והודעה מספיקים ותקפים
            אליך, בין שנכנסת או קראת את ההודעה ובין שלא, וייחשבו כאילו נשלחו
            אליך, בין שהתקבלו על ידך בפועל ובין שלא.
          </p>
        </li>

        <li>
          <b>
            <u>כללי</u>
          </b>
          <p>
            על ידי שימוש בשירות, הינך מסכים כי כל חוקי מדינת ישראל, ללא קשר
            לכללי ברירת הדין וללא קשר למיקומך, ישלטו בתנאים אלה ובכל סכסוך מכל
            סוג שיעלה בינך לבין TrainLit. כל תביעה או סכסוך בינך לבין TrainLit
            שינבע במלואו או בחלקו משימושך בשירות, יוכרע באופן בלעדי על ידי בית
            משפט מוסמך הממוקם בתל אביב, ישראל, כשלבתי משפט אחרים לא תהיה סמכות,
            ואתה מסכים בזאת ומוותר על כל טענה בדבר חוסר סמכות מקומית ופורום לא
            נאות ביחס למקום וסמכות השיפוט של בתי המשפט של תל אביב, ישראל. על אף
            כל חוק או חקיקה סותרים, אתה מסכים כי כל תביעה או עילת תביעה הנובעות
            או קשורות לשירותים תוגש על ידך תוך שנה אחת (1) לאחר התרחשות עילת
            התביעה, שאם לא כן תבוטל עילת התביעה לצמיתות.
          </p>
          <p>
            אי אכיפה על ידי TrainLit של כל הוראה בתנאים אלה לא תהווה ויתור כל
            אכיפה נוספת של הוראה זו או של כל הוראה אחרת. אם, מכל סיבה, בית משפט
            מוסמך יקבע שהוראה כלשהי בתנאים אלה בלתי תקפה או בלתי ניתנת לאכיפה,
            הוראה זו תיאכף במידה האפשרית המותרת ושאר ההוראות בתנאים אלה יישארו
            במלוא התוקף. תנאים אלה מהווים את מלוא ההבנה הבלעדית בין TrainLit
            לבינך בנוגע לתוכנם והם מחליפים את כל הצעות המחיר, ההסכמים, התכתובות
            או ההבנות, הקודמים או הנוכחיים, בין בכתב או בעל פה, הקשורים לתוכנם.
          </p>
        </li>

        <li>
          <b>
            <u>יצירת קשר</u>
          </b>
          <p>
            במידה ויש לך שאלות בנוגע לתנאי שימוש אלו או בנוגע לכל נושא אחר
            בעניין השירות, אנא צור עמנו קשר בכתובת support@trainlitapp.com
          </p>
        </li>
      </ol>
      <p>עדכון אחרון: 31באוגוסט, 2019.</p>
    </div>
  );
}
