import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Hidden,
  IconButton,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { Image } from "cloudinary-react";
import BackArrowIcon from "@material-ui/icons/ArrowBackIos";
import CloseIcon from "@material-ui/icons/Close";

import { displayPrice } from "../../lib/helpers";
import MenuItem from "../base/MenuItem";

export default function PurchaseProductDialog(props) {
  const [currentPlayer, setCurrentPlayer] = useState("");
  const [currentOption, setCurrentOption] = useState("");
  const [currentQuantity, setCurrentQuantity] = useState("");
  const classes = useStyles();

  const product = props.product || {};

  const hasOptions = product.options?.length > 0;

  useEffect(() => {
    setCurrentOption("");
  }, [props.product]);

  useEffect(() => {
    if (!props.isOpen) {
      setCurrentOption("");
      setCurrentPlayer("");
      setCurrentQuantity("");
    }
  }, [props.isOpen, props.players]);

  // prevent dialog view getting broken right before closing
  if (!props.product) {
    return <span />;
  }

  const handleAddItem = () => {
    props.onAddItem(currentQuantity, currentPlayer, currentOption);
  };

  return (
    <Dialog
      open={props.isOpen}
      maxWidth="xs"
      PaperProps={{ className: classes.dialog }}
      dir="rtl"
      onClose={props.onClose}
    >
      <DialogContent dividers>
        <div className={classes.header}>
          <IconButton onClick={props.onClose}>
            <Hidden xsDown>
              <CloseIcon />
            </Hidden>
            <Hidden smUp>
              <BackArrowIcon />
            </Hidden>
          </IconButton>
        </div>
        <Grid container spacing={1}>
          <Grid item container xs={12} justifyContent="center">
            <Image
              version={product.imageVersion}
              publicId={product.imagePublicId}
              height="200"
              width="200"
              alt="Logo"
              className={classes.image}
            />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.title}>{product.title}</div>
          </Grid>
          {product.description && (
            <Grid item xs={12}>
              {product.description}
            </Grid>
          )}
          <Grid item xs={12}>
            {displayPrice(product.price)}
          </Grid>

          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              value={currentPlayer}
              onChange={(e) => setCurrentPlayer(e.target.value)}
              label="ספורטאי/ת"
            >
              {props.players.map((player) => (
                <MenuItem value={player._id} key={player._id}>
                  {player.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {product.options?.length > 0 && (
            <Grid item xs={12}>
              <TextField
                select
                label={product.optionsType}
                fullWidth
                value={currentOption}
                onChange={(e) => setCurrentOption(e.target.value)}
              >
                {product.options.map((option) => (
                  <MenuItem value={option._id} key={option._id}>
                    {option.title}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}

          <Grid item xs={12}>
            <TextField
              select
              label="כמות"
              fullWidth
              value={currentQuantity}
              onChange={(e) => setCurrentQuantity(e.target.value)}
            >
              {[...Array(10).keys()].map((i) => (
                <MenuItem value={i + 1} key={i}>
                  {i + 1}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} container justifyContent="center">
            <Button
              variant="outlined"
              className={classes.addButton}
              onClick={handleAddItem}
              disabled={
                !currentPlayer ||
                (hasOptions && !currentOption) ||
                !currentQuantity
              }
            >
              הוספה לסל הקניות
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "flex-start",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-end",
    },
  },
  addButton: {
    marginTop: 30,
    width: 200,
  },
  image: { alignSelf: "center", borderRadius: 7 },
  title: { fontSize: 20, fontWeight: "bold" },
  dialog: {
    [theme.breakpoints.down("xs")]: {
      margin: 0,
      width: "100%",
      maxWidth: "100%",
      height: "100%",
      maxHeight: "none",
      borderRadius: 0,
    },
  },
}));
