import {
  Toolbar,
  AppBar as MUAppBar,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Image } from "cloudinary-react";

import ProfileMenu from "./AppBar/ProfileMenu";

function AppBar(props) {
  const classes = useStyles();
  const { clubId } = useParams();

  return (
    <MUAppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.trainlitLogo}>
          <Image
            publicId={`general/trainlitLogoWhite`}
            alt="Logo"
            style={{ width: 150, marginTop: 10 }}
          />
        </div>
        <RouterLink
          color="secondary"
          to={`/clubs/${clubId}`}
          className={classes.homeLink}
        >
          <Typography variant="h5" className={classes.clubName} noWrap>
            {props.clubConfig && props.clubConfig.general.name}
          </Typography>
        </RouterLink>
        {props.isAuthenticated && <ProfileMenu />}
      </Toolbar>
    </MUAppBar>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    // zIndex: 1500,
    zIndex: theme.zIndex.drawer + 1,
    height: 65,
    background: theme.appBar.background,
  },
  toolbar: {
    paddingLeft: 0,
    display: "flex",
    justifyContent: "space-between",
  },
  clubName: {
    color: theme.appBar.color,
  },
  homeLink: {
    textDecoration: "none",
  },
  trainlitLogo: {
    width: 240,
    textAlign: "center",
    padding: 30,
  },
}));

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
  };
};

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(AppBar);
