import React from "react";
import {
  Hidden,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import { displayPrice } from "../../lib/helpers";
import PlusMinusButton from "../PlusMinusButton";
import Button from "../Button";

export default function MerchandiseReviewItem({
  item,
  title,
  description,
  onQuantityChange,
}) {
  const classes = useStyles();

  const renderActionDesktop = () => {
    return (
      <div className={classes.desktopActionContainer}>
        <Button
          fullWidth
          className={classes.deleteButton}
          onClick={() => onQuantityChange(-item.quantity)}
        >
          הסר פריט
        </Button>
        <PlusMinusButton value={item.quantity} onChange={onQuantityChange} />
      </div>
    );
  };

  const renderActionMobile = () => {
    return (
      <div className={classes.mobileActionContainer}>
        <PlusMinusButton value={item.quantity} onChange={onQuantityChange} />
        <IconButton
          style={{ padding: 7 }}
          edge="end"
          onClick={() => onQuantityChange(-item.quantity)}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    );
  };

  return (
    <ListItem className={classes.listItem}>
      <ListItemText
        primary={title}
        secondaryTypographyProps={{ component: "span" }}
        secondary={
          <span
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {description}
            <span className={classes.priceContainer}>
              <strong>{displayPrice(item.price)}</strong>
            </span>
            <Hidden smUp>{<span>{renderActionMobile()}</span>}</Hidden>
          </span>
        }
      />

      <Hidden xsDown>
        <ListItemSecondaryAction>
          {renderActionDesktop()}
        </ListItemSecondaryAction>
      </Hidden>
    </ListItem>
  );
}

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1),
    margin: theme.spacing(1, 0),
    border: "1px solid #EEE",
    borderRadius: 5,

    [theme.breakpoints.down(700 + theme.spacing(3) * 2)]: {
      borderRadius: 0,
      borderLeftWidth: 0,
      borderRightWidth: 0,
    },
  },
  priceContainer: {
    marginTop: 10,
    color: "#000",
  },
  deleteButton: { marginBottom: 10 },
  mobileActionContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 5,
  },
  desktopActionContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
  },
}));
