import {
  Typography,
  Grid,
  CircularProgress,
  withStyles,
  Container,
  Link,
} from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import { Link as RouterLink } from "react-router-dom";
import { Alert } from "@material-ui/lab";

import axios from "../../lib/axios";
import { validateParent } from "../Purchase/helpers/validator";
import ParentForm from "./ParentForm";
import * as Actions from "../../store/actions";
import { clubUrl, normalizeServerValidationErrors } from "../../lib/helpers";
import VerifyParentPhonePopUp from "./VerifyParentPhonePopUp";
import Paper from "../../components/Paper";
import Button from "../../components/Button";
import { sendMessage } from "../../lib/wrapperHelper";

let PREFILLED = process.env.NODE_ENV === "production" ? false : false;

class NewParent extends Component {
  constructor(props) {
    super(props);

    const qs = queryString.parse(props.location.search);

    this.state = {
      parent: {
        firstName: PREFILLED ? "יוסי" : "",
        lastName: PREFILLED ? "משפחה" : "",
        phone: qs.phone || (PREFILLED ? "0547123123" : ""),
        email: PREFILLED ? "gilad+asdfs@trainlitapp.com" : "",
        street: PREFILLED ? "רחובבב" : "",
        houseNumber: PREFILLED ? "12" : "",
        city: PREFILLED ? "תא" : "",
        secondParentName: "",
        secondParentPhone: "",
        password: PREFILLED ? "Password1" : "",
        id: "",
        termsAgreed: false,
      },
      errors: {},
      isPopUpOpen: qs.verification === "true",
      success: null,
      instantValidate: false,
      isLoading: false,
      uniquePhoneError: false,
      uniqueIdError: false,
      serverError: false,
    };
  }

  onChange = async (changes) => {
    let errors;
    if (this.state.instantValidate) {
      errors = await validateParent({ ...this.state.parent, ...changes });
    }

    this.setState((prevState) => {
      errors = errors || prevState.errors;

      return {
        ...prevState,
        parent: {
          ...prevState.parent,
          ...changes,
        },
        errors,
        uniqueIdError: changes.id ? false : prevState.uniqueIdError,
        uniquePhoneError: changes.phone ? false : prevState.uniquePhoneError,
      };
    });
  };

  handleSubmit = async () => {
    const errors = await validateParent(this.state.parent);
    const isValid = Object.keys(errors).length === 0;

    if (isValid) {
      try {
        const { parent } = this.state;
        const data = {
          firstName: parent.firstName,
          lastName: parent.lastName,
          email: parent.email,
          street: parent.street,
          houseNumber: parent.houseNumber,
          city: parent.city,
          phone: parent.phone,
          secondParentName: parent.secondParentName,
          secondParentPhone: parent.secondParentPhone,
          password: parent.password,
          id: parent.id,
          clubId: this.props.match.params.clubId,
        };
        this.setState({ isLoading: true });
        const res = await axios.post("/parents", data);

        this.setState({
          isPopUpOpen: true,
          success: true,
          parentId: res.data._id,
          isLoading: false,
          serverError: false,
        });
        this.props.history.replace(
          `${this.props.location.pathname}?&phone=${this.state.parent.phone}&verification=true`
        );
      } catch (error) {
        let errors = {};
        let uniquePhoneError = null;
        let uniqueIdError = null;

        if (
          error.response &&
          error.response.status === 422 &&
          error.response.data &&
          error.response.data.errors
        ) {
          uniquePhoneError = error.response.data.errors.phone === "unique";
          uniqueIdError = error.response.data.errors.id === "unique";
          errors = normalizeServerValidationErrors(error.response.data.errors);
          // don't need to show both
          if (uniquePhoneError) errors.phone = "";
          if (uniqueIdError) errors.id = "";
        }
        this.setState({
          success: false,
          isLoading: false,
          errors,
          uniquePhoneError,
          uniqueIdError,
          serverError: Object.keys(errors).length === 0, // if no other error
        });
      }
    } else {
      this.setState({ errors, instantValidate: true });
    }
  };

  handleLogin = async (parent, token) => {
    sendMessage("login", {
      parentId: parent._id,
      token,
      fcmToken: parent.fcmToken,
    });
    this.props.login(parent._id, token, parent);
    this.props.setIsLoadingParent(true);
    this.props.setParent(parent);
    this.props.setIsLoadingParent(false);
    if (this.props.isMobileApp) {
      this.props.history.replace("/login");
    }
  };

  handlePopUpClose = () => this.setState({ isPopUpOpen: false });

  render() {
    const { classes } = this.props;
    const { clubId } = this.props.match.params;

    return (
      <Container maxWidth="md">
        <Paper
          bodyClassName={classes.paper}
          title="חשבון חדש"
          bigTitle
          titleBackground
        >
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              style={{ border: "0px solid", paddingTop: 0, paddingBottom: 0 }}
            >
              <Typography variant="h6">פרטי הלקוח/הורה</Typography>
            </Grid>
            {this.state.serverError && (
              <Grid item xs={12}>
                <Alert severity="error">אירעה שגיאה. אנא נסו מאוחר יותר</Alert>
              </Grid>
            )}
            {this.state.uniquePhoneError && (
              <Grid item xs={12}>
                <Alert severity="error">
                  מספר הטלפון {this.state.parent.phone} קיים כבר במערכת.
                  להתחברות עם מספר זה{" "}
                  <Link component={RouterLink} to={clubUrl(clubId)}>
                    לחצו כאן
                  </Link>
                </Alert>
              </Grid>
            )}
            {this.state.uniqueIdError && (
              <Grid item xs={12}>
                <Alert severity="error">
                  תעודת הזהות {this.state.parent.id} קיימת כבר במערכת
                </Alert>
              </Grid>
            )}
            <Grid item>
              <ParentForm
                state={this.state.parent}
                errors={this.state.errors}
                onChange={this.onChange}
                clubId={this.props.match.params.clubId}
              />
            </Grid>
            <Grid item xs={12} className={classes.submitButtonContainer}>
              <Button
                className={classes.submitButton}
                variant="contained"
                onClick={this.handleSubmit}
                disabled={this.state.isLoading}
                color="primary"
                debounce
              >
                {this.state.isLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  "צור חשבון"
                )}
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <VerifyParentPhonePopUp
          open={this.state.isPopUpOpen}
          onClose={() => this.setState({ isPopUpOpen: false })}
          phone={this.state.parent.phone}
          login={this.handleLogin}
        />
      </Container>
    );
  }
}

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    },
  },
  submitButton: {
    width: 200,
    height: 35,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  submitButtonContainer: {
    justifyContent: "flex-end",
    display: "flex",
  },
});

const mapStateToProps = (state) => {
  return {
    isMobileApp: state.general.isMobile,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    login: (parentId, token, parent) =>
      dispatch(Actions.auth.login(parentId, token, parent)),
    setParent: (parent) => dispatch(Actions.auth.setParent(parent)),
    setIsLoadingParent: (isLoading) =>
      dispatch(Actions.auth.setIsLoadingParent(isLoading)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(NewParent));
