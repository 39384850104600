import React, { Component } from "react";
import moment from "moment";
import {
  CircularProgress,
  Grid,
  Typography,
  Snackbar,
  Container,
  Avatar,
  withStyles,
  Hidden,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import axios from "../../lib/axios";
import Button from "../../components/Button";
import MedicalForm from "../Purchase/MedicalForm";
import PlayerForm from "../Purchase/PlayerForm";
import { validatePlayer } from "../Purchase/helpers/validator";
import Paper from "../../components/Paper";
import AbstractBackground from "../../resources/images/paperBackground.jpg";

class EditPlayer extends Component {
  state = {
    player: null,
    errors: {},
    instantValidate: false,
    isSnackOpen: false,
    snackKey: undefined,
    snackStatus: null,
  };

  componentDidMount = () => {
    this.loadData();
  };

  loadData = async () => {
    this.setState({ isLoading: true });
    const res = await axios.get(`/players/${this.props.match.params.playerId}`);
    const player = res.data;
    const orgPlayer = { ...player };

    player.dobDay = moment(player.dateOfBirth).date();
    player.dobMonth = moment(player.dateOfBirth).month() + 1;
    player.dobYear = moment(player.dateOfBirth).year();
    player.phone = player.phone || "";
    player.id = player.id || "";
    player.medicalIssuesUpdate = false;
    player.school = player.school || "";

    this.setState({
      orgPlayer,
      player,
      isLoading: false,
    });
  };

  onChange = async (changes) => {
    let errors;
    if (this.state.instantValidate) {
      errors = await validatePlayer({ ...this.state.player, ...changes });
    }

    if (changes.hasMedicalIssues === false) {
      changes.medicalIssues = "";
    }

    this.setState((prevState) => {
      return {
        ...prevState,
        player: { ...prevState.player, ...changes },
        errors: errors || prevState.errors,
      };
    });
  };

  handleSubmit = async () => {
    const errors = await validatePlayer(this.state.player);
    const isValid = Object.keys(errors).length === 0;

    if (isValid) {
      try {
        const { player } = this.state;
        const data = {
          firstName: player.firstName,
          lastName: player.lastName,
          class: player.class,
          gender: player.gender,
          dateOfBirth: moment([
            player.dobYear,
            player.dobMonth - 1,
            player.dobDay,
          ]).format("YYYY-MM-DD"),
          hasMedicalIssues: player.hasMedicalIssues,
          medicalIssues: player.medicalIssues,
          phone: player.phone,
          school: player.school,
        };
        if (this.state.orgPlayer.id === undefined) {
          data.id = player.id;
        }

        this.setState({ isLoading: true });
        const res = await axios.patch(
          `/players/${this.state.player._id}`,
          data
        );
        this.setState({
          success: true,
          playerId: res.data._id,
          isLoading: false,
          isSnackOpen: true,
          snackStatus: "success",
          snackKey: moment().format(),
        });
        if (this.state.orgPlayer.id === undefined) {
          this.loadData();
        }
      } catch (error) {
        let errors = {};
        this.setState({
          success: false,
          isLoading: false,
          errors,
          instantValidate: true,
          isSnackOpen: true,
          snackStatus: "error",
        });
      }
    } else {
      this.setState({ errors, instantValidate: true });
    }
  };

  handleCloseSnack = () => {
    this.setState({ isSnackOpen: false, snackStatus: null });
  };

  renderForm = () => (
    <Grid container spacing={3}>
      <Grid item>
        <PlayerForm
          state={this.state.player}
          errors={this.state.errors}
          onChange={this.onChange}
          id={this.state.orgPlayer.id === undefined}
        />
      </Grid>
      <Grid item>
        <Typography variant="h6" gutterBottom>
          הצהרת בריאות
        </Typography>
        <MedicalForm
          state={this.state.player}
          errors={this.state.errors}
          onChange={this.onChange}
        />
      </Grid>
      <Hidden xsDown>{this.state.isSnackOpen && this.renderAlert()}</Hidden>
      <Grid item xs={12} container justifyContent="flex-end">
        <Button
          className={this.props.classes.addPlayerButton}
          variant="outlined"
          onClick={this.handleSubmit}
          theme="action"
          isLoading={this.state.isLoading}
        >
          שמירה
        </Button>
      </Grid>
    </Grid>
  );

  renderAlert = () => (
    <Alert
      onClose={this.handleCloseSnack}
      severity={this.state.snackStatus}
      className={this.props.classes.alert}
    >
      {this.state.snackStatus === "success"
        ? "פרטי הספורטאי/ת עודכנו"
        : "אירעה שגיאה. אנא נסו מאוחר יותר"}
    </Alert>
  );

  render() {
    const { classes } = this.props;
    const { orgPlayer } = this.state;

    return (
      <Container maxWidth="md">
        <Paper className={classes.headPpaperContainer}>
          <div className={classes.headPaper}>
            <Avatar />
            <Typography variant="h5">
              {orgPlayer && `${orgPlayer.firstName} ${orgPlayer.lastName}`}
            </Typography>
            {orgPlayer && <div> ת.ז {orgPlayer.id}</div>}
          </div>
        </Paper>
        <Paper
          title="פרטי הספורטאי/ת"
          paperProps={{ className: classes.container }}
        >
          {this.state.player ? this.renderForm() : <CircularProgress />}
          <Hidden smUp>
            <Snackbar
              open={this.state.isSnackOpen}
              autoHideDuration={6000}
              onClose={this.handleCloseSnack}
              key={this.state.isSnackOpen ? this.state.snackKey : undefined}
            >
              {this.renderAlert()}
            </Snackbar>
          </Hidden>
        </Paper>
      </Container>
    );
  }
}

const styles = (theme) => ({
  headPpaperContainer: {
    ...(theme.paper.header
      ? {}
      : {
          backgroundImage: `url(${AbstractBackground})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }),
  },
  headPaper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    margin: 10,
  },
  container: {
    marginTop: 20,
  },
  alert: {
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
  },
});

export default withStyles(styles)(EditPlayer);
