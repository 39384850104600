import React from "react";
import _ from "lodash";
import {
  CircularProgress,
  withStyles,
  Stepper,
  Step,
  StepLabel,
  Hidden,
} from "@material-ui/core";
import { connect } from "react-redux";
import queryString from "query-string";
import clsx from "clsx";

import * as Actions from "../../store/actions";
import axios from "../../lib/axios";
import SubscriptionForm from "./SubscriptionForm";
import MerchandiseForm from "./MerchandiseForm";
import PaymentForm from "../../components/store/PaymentForm";
import Review from "../../components/store/Review";
import Confirmation from "../../components/store/Confirmation";
import ErrorDialog from "./ErrorDialog";
import * as Cart from "../../components/store/helpers/cart";
import * as formValidator from "./helpers/validator";
import clubsConfig from "../../config/clubs.json";
import Paper from "../../components/Paper";
import Button from "../../components/Button";
import UpdatePlayerDetailsForm from "./UpdatePlayerDetailsForm";
import ShoppingCartIndicator from "./ShoppingCartIndicator";

class Purchase extends React.Component {
  initialSubscriptionFields = () => ({
    level: "",
    subLevel: "",
    teamId: "",
    playerId: "",
    pricingId: "",
    errors: {},
  });

  initState = () => {
    const qs = queryString.parse(this.props.location.search);
    const modes = ["store", "payment", "subscription"];
    if (qs.mode && modes.indexOf(qs.mode) === -1) {
      this.props.history.replace(`/clubs/${this.props.match.params.clubId}/`);
    }

    const mode = qs.mode || "subscription";

    let supported = true;
    if (mode === "store" || mode === "payment") {
      if (
        this.props.clubConfig.paymentMethods === "other" ||
        this.props.clubConfig.paymentMethods === "none"
      ) {
        supported = false;
      }
    }

    return {
      supported,
      loading: true,
      activeStep: 0,
      playerId: qs.playerId,
      subscriptionId: qs.subscriptionId,
      mode,
      club: null,
      cart: Cart.init(),
      forms: {
        subscription: this.initialSubscriptionFields(),
        merchandise: { errors: {} },
        player: { errors: {} },
        payment: {
          paymentMethod: "creditCard",
          token: null,
          ccResponse: null,
          last4: null,
          numberOfPayments: mode === "subscription" ? "" : 1,
          errors: {},
          termsAgreed: false,
          discountReason: "",
        },
        review: {
          errors: {},
        },
      },
      error: {},
      isErrorDialogOpen: false,
    };
  };

  constructor(props) {
    super(props);

    this.state = this.initState();
  }

  steps = (mode) => {
    switch (mode) {
      case "subscription":
        return [
          { label: "קבוצה", id: "subscription" },
          { label: "עדכון פרטי הספורטאי/ת", id: "updatePlayerDetails" },
          ...(this.props.clubConfig.store // can't have sub+merch with other or all paymentMethods
            ? [{ label: "מוצרים נלווים", id: "merchandise" }]
            : []),
          { label: "סיכום", id: "review" },
          { label: "תשלום", id: "payment" },
        ];

      case "store":
        return [
          { label: "מוצרים", id: "merchandise" },
          { label: "סיכום", id: "review" },
          { label: "תשלום", id: "payment" },
        ];

      case "payment":
        return [
          { label: "סיכום", id: "review" },
          { label: "תשלום", id: "payment" },
        ];

      default:
        throw new Error("unkonwn mode");
    }
  };

  prepareLevels = (teams, subscriptionProducts) => {
    teams.forEach((t) => {
      t.product = t.productId
        ? subscriptionProducts.find((sp) => sp._id === t.productId)
        : {
            _id: "external",
            price: 0,
            subscriptionDetails: { subscriptionType: "external" },
          };
    });

    //remove teams with inactive or expired product
    teams = teams.filter((t) => t.product);
    teams = _.sortBy(teams);

    let levels = _.groupBy(teams, (t) => t.teamLevel._id);
    levels = Object.entries(levels).map(([_id, teams]) => {
      const bySubLevel = _.groupBy(teams, (t) => (t.teamSubLevel || {})._id);
      const subLevels = Object.entries(bySubLevel).map(([slId, slTeams]) => ({
        _id: slId === "undefined" ? "" : slId,
        teams: _.sortBy(slTeams, "name"),
        name: (slTeams[0].teamSubLevel || {}).name,
      }));

      return {
        _id,
        subLevels: _.sortBy(subLevels, "name"),
        name: teams[0].teamLevel.name,
      };
    });

    levels = _.sortBy(levels, "name");
    return levels;
  };

  componentDidMount = () => {
    this.loadData();
  };

  componentDidUpdate = async (prevProps) => {
    if (this.props.location.search !== prevProps.location.search) {
      this.setState(this.initState(), this.loadData);
    }
  };

  loadData = async () => {
    const clubInternalName = this.props.match.params.clubId;
    const promises = [];
    const data = {};
    let loadError = false;

    promises.push(
      axios
        .get(`/clubs/${clubInternalName}?idType=internalName`)
        .then((res) => {
          data.club = res.data;
        })
    );

    promises.push(
      axios
        .get(`/clubs/${clubInternalName}/terms`)
        .then((res) => (data.terms = res.data))
    );

    let hasProduct = "";
    switch (this.props.clubConfig.registrationTeamType) {
      case "direct":
        hasProduct = "&hasProduct=true";
        break;
      case "indirect":
        hasProduct = "&hasProduct=false";
        break;

      default:
        break;
    }
    promises.push(
      axios
        .get(`/clubs/${clubInternalName}/teams?status=active${hasProduct}`)
        .then((res) => {
          data.teams = res.data;
        })
    );

    if (this.props.clubConfig.store) {
      promises.push(
        axios
          .get(
            `/clubs/${clubInternalName}/store/products?category=merchandise&active=true`
          )
          .then((res) => {
            data.merchandise = _.sortBy(res.data, "priority");
          })
      );
    }

    promises.push(
      // get all players for the merchandise
      // BUT this.state.playerId is required for subscriptions
      axios.get(`/players?granularity=clubPlayers`).then(
        (res) => {
          data.players = res.data;
          if (!this.props.clubConfig.general.guestMerchandise) {
            data.players = data.players.filter(
              (p) =>
                p._id === this.state.playerId || // subscription mode => add the current player (he might not be registered to the club yet)
                p.clubPlayers.find((cp) => cp.club === this.props.club._id)
            );
          }

          if (this.state.mode === "subscription") {
            data.player = res.data.find((p) => p._id === this.state.playerId);
          } else if (data.players.length === 0) {
            // message might be wrong if guestMerchandise is NOT allowed
            this.props.clubConfig.general.guestMerchandise
              ? this.showErrorDialog(
                  "לא קיימים ספורטאים בחשבון שלך",
                  "יש להוסיף שחקנים לחשבון על מנת לבצע רכישה בחנות",
                  { permanent: true }
                )
              : this.showErrorDialog(
                  "לא קיימים ספורטאים רשומים במועדון",
                  "יש להירשם לפעילות על מנת לבצע רכישה",
                  { permanent: true }
                );
          }
        },
        () => {
          loadError = true;
        }
      )
    );

    switch (this.state.mode) {
      case "subscription":
        promises.push(
          axios
            .get(
              `/clubs/${clubInternalName}/store/products?category=subscription&granularity=subProducts&active=true&expired=false`
            )
            .then((res) => {
              data.seasonProducts = res.data;
            })
        );
        break;

      case "payment":
        promises.push(
          axios
            .get(
              `/store/subscriptions/${this.state.subscriptionId}?granularity=team&granularity=player&granularity=product`
            )
            .then((res) => {
              data.subscription = res.data;

              data.cart = Cart.handleSubscriptionPaymentChange(
                this.state.cart,
                data.subscription,
                data.subscription.debt
              );
            })
        );
        break;

      default:
        break;
    }

    await Promise.all(promises);
    if (data.player) {
      data.player.clubPlayer = data.player.clubPlayers.find(
        (cp) => cp.club === data.club._id
      );
    }

    if (loadError) {
      this.setState({ loadError: true });
      return;
    }

    await this.parentJoinClub(data.club, true);
    let levels;
    if (this.state.mode === "subscription") {
      await this.checkPlayerJoinClub(data.club._id, this.state.playerId);
      levels = this.prepareLevels(data.teams, data.seasonProducts);
    }

    this.setState((prevStat) => {
      const forms = { ...prevStat.forms };
      if (this.props.clubConfig.paymentMethods === "other") {
        forms.payment.paymentMethod = "other";
      }

      if (this.props.clubConfig.paymentMethods === "none") {
        forms.payment.paymentMethod = "";
      }

      if (data.player) {
        forms.player = {
          ...prevStat.forms.player,
          class: data.player.class,
          phone: data.player.phone || "",
          school: data.player.school || "",
        };
      }

      return {
        loading: false,
        steps: this.steps(this.state.mode),
        ...data,
        forms,
        levels,
      };
    });
  };

  handleFieldChange = async (form, updates) => {
    this.setState({
      forms: {
        ...this.state.forms,
        [form]: { ...this.state.forms[form], ...updates },
      },
    });
  };

  maxPaymentNumber = () => {
    const absMax =
      clubsConfig.clubs[this.props.match.params.clubId].payments
        .maxNumberOfPayments || 12;

    return Math.min(this.maxPaymentNumberRaw(), absMax);
  };

  maxPaymentNumberRaw = () => {
    const seasonProduct = (this.state.cart.seasonProduct || {}).seasonProduct;
    if (
      this.state.mode === "store" ||
      (seasonProduct || {})._id === "external" // with merchendise
    ) {
      return 1;
    }

    const subscriptionType =
      seasonProduct?.subscriptionDetails?.subscriptionType;
    if (subscriptionType === "fixedPrice" || subscriptionType === "daily") {
      return seasonProduct.subscriptionDetails.numberOfPayments;
    }

    if (this.state.mode === "payment") {
      if (
        ["fixedPrice", "daily", "camp"].indexOf(
          this.state.cart.subscriptionPayments[0].subscription.product
            .subscriptionDetails.subscriptionType
        ) > -1
      ) {
        return this.state.cart.subscriptionPayments[0].subscription.product
          .subscriptionDetails.numberOfPayments;
      }

      return this.state.cart.subscriptionPayments[0].subscription.product
        .subscriptionDetails.maxNumberOfPayments;
    }

    return seasonProduct.subscriptionDetails.subscriptionType === "camp"
      ? seasonProduct.subscriptionDetails.numberOfPayments
      : seasonProduct.subscriptionDetails.maxNumberOfPayments;
  };

  setPaymentMethod = () => {
    let paymentMethod = "";

    if (Object.values(this.state.cart.merchandise).length > 0) {
      paymentMethod = "creditCard";
    } else {
      const seasonProduct =
        (this.state.cart.seasonProduct || {}).seasonProduct || {};

      if (seasonProduct._id === "external") {
        paymentMethod = "";
      } else if (seasonProduct._id) {
        switch (this.props.clubConfig.paymentMethods) {
          case "other":
            paymentMethod = "other";
            break;

          case "cc":
          case "all":
            paymentMethod = "creditCard";
            break;

          default:
            throw new Error("shouldn't get here"); //pm can't be none for clubs with direct subs
        }
      }
    }

    this.handleFieldChange("payment", { paymentMethod });
  };

  getStepContent = (step) => {
    const stepId = this.state.steps[step].id;
    const seasonProduct = (this.state.cart.seasonProduct || {}).seasonProduct;
    const hasMerchendise =
      Object.values(this.state.cart.merchandise).length > 0;
    const noPayment =
      (seasonProduct || {})._id === "external" && !hasMerchendise;

    switch (stepId) {
      case "subscription":
        return (
          <SubscriptionForm
            user={this.state.user}
            state={this.state.forms.subscription}
            cart={this.state.cart}
            seasonProducts={this.state.seasonProducts}
            teams={this.state.teams}
            levels={this.state.levels}
            clubPlayer={this.state.player?.clubPlayer}
            onChange={(updates) => {
              this.handleFieldChange("subscription", updates);
            }}
            onSetSeasonProduct={(seasonProduct, pricingID) => {
              this.setState(
                {
                  cart: Cart.setSeasonProduct(
                    this.state.cart,
                    seasonProduct,
                    pricingID
                  ),
                },
                this.setPaymentMethod
              );
            }}
            onRemoveSeasonProduct={() =>
              this.setState({ cart: Cart.removeSeasonProduct(this.state.cart) })
            }
          />
        );
      case "merchandise":
        return (
          <>
            <Hidden smDown>
              <ShoppingCartIndicator
                cartItems={this.state.cart.totalMerchandiseItems}
                disabled={this.state.cart.totalMerchandiseItems === 0}
                onClick={this.handleNext}
              />
            </Hidden>
            <MerchandiseForm
              state={this.state.forms.merchandise}
              mode={this.state.mode}
              merchandise={this.state.merchandise}
              cart={this.state.cart}
              players={this.state.players}
              onProductChange={(...args) => {
                this.setState(
                  (prevState) => ({
                    cart: Cart.handleRegularProductCartChange(
                      prevState.cart,
                      ...args
                    ),
                  }),
                  this.setPaymentMethod
                );
              }}
            />
          </>
        );

      case "updatePlayerDetails":
        return (
          <UpdatePlayerDetailsForm
            state={this.state.forms.player}
            onChange={(updates) => this.handleFieldChange("player", updates)}
          />
        );

      case "review":
        return (
          <Review
            player={this.state.player}
            cart={this.state.cart}
            teams={this.state.teams}
            forms={this.state.forms}
            onMerchandiseChange={(...args) => {
              this.setState(
                (prevState) => ({
                  cart: Cart.handleRegularProductCartChange(
                    prevState.cart,
                    ...args
                  ),
                }),
                this.setPaymentMethod
              );
            }}
          />
        );

      case "payment":
        return (
          <PaymentForm
            state={this.state.forms.payment}
            onChange={(updates) => this.handleFieldChange("payment", updates)}
            cart={this.state.cart}
            isSending={this.state.isSending}
            clubConfig={clubsConfig.clubs[this.props.match.params.clubId]}
            maxPaymentsNumber={this.maxPaymentNumber()}
            showPayments={this.state.mode !== "store"}
            terms={this.state.terms}
            mode={this.state.mode}
            paymentMethods={this.props.clubConfig.paymentMethods}
            cashCheckDisabled={hasMerchendise}
            noPayment={noPayment}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  };

  preparePaymentFieldsForSubmit = () => {
    const params = {
      paymentMethod: this.state.forms.payment.paymentMethod,
      amount: this.state.cart.total,
    };

    switch (this.state.forms.payment.paymentMethod) {
      case "creditCard":
        params.last4 = this.state.forms.payment.last4;
        params.payments = this.state.forms.payment.numberOfPayments;
        params.token = this.state.forms.payment.token;
        params.expiration = this.state.forms.payment.expiration;
        params.ccResponse = this.state.forms.payment.ccResponse;
        break;

      default:
        break;
    }

    return params;
  };

  prepareSubscriptionForSubmit = (clubPlayerId) => {
    if (this.state.mode !== "subscription") {
      return undefined;
    }

    const sp = this.state.cart.seasonProduct;
    const subscription = this.state.forms.subscription;
    if (sp.seasonProduct._id === "external") {
      return {
        type: "indirect",
        teamId: subscription.teamId,
        clubPlayerId,
        price: 0,
      };
    } else {
      return {
        type: "direct",
        productId: sp.seasonProduct._id,
        teamId: subscription.teamId,
        price: sp.price,
        clubPlayerId,
        pricingId: subscription.pricingId,
        discountReason:
          this.state.forms.payment.paymentMethod === "discountRequest"
            ? this.state.forms.payment.discountReason
            : undefined,
        subProducts: (sp.seasonProduct.subProducts || []).map((subProduct) => {
          return {
            productId: subProduct._id,
            price: subProduct.price,
            quantity: 1,
          };
        }),
      };
    }
  };

  prepareMerchandiseForSubmit = (mainClubPlayerId) => {
    const items = Object.values(this.state.cart.merchandise);
    const prodcutsPurchase = [];
    items.forEach((item) => {
      const clubPlayer = item.player.clubPlayers.find(
        (cp) => cp.club === this.props.club._id
      );

      let clubPlayerId;
      let guestPlayer;
      if (clubPlayer) {
        clubPlayerId = clubPlayer._id;
      }
      // the player has just join the club
      else if (item.player._id === this.state.playerId) {
        clubPlayerId = mainClubPlayerId;
      } else if (this.props.clubConfig.general.guestMerchandise) {
        guestPlayer = {
          name: item.player.name,
          phone: item.player.phone,
          parentPhone: this.props.parent.phone,
          parentName: this.props.parent.name, // might be empty
        };
      } else {
        throw new Error("club player id is missing");
      }

      prodcutsPurchase.push({
        productId: item.product._id,
        clubPlayerId,
        guestPlayer,
        price: item.price,
        quantity: item.quantity,
        optionId: item.optionId || undefined, // don't send empty string
      });
    });

    return prodcutsPurchase;
  };

  prepareProductsForSubmit = (clubPlayerId) => {
    return {
      subscription: this.prepareSubscriptionForSubmit(clubPlayerId),
      merchandise: this.prepareMerchandiseForSubmit(clubPlayerId),
      subscriptionPayments: this.state.cart.subscriptionPayments.map((item) => {
        return {
          subscriptionId: item.subscription._id,
          price: item.price,
        };
      }),
    };
  };

  showErrorDialog = (title, text, options = {}) => {
    this.setState({
      isErrorDialogOpen: true,
      error: {
        title,
        text,
        backToStep: options.backToStep || null,
        permanent: Object.prototype.hasOwnProperty.call(options, "permanent")
          ? options.permanent
          : false,
      },
    });
  };

  parentJoinClub = async (club, check = false) => {
    try {
      const res = await axios.post(`/parents/${this.props.parentId}/joinClub`, {
        clubId: club._id,
        parentId: this.props.parentId,
        check,
      });
      if (check) {
        return true;
      }
      const clubParentId = res.data.clubParentId;
      this.setState({ clubParentId });
      return clubParentId;
    } catch (error) {
      const errorData = error.response.data;
      this.setState({ isSending: false });
      if (errorData.errors) {
        if (
          errorData.errors.id === "unique" ||
          errorData.errors.phone === "unique"
        ) {
          this.showErrorDialog(
            "לא ניתן להשלים את הרישום למועדון",
            `קיים לקוח במועדון עם מספר  ${
              errorData.errors.phone === "unique" ? "הטלפון" : "תעודת הזהות"
            } שלכם אך שאר הפרטים אינם תואמים. מאחר ופרטיותכם חשובה לנו לא נוכל להשלים את הרישום. אנא פנו למועדון להשלמת הרישום`,
            { permanent: true }
          );
          return false;
        }
      }

      this.showErrorDialog("אירעה שגיאה", "אנא נסו מאוחר יותר");
      return false;
    }
  };

  checkPlayerJoinClub = async (clubId, playerId) => {
    try {
      const res = await axios.post(`/players/${playerId}/checkJoinClub`, {
        clubId,
      });

      let errorTitle, errorContent;

      switch (res.data.status) {
        case "valid":
          return;

        case "noId":
          errorTitle = "לא ניתן להשלים את הרישום למועדון";
          errorContent =
            "פרטי הספורטאי/ת אינם עדכניים. אנא כנסו לפרופיל הספורטאי/ת ועדכנו את פרטיו";
          break;

        case "diffParent":
          errorTitle = "לא ניתן להשלים את הרישום למועדון";
          errorContent = `הספורטאי/ת קיימים במועדון אך הם משויכים ללקוח אחר. להשלמת הרישום יש לפנות למועדון`;
          break;

        default:
          this.showErrorDialog("אירעה שגיאה", "אנא נסו מאוחר יותר");
          return;
      }

      this.showErrorDialog(errorTitle, errorContent, { permanent: true });
    } catch (error) {
      this.showErrorDialog("אירעה שגיאה", "אנא נסו מאוחר יותר", {
        permanent: true,
      });
    }
  };

  playerJoinClub = async (club, playerId, clubParentId) => {
    try {
      const res = await axios.post(`/players/${playerId}/joinClub`, {
        clubId: club._id,
        clubParentId,
      });

      const clubPlayerId = res.data.clubPlayerId;
      this.setState({ clubPlayerId });
      return clubPlayerId;
    } catch (error) {
      const errorData = error.response.data;
      this.setState({ isSending: false });
      if (errorData.errors) {
        if (errorData.errors.id === "unique") {
          this.showErrorDialog(
            "לא ניתן להשלים את הרישום למועדון",
            "הספורטאי/ת קיימים במועדון אך הם משויכים ללקוח אחר. להשלמת הרישום יש לפנות למועדון"
          );
          return false;
        }
      }

      this.showErrorDialog("אירעה שגיאה", "אנא נסו מאוחר יותר");
      return false;
    }
  };

  updatePlayerDetails = async () => {
    if (
      this.state.forms.player.phone !== this.state.player.phone ||
      this.state.forms.player.school !== this.state.player.school ||
      this.state.forms.player.class !== this.state.player.class
    ) {
      try {
        await axios.patch(`/players/${this.state.playerId}`, {
          phone: this.state.forms.player.phone,
          school: this.state.forms.player.school,
          class: this.state.forms.player.class,
        });
      } catch (error) {
        this.showErrorDialog("אירעה שגיאה", "אנא נסו מאוחר יותר");
        return false;
      }
    }

    return true;
  };

  handleSubmit = async () => {
    this.setState({ isSending: true });

    let clubParentId = this.state.clubParentId;
    if (!clubParentId) {
      clubParentId = await this.parentJoinClub(this.state.club, false);
      if (!clubParentId) return;
    }

    let clubPlayerId = this.state.clubPlayerId;
    if (this.state.mode === "subscription") {
      if (!(await this.updatePlayerDetails())) {
        this.setState({ isSending: false });
        return;
      }

      //clubPlayerId is only required for subscriptions
      if (!clubPlayerId) {
        clubPlayerId = await this.playerJoinClub(
          this.state.club,
          this.state.playerId,
          clubParentId,
          false
        );
        if (!clubPlayerId) return;
      }
    }

    const data = {
      clubParentId,
      clubId: this.state.club._id,
      ...this.prepareProductsForSubmit(clubPlayerId),
      payment: this.preparePaymentFieldsForSubmit(),
    };

    try {
      if (this.state.forms.payment.paymentMethod === "creditCard") {
        const response = await axios.post(`/store/purchases`, data);
        this.setState({
          isSending: false,
          transactionId: response.data.transactionId,
          receiptEmail: response.data.receiptEmail,
          paid: true,
        });
      } else {
        await axios.post(`/store/subscriptions`, data);
        this.setState({
          paid: false,
        });
      }

      this.props.loadParent(); // update parent's clubs

      return true;
    } catch (error) {
      if (error.response?.status === 422) {
        const { errors } = error.response.data;
        if (errors && errors.payment) {
          this.showErrorDialog("העסקה נכשלה", "אנא נסו כרטיס אשראי אחר", {
            backToStep: "payment",
          });
        } else if (errors && errors.registration === "closed") {
          this.showErrorDialog(
            "ההרשמה לקבוצה זו נסגרה",
            "אנא פנו להנהלת המועדון"
          );
        } else {
          this.showErrorDialog(
            "לא ניתן להשלים עסקה זו כרגע",
            "אנא נסו מאוחר יותר"
          );
        }
      } else {
        this.showErrorDialog("אירעה שגיאה", "אנא נסו מאוחר יותר");
      }

      this.setState({ isSending: false });
      return false;
    }
  };

  validate = async (step) => {
    let errors = {};
    const stepId = this.state.steps[step].id;

    switch (stepId) {
      case "subscription":
        errors = await formValidator.validateSubscription(
          this.state.forms.subscription
        );

        this.setState((prevState) => {
          return {
            forms: {
              ...prevState.forms,
              subscription: { ...prevState.forms.subscription, errors },
            },
          };
        });

        break;

      case "merchandise":
        if (this.state.mode === "store") {
          if (Object.keys(this.state.cart.merchandise).length === 0) {
            errors.merchandise = "required";
          }

          this.setState((prevState) => {
            return {
              forms: {
                ...prevState.forms,
                merchandise: { ...prevState.forms.merchandise, errors },
              },
            };
          });
        }
        break;

      case "updatePlayerDetails":
        errors = await formValidator.validatePlayerUpdateDetails(
          this.state.forms.player
        );

        this.setState((prevState) => {
          return {
            forms: {
              ...prevState.forms,
              player: { ...prevState.forms.player, errors },
            },
          };
        });
        break;

      case "review":
        break;

      case "payment":
        if (this.state.forms.payment.paymentMethod === "creditCard") {
          if (!this.state.forms.payment.token) {
            errors.cc = "שדה חובה";
          }

          if (
            !this.state.forms.payment.ccResponse ||
            this.state.forms.payment.ccResponse.Response !== "000"
          ) {
            errors.cc = "שדה חובה";
          }

          if (!this.state.forms.payment.numberOfPayments) {
            errors.numberOfPayments = "שדה חובה";
          }
        }

        if (this.state.forms.payment.paymentMethod === "discountRequest") {
          if (this.state.forms.payment.discountReason.trim().length < 2) {
            errors.discountReason = "שדה חובה";
          }
        }

        if (!this.state.forms.payment.termsAgreed) {
          errors.termsAgreed = "שדה חובה";
        }

        this.setState((prevState) => {
          return {
            forms: {
              ...prevState.forms,
              payment: { ...prevState.forms.payment, errors },
            },
          };
        });
        break;

      default:
        break;
    }

    return Object.keys(errors).length === 0;
  };

  handleNext = async () => {
    const isValid = await this.validate(this.state.activeStep);
    if (isValid) {
      let success = true;
      if (this.state.activeStep === this.state.steps.length - 1) {
        success = await this.handleSubmit();
      }

      if (success) {
        this.setState((prevState) => {
          return { activeStep: prevState.activeStep + 1 };
        });
      }
    }
  };

  handleBack = () => {
    this.setState((prevState) => {
      return { activeStep: prevState.activeStep - 1 };
    });
  };

  renderTitle = () => {
    return {
      store: "חנות מוצרים",
      subscription: "רישום",
      payment: "תשלום",
    }[this.state.mode];
  };

  nextButtonText = () => {
    if (this.state.activeStep < this.state.steps.length - 1) {
      return "הבא";
    }

    return this.state.cart.total > 0 &&
      this.state.forms.payment.paymentMethod === "creditCard"
      ? "תשלום"
      : "סיום";
  };

  currentStepId = () =>
    this.state.steps ? this.state.steps[this.state.activeStep]?.id : null;

  render() {
    const { classes } = this.props;

    if (!this.state.supported) {
      return <div>לא ניתן להשלים פעולה זו</div>;
    }
    if (this.state.loadError) {
      return <div>העמוד שחיפתשם לא נמצא</div>;
    }

    return (
      <Paper
        className={classes.paper}
        bodyClassName={clsx(classes.paperBody, {
          [classes.paperBodyNoPadding]: this.currentStepId() === "review",
        })}
        title={this.renderTitle()}
        bigTitle
        titleBackground
        titleClassName={classes.paperTitle}
        titleDividerClassName={classes.paperTitleDivider}
      >
        {this.state.loading ? (
          <div className={classes.progressContainer}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <ErrorDialog
              open={this.state.isErrorDialogOpen}
              error={this.state.error}
              permanent={this.state.error.permanent}
              clubInternalName={this.props.match.params.clubId}
              onClose={() => {
                const activeStep = this.state.error.backToStep
                  ? _.findIndex(
                      this.state.steps,
                      (s) => s.id === this.state.error.backToStep
                    )
                  : this.state.activeStep;

                this.setState({
                  isErrorDialogOpen: false,
                  activeStep,
                });
              }}
            />

            <Stepper
              activeStep={this.state.activeStep}
              className={classes.stepper}
              alternativeLabel
            >
              {this.state.steps.map((step) => (
                <Step key={step.id}>
                  <StepLabel>{step.label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <>
              {this.state.activeStep === this.state.steps.length ? (
                <Confirmation
                  mode={this.state.mode}
                  discountRequest={
                    this.state.forms.payment.paymentMethod === "discountRequest"
                  }
                  transactionId={this.state.transactionId}
                  email={this.state.receiptEmail}
                  paymentMethod={this.state.forms.payment.paymentMethod}
                  paid={this.state.paid}
                  subscriptionType={
                    this.state.cart.seasonProduct
                      ? this.state.cart.seasonProduct.seasonProduct._id ===
                        "external"
                        ? "external"
                        : "internal"
                      : null
                  }
                  merchandise={
                    Object.values(this.state.cart.merchandise).length > 0
                  }
                />
              ) : (
                <>
                  {this.getStepContent(this.state.activeStep)}
                  <div className={classes.buttons}>
                    {this.state.activeStep !== 0 && (
                      <Button
                        onClick={this.handleBack}
                        className={classes.button}
                        disabled={this.state.isSending}
                      >
                        חזור
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleNext}
                      disabled={
                        this.state.isSending ||
                        (this.state.mode === "subscription" &&
                          this.state.levels.length === 0) ||
                        ((this.state.merchandise || []).length === 0 &&
                          this.state.mode === "store") ||
                        (this.currentStepId() === "review" && // empty cart (can happen if items are deleted from the review step)
                          this.state.cart.total === 0 &&
                          !this.state.cart.seasonProduct) // cart's total might be 0 when signing up for a external team and no merch
                      }
                      className={classes.button}
                      debounce={
                        this.state.activeStep === this.state.steps.length - 1
                      }
                    >
                      {this.state.isSending && (
                        <CircularProgress
                          color="secondary"
                          size={20}
                          style={{ marginLeft: 5 }}
                        />
                      )}
                      {this.nextButtonText()}
                    </Button>
                  </div>
                </>
              )}
            </>
          </>
        )}
      </Paper>
    );
  }
}

const styles = (theme) => ({
  paper: {
    width: "100%",
    [theme.breakpoints.up(700 + theme.spacing(2) * 2)]: {
      width: 700,
    },
  },
  paperBody: {
    padding: theme.spacing(2),
    [theme.breakpoints.up(700 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(3),
    },
  },
  paperBodyNoPadding: {
    [theme.breakpoints.down(700 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(0),
    },
  },
  paperTitle: {
    [theme.breakpoints.down(700 + theme.spacing(3) * 2)]: {
      display: "none",
    },
  },
  paperTitleDivider: {
    [theme.breakpoints.down(700 + theme.spacing(3) * 2)]: {
      display: "none",
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: 10,
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  progressContainer: {
    height: "400px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const mapStateToProps = (state) => {
  return {
    parentId: state.auth.parentId,
    parent: state.auth.parent,
    clubConfig: state.general.clubConfig,
    club: state.general.club,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    loadParent: () => dispatch(Actions.auth.loadParent()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Purchase));
