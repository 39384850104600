import React from "react";
import Paper from "../components/Paper";
import { Container } from "@material-ui/core";
import { connect } from "react-redux";

import axios from "../lib/axios";

function About(props) {
  const [about, setAbout] = React.useState(null);

  React.useEffect(async () => {
    const res = await axios.get(
      `/clubs/${props.match.params.clubId}/info?type=about`
    );
    setAbout(res.data);
  }, [props.match.params.clubId]);

  return (
    <Container maxWidth="md">
      <Paper title="אודות" titleBackground bigTitle>
        <div
          dangerouslySetInnerHTML={{
            __html: about,
          }}
        />
      </Paper>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoadingClub: state.general.isLoadingClub,
    clubConfig: state.general.clubConfig,
  };
};

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(About);
