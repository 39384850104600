import React from "react";
import Typography from "@material-ui/core/Typography";
import {
  Grid,
  Checkbox,
  FormHelperText,
  Link,
  makeStyles,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { connect } from "react-redux";

import CreditCardForm from "./CreditCardForm";
import { displayPrice } from "../../lib/helpers";
import TermsDialog from "./TermsDialog";

function PaymentForm(props) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const renderChoosePaymentMethod = () => (
    <Grid item xs={12}>
      <FormControl component="fieldset" disabled={props.disabled}>
        <FormLabel component="legend">אמצעי תשלום</FormLabel>
        <RadioGroup
          row
          aria-label="gender"
          value={props.state.paymentMethod}
          onChange={(e) => props.onChange({ paymentMethod: e.target.value })}
        >
          {(props.paymentMethods === "all" ||
            props.paymentMethods === "cc") && (
            <FormControlLabel
              value="creditCard"
              control={<Radio />}
              label="כרטיס אשראי"
            />
          )}
          {(props.paymentMethods === "all" ||
            props.paymentMethods === "other") && (
            <FormControlLabel
              value="other"
              control={<Radio />}
              label="מזומן/צ׳ק"
              disabled={props.cashCheckDisabled}
            />
          )}

          {props.newClubConfig.general.discountRequests && (
            <FormControlLabel
              value="discountRequest"
              control={<Radio />}
              label="בקשה מיוחדת"
              disabled={props.cashCheckDisabled}
            />
          )}
        </RadioGroup>
      </FormControl>
    </Grid>
  );

  const showRadios =
    props.mode === "subscription" &&
    (props.paymentMethods === "all" ||
      props.paymentMethods === "other" ||
      props.newClubConfig.general.discountRequests);

  const showCCOnlyAlert = showRadios && props.cashCheckDisabled;

  const renderNoPayment = () => {
    return (
      <Grid item xs={12}>
        <Alert severity="info">התשלום לקבוצה שנבחרה לא מתבצע דרך המועדון</Alert>
      </Grid>
    );
  };

  const renderPayment = () => (
    <React.Fragment>
      <Grid item xs={12}>
        <Typography style={{ fontWeight: "bold" }}>
          סה״כ לתשלום: {displayPrice(props.cart.total)}
        </Typography>
      </Grid>
      {showCCOnlyAlert && (
        <Grid item xs={12}>
          <Alert severity="info">
            רכישת מוצרים נילווים ניתנת בכרטיס אשראי בלבד
          </Alert>
        </Grid>
      )}

      {showRadios && renderChoosePaymentMethod()}
      <Grid item xs={12}>
        <div style={{ minHeight: 120 }}>
          {props.state.paymentMethod === "creditCard" && (
            <CreditCardForm
              state={props.state}
              onChange={props.onChange}
              products={props.products}
              cart={props.cart}
              clubConfig={props.clubConfig}
              showPayments={props.showPayments}
              maxPaymentsNumber={props.maxPaymentsNumber}
            />
          )}
          {props.state.paymentMethod === "other" && (
            <div style={{ padding: 20, paddingRight: 10 }}>
              <Typography style={{ fontWeight: "bold" }}>
                לאחר השלמת הרישום עליכם ליצור קשר עם המועדון לצורך ביצוע התשלום.
              </Typography>
            </div>
          )}

          {props.state.paymentMethod === "discountRequest" && (
            <TextField
              label="פרטי הבקשה"
              placeholder="אנא פרטו כאן את בקשתכם"
              fullWidth
              onChange={(e) =>
                props.onChange({ discountReason: e.target.value })
              }
              value={props.state.discountReason}
              error={props.state.errors.discountReason !== undefined}
              helperText={props.state.errors.discountReason}
            />
          )}
        </div>
      </Grid>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <div className={classes.container}>
        <Grid container spacing={2}>
          {props.noPayment ? renderNoPayment() : renderPayment()}
          <Grid item xs={12}>
            <Checkbox
              checked={props.state.termsAgreed}
              onChange={(event) => {
                props.onChange({
                  termsAgreed: event.target.checked,
                });
              }}
            />
            <Link
              href="#"
              onClick={() => setOpen(true)}
              className={classes.termsLink}
            >
              קראתי והסכמתי לתנאי השימוש
            </Link>
            <FormHelperText error>
              {props.state.errors.termsAgreed ? "יש לאשר את תנאי השימוש" : ""}
            </FormHelperText>
          </Grid>
        </Grid>
      </div>
      <TermsDialog
        terms={props.terms}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    border: "1px solid #EEE",
    borderRadius: 5,
    padding: 20,
  },
  termsLink: {
    color: theme.palette.text.primary,
  },
}));

const mapStateToProps = (state) => {
  return {
    newClubConfig: state.general.clubConfig,
  };
};

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentForm);
