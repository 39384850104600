import React, { useState } from "react";
import {
  Avatar,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/AlternateEmail";
import HomeIcon from "@material-ui/icons/Home";
import { connect } from "react-redux";

import Paper from "../components/Paper";
import axios from "../lib/axios";

const icons = {
  phone: PhoneIcon,
  email: EmailIcon,
  address: HomeIcon,
};

function Contact(props) {
  const [contacts, setContacts] = useState([{}]);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();

  React.useEffect(async () => {
    setIsLoading(true);
    const res = await axios.get(
      `/clubs/${props.match.params.clubId}/info?type=contacts`
    );
    setContacts(res.data || []);
    setIsLoading(false);
  }, [props.match.params.clubId]);

  const renderRow = (contact, field) => {
    const Icon = icons[field];
    if (isLoading) {
      return (
        <Grid item xs={12}>
          <Typography>
            <Skeleton height={30} />
          </Typography>
        </Grid>
      );
    }

    if (!contact[field]) {
      return null;
    }

    return (
      <Grid item xs={12}>
        <Typography>
          <div className={classes.infoRow}>
            <Icon style={{ marginLeft: 10 }} /> {contact[field]}
          </div>
        </Typography>
      </Grid>
    );
  };

  const renderContact = (contact, index) => {
    return (
      <Grid item xs={12} key={index}>
        <div className={classes.contactContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <div className={classes.contactRightSide}>
                {isLoading ? (
                  <Skeleton variant="circle">
                    <Avatar />
                  </Skeleton>
                ) : (
                  <Avatar />
                )}
                <Typography style={{ textAlign: "center" }}>
                  {isLoading ? <Skeleton width={150} /> : contact.name}
                </Typography>
              </div>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={9}
              alignItems="center"
              spacing={2}
              style={{ padding: 20 }}
            >
              {renderRow(contact, "phone")}
              {renderRow(contact, "email")}
              {renderRow(contact, "address")}
            </Grid>
          </Grid>
        </div>
      </Grid>
    );
  };

  return (
    <Container maxWidth="md">
      <Paper title="צור קשר" titleBackground bigTitle>
        <Grid container spacing={3}>
          {contacts.map((c, i) => renderContact(c, i))}
        </Grid>
      </Paper>
    </Container>
  );
}

const useStyles = makeStyles(() => ({
  contactContainer: {
    border: "1px solid #e9ecef",
    borderRadius: 5,
  },
  contactRightSide: {
    borderRight: "1px solid #e9ecef",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: 20,
  },
  infoRow: {
    display: "flex",
    alignItems: "center",
  },
}));

const mapStateToProps = (state) => {
  return {
    isLoadingClub: state.general.isLoadingClub,
    clubConfig: state.general.clubConfig,
  };
};

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
