import React from "react";
import Grid from "@material-ui/core/Grid";
import {
  TextField,
  makeStyles,
  Checkbox,
  FormHelperText,
} from "@material-ui/core";
import { Link } from "react-router-dom";

import PasswordTextField from "../../components/PasswordTextField";
import TermsAndPrivacyDialog from "./TermsAndPrivacyDialog";

export default function ParentForm(props) {
  const classes = useStyles();
  const [termsOpen, setTermsOpen] = React.useState(false);

  const defaultParams = (field) => ({
    fullWidth: true,
    value: props.state[field],
    onChange: (event) => props.onChange({ [field]: event.target.value }),
    error: props.errors[field] !== undefined,
    helperText: props.errors[field],
  });

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        {!props.editMode && (
          <Grid item xs={12} sm={6}>
            <TextField
              {...defaultParams("phone")}
              helperText={
                props.errors["phone"] === undefined
                  ? "שימו לב, מספר הטלפון ישמש אתכם לכניסה לחשבון"
                  : props.errors["phone"]
              }
              required
              label="טלפון"
            />
          </Grid>
        )}
        {(!props.editMode || props.editId) && (
          <Grid item xs={12} sm={6}>
            <TextField {...defaultParams("id")} required label="תעודת זהות" />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <TextField {...defaultParams("firstName")} required label="שם פרטי" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField {...defaultParams("lastName")} required label="שם משפחה" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField {...defaultParams("email")} required label="אי-מייל" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField {...defaultParams("street")} required label="רחוב" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...defaultParams("houseNumber")}
            required
            label="מספר בית"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField {...defaultParams("city")} required label="עיר" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...defaultParams("secondParentName")}
            label="שם הורה ב׳"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...defaultParams("secondParentPhone")}
            label="טלפון הורה ב׳"
          />
        </Grid>
        {props.editMode !== true && (
          <React.Fragment>
            <Grid item xs={12} sm={6}>
              <PasswordTextField
                {...defaultParams("password")}
                required
                label="סיסמה"
                error={props.errors.password !== undefined}
                helperText={`${
                  props.errors.password ? props.errors.password + ". " : ""
                }על הסיסמה להיות באורך של לפחות 8 תווים ולהכיל אות גדולה, אות קטנה ומספר`}
              />
            </Grid>

            <Grid item xs={12}>
              <Checkbox
                checked={props.state.termsAgreed}
                onChange={(event) => {
                  props.onChange({
                    termsAgreed: event.target.checked,
                  });
                }}
                className={classes.checkbox}
              />
              <Link
                to="#"
                onClick={() => setTermsOpen(true)}
                className={classes.termsLink}
              >
                קראתי והסכמתי לתנאי השימוש והפרטיות
              </Link>
              <FormHelperText error>
                {props.errors.termsAgreed
                  ? "יש לאשר את תנאי השימוש והפרטיות"
                  : ""}
              </FormHelperText>
            </Grid>
            <TermsAndPrivacyDialog
              open={termsOpen}
              onClose={() => setTermsOpen(false)}
            />
          </React.Fragment>
        )}
        <Grid item xs={12}>
          * שדה חובה
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  termsLink: {
    color: theme.palette.text.primary,
  },
  checkbox: {
    paddingLeft: 0,
  },
}));
