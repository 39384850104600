import axios from "../../lib/axios";

export const setClub = (club, clubConfig) => {
  return {
    type: "SET_CLUB",
    club,
    clubConfig,
  };
};

export const setIsLoadingClub = (isLoadingClub) => {
  return {
    type: "SET_LOADING_CLUB",
    isLoadingClub,
  };
};

export const loadClub = (clubInternalName) => {
  return async (dispatch) => {
    dispatch(setIsLoadingClub(true));

    const res = await axios.get(
      `/clubs/${clubInternalName}?idType=internalName&granularity=parentsConfig`
    );
    dispatch(setClub(res.data, res.data.clubConfig));
    dispatch(setIsLoadingClub(false));
  };
};

export const unloadClub = () => {
  return {
    type: "UNLOAD_CLUB",
  };
};

export const setIsMobile = (isMobile) => {
  return {
    type: "SET_IS_MOBILE",
    isMobile,
  };
};
