import { Box, Button, styled } from "@material-ui/core";
import React from "react";
import AddPersonIcon from "@material-ui/icons/PersonAdd";
import { Link as RouterLink } from "react-router-dom";

import { clubUrl } from "../../lib/helpers";
import Paper from "../../components/Paper";

// ol can't be under p
const P = styled(Box)({
  paddingBottom: 10,
});

export default function NoData(props) {
  return (
    <Paper title="ברוכים הבאים לטריינליט!" bigTitle titleBackground>
      <P>
        כאן תוכלו למצוא את כל המידע שאתם צריכים בנוגע לפעילות הספורטאים במועדון
        - רישום ותשלום, חנות מוצרים, היסטוריית תשלומים, פרטי המאמנים ועוד.
      </P>
      <P>
        האיזור האישי שלכם מאפשר לכם להתנהל מול המועדון באופן עצמאי וללא תלות
        במנהלים לקבלת מידע וביצוע פעולות.
        <div>
          בצד ימין תוכלו למצוא את תקנון הרישום והתשלום של המועדון ופרטים ליצירת
          קשר עם המועדון.
        </div>
      </P>
      <P>
        <div style={{ fontWeight: "bold", fontSize: 16 }}>
          איך נרשמים לפעילות?
        </div>
        <ol>
          <li>
            לחצו על &quot;הוספת ספורטאי/ת&quot;, ולאחר מכן מלאו את פרטי
            הספורטאי/ת אותם אתם מעוניינים לרשום.
          </li>
          <li>
            לחצו על &quot;רישום לפעילות&quot;, בחרו את המסגרת המתאימה ומלאו את
            פרטי האשראי.
          </li>
          <li>עם סיום הרישום לפעילות תקבלו אישור מיידי.</li>
        </ol>
        <P>מאחלים לכם שנת פעילות מוצלחת ופוריה!</P>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: 20 }}
          startIcon={<AddPersonIcon style={{ marginLeft: 10 }} />}
          component={RouterLink}
          to={clubUrl(props.clubId, "players/new")}
        >
          הוספת ספורטאי/ת
        </Button>
      </P>
    </Paper>
  );
}
