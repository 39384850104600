import _ from "lodash";
import { roundPrice } from "../../../lib/helpers";

export const init = () => ({
  seasonProduct: null,
  merchandise: {},
  subscriptionPayments: [],
  seasonProductTotal: 0,
  merchandiseTotal: 0,
  total: 0,
  totalMerchandiseItems: 0,
});

const cloneCart = (cart) => ({
  ...cart,
  merchandise: { ...cart.merchandise },
  seasonProduct: cart.seasonProduct // might be null
    ? { ...cart.seasonProduct }
    : cart.seasonProduct,
  subscriptionPayments: [...cart.subscriptionPayments],
});

export const handleRegularProductCartChange = (
  prevCart,
  product,
  quantityDiff,
  player,
  optionId
) => {
  const cart = cloneCart(prevCart);
  const recordKey = `${product._id}||${player._id}||${optionId}`;

  let record = cart.merchandise[recordKey];

  if (!record) {
    record = {
      quantity: 0,
      product,
      player,
      optionId,
      optionTitle: product.options.find((o) => o._id === optionId)?.title || "",
      price: product.price,
    };
    cart.merchandise[recordKey] = record;
  }

  record.quantity += quantityDiff;
  cart.totalMerchandiseItems += quantityDiff;
  if (record.quantity === 0) {
    delete cart.merchandise[recordKey];
  }

  calcTotals(cart);

  return cart;
};

export const removeSeasonProduct = (prevCart) => {
  const cart = cloneCart(prevCart);
  cart.seasonProduct = null;
  calcTotals(cart);
  return cart;
};

export const setSeasonProduct = (prevCart, seasonProduct, pricingId) => {
  const cart = cloneCart(prevCart);

  if (pricingId === "external") {
    cart.seasonProduct = {
      seasonProduct,
      pricing: { external: true },
      price: 0,
      subProductsPrice: 0,
    };
  } else {
    const pricing = seasonProduct.subscriptionDetails.pricings.find(
      (p) => p._id === pricingId
    );

    const subscriptionType =
      seasonProduct?.subscriptionDetails?.subscriptionType;

    const units =
      subscriptionType === "season" || subscriptionType === "camp"
        ? seasonProduct.subscriptionDetails.monthsLeft
        : 1;

    cart.seasonProduct = {
      seasonProduct,
      pricing,
      price: roundPrice(pricing.unitPrice * units),
      subProductsPrice: _.sumBy(seasonProduct.subProducts || [], "price"),
    };
  }

  calcTotals(cart);
  return cart;
};

export const handleSubscriptionPaymentChange = (
  prevCart,
  subscription,
  price
) => {
  const cart = cloneCart(prevCart);

  const prevSub = cart.subscriptionPayments.find(
    (rec) => rec.subscription._id === subscription._id
  );

  if (prevSub) {
    prevSub.price = price;
  } else {
    cart.subscriptionPayments.push({
      subscription,
      price,
    });
  }

  calcTotals(cart);
  return cart;
};

export const calcMerchandiseTotal = (cart) => {
  return roundPrice(
    _.sum(Object.values(cart.merchandise).map((p) => p.price * p.quantity))
  );
};

export const calcSubscriptionPayments = (cart) => {
  return roundPrice(_.sum(cart.subscriptionPayments.map((p) => p.price)));
};

export const calcSeasonProductTotal = (cart) => {
  return cart.seasonProduct
    ? roundPrice(cart.seasonProduct.price + cart.seasonProduct.subProductsPrice)
    : 0;
};

const calcTotals = (cart) => {
  cart.seasonProductTotal = calcSeasonProductTotal(cart);
  cart.merchandiseTotal = calcMerchandiseTotal(cart);
  cart.subscriptionPaymentsTotal = calcSubscriptionPayments(cart);

  cart.total = roundPrice(
    cart.seasonProductTotal +
      cart.merchandiseTotal +
      cart.subscriptionPaymentsTotal
  );
};
