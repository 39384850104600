import React from "react";
import { ListItem, ListItemText, makeStyles } from "@material-ui/core";
import { displayPrice } from "../../lib/helpers";

export default function ReviewItem({ title, description, price, customPrice }) {
  const classes = useStyles();
  return (
    <ListItem className={classes.listItem}>
      <ListItemText
        primary={title}
        primaryTypographyProps={{ className: classes.primaryTextContainer }}
        secondaryTypographyProps={{ component: "span" }}
        secondary={
          <span className={classes.secondaryTextContainer}>
            {description}
            <span className={classes.priceContainer}>
              {customPrice ? (
                customPrice
              ) : (
                <strong>{displayPrice(price)}</strong>
              )}
            </span>
          </span>
        }
      />
    </ListItem>
  );
}

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1),
    margin: theme.spacing(1, 0),
    border: "1px solid #EEE",
    borderRadius: 5,

    [theme.breakpoints.down(700 + theme.spacing(3) * 2)]: {
      borderRadius: 0,
      borderLeftWidth: 0,
      borderRightWidth: 0,
    },
  },
  priceContainer: {
    marginTop: 10,
    color: "#000",
  },
  primaryTextContainer: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "calc(100vw - 70px)",
  },
  secondaryTextContainer: {
    display: "flex",
    flexDirection: "column",
  },
}));
