import React from "react";
import "./App.css";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";

////////////////////////
// import Demo from "./playground/Demo";
////////////////////////
import * as Actions from "./store/actions";
import LayoutRoute from "./layout/LayoutRoute";
import Home from "./containers/Home/Home";
import Privacy from "./containers/Privacy";
import Terms from "./containers/Terms";
import Contact from "./containers/Contact";
import About from "./containers/About";
import ClubTerms from "./containers/ClubTerms";

import Purchase from "./containers/Purchase/Purchase";
import Login from "./containers/Auth/Login";
import Logout from "./containers/Auth/Logout";
import IframeResponder from "./components/store/IframeResponder";
import NewPlayer from "./containers/Players/NewPlayer";
import EditPlayer from "./containers/Players/EditPlayer";
import NewParent from "./containers/Parent/NewParent";
import Profile from "./containers/Profile/Profile";
import ChangePassword from "./containers/Profile/ChangePassword";
import Calendar from "./containers/Calendar/Calendar";
import queryString from "query-string";

import Clubs from "./containers/Clubs/Clubs";

import { CircularProgress, ThemeProvider } from "@material-ui/core";
import { sendMessage } from "./lib/wrapperHelper";

import "moment/locale/he";
import { baseTheme, generateTheme } from "./layout/Theme";
import Accessibility from "./components/Accessibility";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      theme: generateTheme(baseTheme()),
      updateTheme: this.updateTheme,
    };

    const qs = queryString.parse(this.props.location.search);
    // use window.ReactNativeWebView instead of window.trainlitMobile
    const tlMobile = window.ReactNativeWebView || qs.tlMobile;

    this.props.setIsMobile(tlMobile);
  }

  updateTheme = (theme) => {
    this.setState({ theme: generateTheme(theme) });
  };

  handleMessage = (event) => {
    try {
      const data = event.data;
      let url;
      if (data.source === "tlApp") {
        if (data.type === "goBack") {
          // sendMessage("log", JSON.stringify(this.props.history));
          // process android hardware backpress
          if (this.props.currentClub) {
            // sendMessage("log", JSON.stringify(this.props.history));
            // when merchandise product dialog is open we add #productDialog hash to handle android back button press
            this.props.history.location.hash === "#productDialog"
              ? this.props.history.goBack()
              : this.props.history.replace(
                  `/clubs/${this.props.currentClub.internalName}`
                );
          } else {
            this.props.history.goBack();
          }
        } else if (data.type === "notification") {
          switch (data.params.type) {
            case "calendar":
              sendMessage("clubChange", {
                internalName: data.params.details.data.clubInternalName,
                _id: data.params.details.data.clubId,
              });

              url = `/clubs/${
                data.params.details.data.clubInternalName
              }/calendar?ts=${new Date().getTime()}`;

              if (url === this.props.history.location.pathname) {
                this.props.history.replace(`${url}`);
              } else {
                this.props.history.push(url);
              }
              break;

            default:
              break;
          }
        }
      }
    } catch (error) {
      //just igone this
    }
  };

  componentDidMount = () => {
    this.props.autoLogin();

    window.addEventListener("message", this.handleMessage);
    // required for the back button from parents' login to the choose user type screen in the app
    sendMessage("urlUpdate", this.props.location);
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.location !== prevProps.location) {
      sendMessage("urlUpdate", this.props.location);
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("message", this.handleMessage);
  };

  staticRoutes = (auth) => {
    const params = auth ? {} : { noAuth: true };

    return [
      <LayoutRoute
        key="terms"
        path="/clubs/:clubId/terms"
        component={Terms}
        {...params}
      />,
      <LayoutRoute
        key="termsNoClub"
        path="/terms"
        noClub
        component={Terms}
        {...params}
      />,
      <LayoutRoute
        key="contact"
        path="/clubs/:clubId/contact"
        component={Contact}
        {...params}
      />,
      <LayoutRoute
        key="about"
        path="/clubs/:clubId/about"
        component={About}
        {...params}
      />,
      <LayoutRoute
        key="clubTerms"
        path="/clubs/:clubId/clubTerms"
        component={ClubTerms}
        {...params}
      />,
      <LayoutRoute
        key="privacy"
        path="/clubs/:clubId/privacy"
        component={Privacy}
        {...params}
      />,
      <LayoutRoute
        key="privacyNoClub"
        path="/privacy"
        component={Privacy}
        noClub
        {...params}
      />,
    ];
  };

  renderContent() {
    if (this.props.loading) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      );
    }

    return this.props.isAuthenticated ? (
      <Switch>
        <LayoutRoute path="/clubs/:clubId/home" component={Home} />
        <Route path="/clubs/:clubId/logout" component={Logout} />
        <LayoutRoute
          path="/clubs/:clubId/purchase"
          component={Purchase}
          layoutProps={{ minPadding: true, customPurchaseMobileHeader: true }}
        />
        <LayoutRoute path="/clubs/:clubId/players/new" component={NewPlayer} />
        <LayoutRoute
          path="/clubs/:clubId/profile/changePassword"
          component={ChangePassword}
        />
        <LayoutRoute
          path="/clubs/:clubId/profile"
          component={Profile}
          layoutProps={{ routeKey: "profile" }}
        />
        <LayoutRoute
          path="/clubs/:clubId/players/:playerId/edit"
          component={EditPlayer}
        />
        {this.staticRoutes(true)}
        <LayoutRoute
          key="about"
          path="/clubs/:clubId/about"
          component={About}
          layoutProps={{ center: true }}
        />
        <LayoutRoute
          key="calendar"
          path="/clubs/:clubId/calendar"
          component={Calendar}
          layoutProps={{ center: true, noPadding: true }}
        />
        <LayoutRoute
          path="/clubs"
          exact
          noClub
          component={Clubs}
          layoutProps={{ pageType: "clubSelect" }}
        />
        <LayoutRoute
          path="/clubs/:clubId"
          component={Home}
          layoutProps={{ pageType: "home" }}
        />
        <Redirect from="/login" to="/clubs" />

        <Route path="/iframeResponder" component={IframeResponder} />
      </Switch>
    ) : (
      <Switch>
        {/* <LayoutRoute path="/clubs" exact noAuth noClub component={Clubs} /> */}

        <LayoutRoute
          path="/clubs/:clubId/parents/new"
          component={NewParent}
          noAuth
        />

        <LayoutRoute path="/parents/new" component={NewParent} noAuth noClub />

        <LayoutRoute
          exact
          path="/login"
          component={Login}
          noAuth
          noClub // for layoutRoute
          layoutProps={{ noPadding: true, appBarProps: { noBackButton: true } }} // for the layout itself
        />

        <LayoutRoute
          exact
          path="/clubs/:clubId"
          component={Login}
          noAuth
          layoutProps={{ noPadding: true }}
        />

        {this.staticRoutes(false)}
        <Redirect from="/clubs/:clubId" to="/clubs/:clubId" />
        {/* the cc iframe responder is not an authenticated request  */}
        <Route path="/iframeResponder" component={IframeResponder} />
      </Switch>
    );
  }

  render() {
    return (
      <ThemeProvider theme={this.state.theme}>
        <Accessibility updateTheme={this.updateTheme} />
        {this.renderContent()}
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    loading: state.auth.loading,
    isMobile: state.general.isMobile,
    currentClub: state.general.club,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    autoLogin: () => dispatch(Actions.auth.autoLogin()),
    setIsMobile: (isMobile) => dispatch(Actions.general.setIsMobile(isMobile)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
