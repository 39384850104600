import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@material-ui/core";
import React from "react";

export default function PopUp(props) {
  const handleClose = (e, reason) => {
    if (reason === "backdropClick" && props.noNativeClose) {
      e.preventDefault();
    } else {
      props.onClose();
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      open={props.open}
      disableEscapeKeyDown={props.noNativeClose}
      {...(props.size ? { maxWidth: props.size, fullWidth: true } : {})}
      dir="rtl"
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <div className={props.contentClassName}>{props.children}</div>
      </DialogContent>
      <DialogActions>
        {props.button1 && (
          <Button variant="outlined" onClick={props.button1.onClick}>
            {props.button1.title}
          </Button>
        )}
        {props.button2 && (
          <Button variant="outlined" onClick={props.button2.onClick}>
            {props.button2.title}
          </Button>
        )}
        {props.customButton}
      </DialogActions>
    </Dialog>
  );
}
