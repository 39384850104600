import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import NotFoundImage from "../images/404.png";

function PageNotFound(props) {
  const history = useHistory();

  if (props.isMobile) {
    history.replace("/clubs");
    return <></>;
  }

  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        marginRight: "-50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
      }}
    >
      <img
        src={NotFoundImage}
        style={{ marginBottom: "50px", height: "200px", maxWidth: "80%" }}
      />
      <h3>העמוד שחיפשתם לא קיים</h3>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.general.isMobile,
  };
};

export default connect(mapStateToProps)(PageNotFound);
