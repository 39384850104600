import React from "react";
import { Typography, Link, Grid } from "@material-ui/core";

export default function CopyRight() {
  return (
    <Typography variant="body2" color="textSecondary" component={"span"}>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item> כל הזכויות שמורות</Grid>
        <Grid item>&nbsp;{"©"}&nbsp; </Grid>
        <Grid item>
          <Link color="inherit" href="https://trainlitapp.com">
            TrainLit
          </Link>
          &nbsp;
        </Grid>
        <Grid item>
          {new Date().getFullYear()}
          {"."}
        </Grid>
      </Grid>
    </Typography>
  );
}
