let dev = {
  apiUrl: "http://localhost:3000",
  selfUrl: "http://localhost:3002",
};

// dev = {
//   apiUrl: "http://10.0.0.1:3000",
//   selfUrl: "http://10.0.0.1:3002",
// };

const prod = {
  apiUrl: "https://api4.trainlitapp.com",
  selfUrl: "https://clubs.trainlitapp.com",
};

let config;

switch (process.env.NODE_ENV) {
  case "development":
    config = dev;
    break;

  case "production":
    config = prod;
    break;

  default:
    break;
}

export default config;
