import React from "react";
import { Typography, Link } from "@material-ui/core";
import { Image } from "cloudinary-react";

export default function PoweredBy() {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      component="div"
    >
      {"מופעל באמצעות "}
      <div>
        <Link
          color="inherit"
          href="https://trainlitapp.com/"
          style={{ textDecoration: "none" }}
        >
          <Image
            publicId={`general/trainlitLogoBlack`}
            alt="Logo"
            style={{ width: 150, marginTop: 10 }}
          />
        </Link>{" "}
      </div>
    </Typography>
  );
}
