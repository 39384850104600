import React from "react";
import {
  Grid,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Checkbox,
  TextField,
  FormHelperText,
  FormControl,
} from "@material-ui/core";

export default function MedicalForm(props) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <FormControl
          component="fieldset"
          error={props.errors.hasMedicalIssues !== undefined}
          required
        >
          <FormLabel component="legend">
            האם לספורטאי/ת יש בעיות רפואיות ו/או רגישויות ו/או מגבלות גופניות
            אחרות?
          </FormLabel>
          <RadioGroup
            name="medical"
            value={
              props.state.hasMedicalIssues === null
                ? ""
                : props.state.hasMedicalIssues.toString()
            }
            onChange={(e) =>
              props.onChange({ hasMedicalIssues: e.target.value === "true" })
            }
            row
          >
            <FormControlLabel value="true" control={<Radio />} label="כן" />
            <FormControlLabel value="false" control={<Radio />} label="לא" />
          </RadioGroup>
          <FormHelperText>{props.errors.hasMedicalIssues}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="אם כן, אנא פרט אותן:"
          value={props.state.medicalIssues}
          onChange={(e) => props.onChange({ medicalIssues: e.target.value })}
          fullWidth
          multiline
          maxRows={3}
          disabled={props.state.hasMedicalIssues === false}
          error={props.errors.medicalIssues !== undefined}
          helperText={props.errors.medicalIssues}
          style={{ marginBottom: 20 }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl
          component="fieldset"
          error={props.errors.medicalIssuesUpdate !== undefined}
          required
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={props.state.medicalIssuesUpdate}
                onChange={(e) =>
                  props.onChange({ medicalIssuesUpdate: e.target.checked })
                }
                name="medicalIssuesUpdate"
              />
            }
            label="במידה ויחול שינוי במצבו הבריאותי של הספורטאי/ת אני מתחייב כי אדווח על כך להנהלת המועדון באופן ישיר."
          />
          <FormHelperText>{props.errors.medicalIssuesUpdate}</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}
