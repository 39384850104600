import React from "react";
import { connect } from "react-redux";
import * as Actions from "./../../store/actions";
import axios from "../../lib/axios";
import { clubUrl } from "../../lib/helpers";
import { sendMessage } from "../../lib/wrapperHelper";

class Logout extends React.Component {
  componentDidMount = async () => {
    try {
      await axios.post(`/users/${this.props.parentId}/logout`);
    } catch (error) {
      console.error(error);
    }
    const url = this.props.isMobile
      ? "/login"
      : clubUrl(this.props.match.params.clubId, "");

    sendMessage("logout", { mode: url });

    this.props.logout();
    if (this.props.isMobile) {
      this.props.unloadClub();
    }
    this.props.history.push(url);
  };

  render() {
    return <React.Fragment />;
  }
}

const mapStateToProps = (state) => {
  return {
    parentId: state.auth.parentId,
    isMobile: state.general.isMobile,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(Actions.auth.logout()),
    unloadClub: () => dispatch(Actions.general.unloadClub()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
