import React from "react";
// import { Alert } from "@material-ui/lab";
import {
  IconButton,
  makeStyles,
  Snackbar as MuiSnackbar,
  SnackbarContent,
} from "@material-ui/core";
import clsx from "clsx";

import WarningIcon from "@material-ui/icons/Warning";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import { amber, green } from "@material-ui/core/colors";
// import

export default function Snackbar(props) {
  const classes = useStyles();
  const Icon = variantIcon[props.variant];

  return (
    <MuiSnackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      autoHideDuration={6000}
      {...props}
      key={props.open ? props.snackKey : undefined}
    >
      <SnackbarContent
        className={clsx(classes[props.variant])}
        message={
          <span className={classes.message}>
            {Icon && (
              <Icon className={clsx(classes.icon, classes.iconVariant)} />
            )}
            {props.message}
          </span>
        }
        action={[
          <IconButton key="close" color="inherit" onClick={props.onClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </MuiSnackbar>
  );
}

// import RTL from "../../layout/Rtl";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
}));
