import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Hidden } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { connect } from "react-redux";
import { CloudinaryContext } from "cloudinary-react";
import clsx from "clsx";
import queryString from "query-string";

import RTL from "../layout/Rtl";
import * as Actions from "../store/actions";
import Drawer from "../layout/Drawer";
import Footer from "./Footer";
import AppBar from "../layout/AppBar";
import MobileAppBar from "./MobileAppBar";
import MissingIdDialog from "../containers/Parent/MissingIdDialog";
import TrainlitLoader from "../components/TrainlitLoader";

class Layout extends React.Component {
  state = {
    isDrawerOpen: false, // mobile
    customMobileTitle: null,
  };

  loadClub = () => {
    this.props.unloadClub();
    this.props.loadClub(this.props.match.params.clubId);
  };

  componentDidMount = async () => {
    if (this.props.isAuthenticated) {
      this.props.loadParent();
    }

    if (this.props.match.params.clubId) {
      if (
        !this.props.club ||
        this.props.club.internalName !== this.props.match.params.clubId
      ) {
        this.loadClub();
      }
    }

    if (this.props.customPurchaseMobileHeader) {
      this.setPurchaseMobileTitle();
    }
  };

  setPurchaseMobileTitle = () => {
    const qs = queryString.parse(this.props.location.search);

    this.setState({
      customMobileTitle: {
        store: "חנות מוצרים",
        subscription: "רישום",
        payment: "תשלום",
      }[qs.mode],
    });
  };

  componentDidUpdate = async (prevProps) => {
    if (
      prevProps.match.params.clubId !== this.props.match.params.clubId &&
      this.props.match.params.clubId
    ) {
      this.loadClub();
    }

    // navigate to/from purchase page OR we are on purchase page and the params have changed (solving issue when moving from registration to store)
    if (
      prevProps.customPurchaseMobileHeader !==
        this.props.customPurchaseMobileHeader ||
      (prevProps.location.search != this.props.location.search &&
        this.props.customPurchaseMobileHeader)
    ) {
      if (this.props.customPurchaseMobileHeader) {
        this.setPurchaseMobileTitle();
      } else {
        this.setState({ customMobileTitle: null });
      }
    }
  };

  handleDrawerToggle = () => {
    this.setState((prevState) => ({
      isDrawerOpen: !prevState.isDrawerOpen,
    }));
  };

  handleCloseDrawer = () => {
    this.setState({ isDrawerOpen: false });
  };

  render() {
    const { classes } = this.props;

    return (
      <RTL>
        <CloudinaryContext cloudName="dmixnaxp6">
          <div dir="rtl" className={classes.root}>
            <CssBaseline />

            <Hidden smDown>
              <AppBar
                {...this.props.appBarProps}
                clubConfig={this.props.clubConfig}
              />
            </Hidden>
            <Hidden mdUp>
              <MobileAppBar
                {...this.props.appBarProps}
                onDrawerToggle={this.handleDrawerToggle}
                clubConfig={this.props.clubConfig}
                pageType={this.props.pageType}
                noBackButton={this.props.pageType === "home"}
                clubId={this.props.match.params.clubId}
                customTitle={this.state.customMobileTitle}
              />
            </Hidden>

            {!this.props.noDrawer && (
              <Drawer
                open={this.state.isDrawerOpen}
                onCloseDrawer={this.handleCloseDrawer}
                clubConfig={this.props.clubConfig}
                isMobile={this.props.isMobile}
              />
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
              }}
            >
              {this.props.isAuthenticated &&
                this.props.parent &&
                this.props.parent._id &&
                !this.props.parent.id &&
                this.props.routeKey != "profile" && (
                  <MissingIdDialog
                    open
                    clubId={this.props.match.params.clubId}
                  />
                )}
              <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                {this.props.club || !this.props.match.params.clubId ? (
                  this.props.noPadding ? (
                    this.props.children
                  ) : (
                    <Container
                      maxWidth="lg"
                      className={clsx(classes.container, {
                        [classes.minPadding]: this.props.minPadding,
                      })}
                    >
                      {this.props.children}
                    </Container>
                  )
                ) : (
                  <div>
                    <TrainlitLoader />
                  </div>
                )}
              </main>
              {!this.props.noFooter && <Footer />}
            </div>
          </div>
        </CloudinaryContext>
      </RTL>
    );
  }
}

const styles = (theme) => ({
  root: {
    display: "flex",
    minHeight: "100vh",
  },
  appBarSpacer: { height: 65 },
  content: {
    flexGrow: 1,
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  minPadding: {
    padding: theme.spacing(1),
  },
});

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    parent: state.auth.parent,
    club: state.general.club,
    clubConfig: state.general.clubConfig,
    isMobile: state.general.isMobile,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    loadParent: () => dispatch(Actions.auth.loadParent()),
    loadClub: (clubInternalName) =>
      dispatch(Actions.general.loadClub(clubInternalName)),
    unloadClub: () => dispatch(Actions.general.unloadClub()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Layout));
