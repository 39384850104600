import React from "react";

import {
  Grid,
  TextField,
  InputLabel,
  Fab,
  FormHelperText,
  makeStyles,
} from "@material-ui/core";
import moment from "moment";
import DeleteIcon from "@material-ui/icons/Delete";

import { classes as playerClasses } from "../../lib/dictionaries";
import MenuItem from "../../components/base/MenuItem";

export default function PlayerForm(props) {
  const classes = useStyles();
  const currentYear = moment().year();

  const renderPlayerDetails = () => (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            name="firstName"
            label="שם פרטי"
            fullWidth
            autoComplete="fname"
            value={props.state.firstName}
            onChange={(e) => props.onChange({ firstName: e.target.value })}
            error={Object.prototype.hasOwnProperty.call(
              props.errors,
              "firstName"
            )}
            helperText={props.errors.firstName}
            margin="none"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="lastName"
            label="שם משפחה"
            fullWidth
            autoComplete="lname"
            value={props.state.lastName}
            onChange={(e) => props.onChange({ lastName: e.target.value })}
            error={Object.prototype.hasOwnProperty.call(
              props.errors,
              "lastName"
            )}
            helperText={props.errors.lastName}
            margin="none"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="מספר הטלפון של הספורטאי/ת"
            fullWidth
            value={props.state.phone}
            onChange={(e) => props.onChange({ phone: e.target.value })}
            error={Object.prototype.hasOwnProperty.call(props.errors, "phone")}
            helperText={
              props.errors.phone ||
              "במידה ולספורטאי/ת אין טלפון יש להשאיר שדה זה ריק"
            }
            margin="none"
          />
        </Grid>
        {props.id !== false && (
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              error={Object.prototype.hasOwnProperty.call(props.errors, "id")}
              label="ת.ז"
              value={props.state.id}
              onChange={(e) => props.onChange({ id: e.target.value })}
              helperText={props.errors.id}
              margin="none"
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            error={Object.prototype.hasOwnProperty.call(props.errors, "class")}
            label="כיתה"
            select
            value={props.state.class}
            onChange={(e) => props.onChange({ class: e.target.value })}
            helperText={props.errors.class}
            margin="none"
          >
            {Object.entries(playerClasses).map(([id, title]) => (
              <MenuItem key={id} value={id} className={classes.menuItem}>
                {title}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="school"
            name="school"
            label="בית ספר"
            fullWidth
            autoComplete="school"
            value={props.state.school}
            onChange={(e) => props.onChange({ school: e.target.value })}
            error={Object.prototype.hasOwnProperty.call(props.errors, "school")}
            helperText={props.errors.school}
            margin="none"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputLabel
            htmlFor="class-label"
            error={Object.prototype.hasOwnProperty.call(props.errors, "dob")}
          >
            תאריך לידה*
          </InputLabel>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextField
                required
                id="class-select"
                select
                value={props.state.dobYear}
                onChange={(e) => props.onChange({ dobYear: e.target.value })}
                fullWidth
                SelectProps={{ displayEmpty: true }}
                error={Object.prototype.hasOwnProperty.call(
                  props.errors,
                  "dob"
                )}
                margin="none"
              >
                <MenuItem value="">
                  <em>שנה</em>
                </MenuItem>
                {[...Array(100).keys()].map((year) => (
                  <MenuItem
                    key={year}
                    value={currentYear - year}
                    className={classes.menuItem}
                  >
                    {currentYear - year}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                required
                select
                value={props.state.dobMonth}
                onChange={(e) => props.onChange({ dobMonth: e.target.value })}
                fullWidth
                SelectProps={{ displayEmpty: true }}
                error={Object.prototype.hasOwnProperty.call(
                  props.errors,
                  "dob"
                )}
                margin="none"
              >
                <MenuItem value="">
                  <em>חודש</em>
                </MenuItem>
                {[...Array(12).keys()].map((month) => (
                  <MenuItem
                    key={month}
                    value={month + 1}
                    className={classes.menuItem}
                  >
                    {month + 1}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                required
                select
                value={props.state.dobDay}
                onChange={(e) => props.onChange({ dobDay: e.target.value })}
                fullWidth
                SelectProps={{ displayEmpty: true }}
                error={Object.prototype.hasOwnProperty.call(
                  props.errors,
                  "dob"
                )}
                margin="none"
              >
                <MenuItem value="">
                  <em>יום</em>
                </MenuItem>
                {[...Array(31).keys()].map((day) => (
                  <MenuItem key={day} value={day + 1}>
                    {day + 1}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <FormHelperText
            error={Object.prototype.hasOwnProperty.call(props.errors, "dob")}
          >
            {props.errors.dob}
          </FormHelperText>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            error={Object.prototype.hasOwnProperty.call(props.errors, "gender")}
            label="מין"
            value={props.state.gender}
            onChange={(e) => props.onChange({ gender: e.target.value })}
            helperText={props.errors.gender}
            select
            margin="none"
          >
            <MenuItem value="">
              <em>מין</em>
            </MenuItem>
            <MenuItem value="male">זכר</MenuItem>
            <MenuItem value="female">נקבה</MenuItem>
          </TextField>
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          {props.showDeleteButton && (
            <Fab color="secondary" size="small" onClick={props.onDelete}>
              <DeleteIcon />
            </Fab>
          )}
        </Grid>
      </Grid>
    </div>
  );

  const hasExistingPlayers = props.user && props.user.players.length > 0;

  return (
    <React.Fragment>
      {(!hasExistingPlayers || props.state.playerId) && renderPlayerDetails()}
    </React.Fragment>
  );
}

const useStyles = makeStyles(() => ({
  uniquePhoneError: {
    padding: 10,
    paddingRight: 5,
    border: "0.5px solid #757575",
    borderRadius: 2,
  },
}));
