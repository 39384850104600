import { Grid, Hidden, makeStyles } from "@material-ui/core";
import React from "react";

import { Link as RouterLink, useParams } from "react-router-dom";
import Copyright from "../components/Copyright";
import { clubUrl } from "../lib/helpers";

export default function Footer() {
  const classes = useStyles();
  const { clubId } = useParams();

  return (
    <footer>
      <div className={classes.container}>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item>
            <RouterLink
              color="secondary"
              to={clubUrl(clubId, "terms")}
              className={classes.link}
              key="about"
            >
              תנאי שימוש
            </RouterLink>
          </Grid>
          <Grid item>
            <RouterLink
              color="secondary"
              to={clubUrl(clubId, "privacy")}
              className={classes.link}
              key="about"
            >
              פרטיות
            </RouterLink>
          </Grid>
          <Grid item xs />
          <Hidden xsDown>
            <Grid item>
              <Copyright />
            </Grid>
          </Hidden>
        </Grid>
      </div>
    </footer>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    borderTop: `${theme.footer.border} solid 1px`,
    backgroundColor: theme.footer.background,
    width: "100%",
    height: 50,
    display: "flex",
    alignItems: "center",
    flex: 1,
    paddingLeft: 20,
    paddingRight: 20,
  },
  link: {
    textDecoration: "none",
    color: theme.footer.link,
    "&:hover": {
      color: theme.footer.linkHover,
    },
  },
}));
