import React from "react";
import {
  Avatar,
  Card,
  CardContent,
  Divider,
  Typography,
  CardActions,
  Grid,
  makeStyles,
} from "@material-ui/core";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCartOutlined";
import PersonIcon from "@material-ui/icons/Person";
import ListIcon from "@material-ui/icons/ListAltOutlined";
import AddIcon from "@material-ui/icons/PersonAddOutlined";
import { Link as RouterLink, useParams } from "react-router-dom";
import AbstractBackground from "../../resources/images/paperBackground.jpg";

import { clubUrl } from "../../lib/helpers";

export default function PlayerCard(props) {
  const classes = useStyles();
  const params = useParams();
  const player = props.player || {};

  return (
    <Card className={classes.container}>
      <CardContent className={classes.cardContent}>
        <div className={classes.top}>
          <Avatar className={classes.avatar}>
            <PersonIcon style={{ fontSize: 40 }} />
          </Avatar>

          <div className={classes.nameContainer}>
            <Typography variant="h5">&nbsp;{player.name}&nbsp;</Typography>
          </div>
        </div>
        <Divider />
      </CardContent>
      <CardActions style={{ padding: 0 }}>
        <Grid container>
          {player.new ? (
            <Grid item xs>
              <RouterLink
                color="secondary"
                to={clubUrl(
                  params.clubId,
                  player.new ? "players/new" : `players/${player._id}/edit`
                )}
                className={classes.link}
              >
                <div className={classes.item}>
                  <AddIcon />
                  <Typography>הוספת ספורטאי/ת</Typography>
                </div>
              </RouterLink>
            </Grid>
          ) : (
            <React.Fragment>
              <Grid item xs>
                <RouterLink
                  color="secondary"
                  to={clubUrl(params.clubId, `players/${player._id}/edit`)}
                  className={classes.link}
                >
                  <div className={classes.item}>
                    <ListIcon />
                    <Typography>פרופיל</Typography>
                  </div>
                </RouterLink>
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item xs>
                <RouterLink
                  color="secondary"
                  to={clubUrl(
                    params.clubId,
                    `purchase?playerId=${player._id}&mode=subscription`
                  )}
                  className={classes.link}
                >
                  <div className={classes.item}>
                    <AddShoppingCartIcon />
                    <Typography>רישום לפעילות</Typography>
                  </div>
                </RouterLink>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </CardActions>
    </Card>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    border: theme.paper.border,
  },
  top: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(3),
    height: 140,
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  link: {
    textDecoration: "none",
  },
  item: {
    padding: 10,
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "&:hover": {
      background: theme.playerCard.hover.background,
      color: theme.playerCard.hover.color,
    },
    color: theme.palette.text.primary,
    textDecoration: "none",
  },
  nameContainer: {
    marginTop: 10,
  },
  cardContent: {
    padding: 0,
    ...(theme.paper.header
      ? {}
      : {
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundImage: `url(${AbstractBackground})`,
        }),
  },
}));
