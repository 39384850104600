import validator from "validator";
import moment from "moment";

export const validateSubscription = async (subscription) => {
  const errors = {};

  if (!subscription.level) {
    errors.level = "required";
  }
  if (!subscription.subLevel) {
    errors.subLevel = "required";
  }
  if (!subscription.teamId) {
    errors.teamId = "required";
  }

  const team = subscription?.subLevel?.teams?.find(
    (t) => t._id === subscription.teamId
  );

  if (!subscription.pricingId && team?.product?._id !== "external") {
    errors.pricingId = "required";
  }

  return errors;
};

export const validateParent = async (parentForm, editMode = false) => {
  const errors = {};
  const firstName = parentForm.firstName.trim();
  if (firstName === "") {
    errors.firstName = "שדה חובה";
  } else if (firstName.length < 2) {
    errors.firstName = "שדה לא תקין";
  }

  const lastName = parentForm.lastName.trim();
  if (lastName === "") {
    errors.lastName = "שדה חובה";
  } else if (lastName.length < 2) {
    errors.lastName = "שדה לא תקין";
  }

  const email = parentForm.email.trim();
  if (email === "") {
    errors.email = "שדה חובה";
  } else if (!validator.isEmail(email)) {
    errors.email = "שדה לא תקין";
  }

  if (!editMode) {
    const phone = parentForm.phone.trim();
    if (phone === "") {
      errors.phone = "שדה חובה";
    } else if (
      !validator.isMobilePhone(phone, "he-IL") ||
      phone.startsWith("+")
    ) {
      errors.phone = "שדה לא תקין";
    }

    if (parentForm.password === "") {
      errors.password = "שדה חובה";
    } else if (
      !parentForm.password.match(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/
      )
    ) {
      errors.password = "שדה לא תקין";
    }

    if (!parentForm.termsAgreed) {
      errors.termsAgreed = "שדה חובה";
    }
  }

  const id = parentForm.id.trim();
  if (id === "") {
    errors.id = "שדה חובה";
  } else if (!validator.isIdentityCard(id, "he-IL")) {
    errors.id = "יש להזין מספר תעודת זהות בן 9 ספרות";
  }

  const street = parentForm.street.trim();
  if (street === "") {
    errors.street = "שדה חובה";
  } else if (street.length < 2) {
    errors.street = "שדה לא תקין";
  }

  const houseNumber = parentForm.houseNumber.trim();
  if (houseNumber === "") {
    errors.houseNumber = "שדה חובה";
  } else if (houseNumber.length === 0) {
    errors.houseNumber = "שדה לא תקין";
  }

  const city = parentForm.city.trim();
  if (city === "") {
    errors.city = "שדה חובה";
  } else if (city.length < 2) {
    errors.city = "שדה לא תקין";
  }

  const secondParentName = parentForm.secondParentName.trim();
  if (secondParentName !== "" && secondParentName.length < 2) {
    errors.secondParentName = "שדה לא תקין";
  }

  const secondParentPhone = parentForm.secondParentPhone.trim();
  if (
    secondParentPhone !== "" &&
    !validator.isMobilePhone(secondParentPhone, "he-IL")
  ) {
    errors.secondParentPhone = "שדה לא תקין";
  }

  return errors;
};

export const validatePlayer = async (player) => {
  const errors = {};

  const firstName = player.firstName.trim();
  if (firstName === "") {
    errors.firstName = "שדה חובה";
  } else if (firstName.length < 2) {
    errors.firstName = "שדה לא תקין";
  }

  const lastName = player.lastName.trim();
  if (lastName === "") {
    errors.lastName = "שדה חובה";
  } else if (lastName.length < 2) {
    errors.lastName = "שדה לא תקין";
  }

  const phone = player.phone.trim();
  if (
    phone !== "" &&
    (!validator.isMobilePhone(phone, "he-IL") || phone.startsWith("+"))
  ) {
    errors.phone = "שדה לא תקין";
  }

  if (player.class === "") {
    errors.class = "שדה חובה";
  }

  const id = player.id.trim();
  if (id === "") {
    errors.id = "שדה חובה";
  } else if (!validator.isIdentityCard(id, "he-IL")) {
    errors.id = "שדה לא תקין";
  }

  const dob = moment([player.dobYear, player.dobMonth - 1, player.dobDay]);
  if (!dob.isValid()) {
    errors.dob = "תאריך לא תקין";
  }

  if (player.gender === "") {
    errors.gender = "שדה חובה";
  }

  if (player.hasMedicalIssues === null) {
    errors.hasMedicalIssues = "שדה חובה";
  }

  if (player.hasMedicalIssues && player.medicalIssues.trim() === "") {
    errors.medicalIssues = "שדה חובה";
  }

  if (!player.medicalIssuesUpdate) {
    errors.medicalIssuesUpdate = "שדה חובה";
  }

  return errors;
};

export const validatePlayerUpdateDetails = async (player) => {
  const errors = {};

  const phone = player.phone.trim();
  if (
    phone !== "" &&
    (!validator.isMobilePhone(phone, "he-IL") || phone.startsWith("+"))
  ) {
    errors.phone = "שדה לא תקין";
  }

  if (player.class === "") {
    errors.class = "שדה חובה";
  }

  return errors;
};
