import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Container,
  List,
  ListSubheader,
  makeStyles,
} from "@material-ui/core";
import _ from "lodash";
import moment from "moment";

import Paper from "../../components/Paper";
import axios from "../../lib/axios";
import Event from "./Event";

export default function Calendar(props) {
  const [eventGroups, setEventGroups] = useState(null);
  const [loadTimestamp, setLoadTimestamp] = useState(null);
  useEffect(() => {
    setLoadTimestamp(
      new URLSearchParams(props.history.location.search).get("ts")
    );
    loadData();
  }, []);

  useEffect(() => {
    const newTs = new URLSearchParams(props.history.location.search).get("ts");

    if (loadTimestamp !== newTs) {
      setLoadTimestamp(newTs);
      loadData();
    }
  }, [props]);

  const classes = useStyles();

  const loadData = async () => {
    const res = await axios.get(
      `/events?clubInternalName=${props.match.params.clubId}&granularity=resourceName`
    );

    let events = _.sortBy(res.data, (e) => moment(e.start).valueOf());
    const eventGroups = _.groupBy(events, (e) => {
      const startOfWeek = moment(e.start).startOf("week");
      if (startOfWeek.isSame(moment().startOf("week"))) {
        return "current";
      } else if (
        startOfWeek.isSame(moment().add("1", "week").startOf("week"))
      ) {
        return "next";
      } else {
        return "future";
      }
    });
    setEventGroups(eventGroups);
  };

  const renderWeek = (type, events) => {
    let title;
    switch (type) {
      case "current":
        title = `שבוע נוכחי - ${moment()
          .endOf("week")
          .format("DD/MM")} - ${moment().startOf("week").format("DD/MM")}`;
        break;

      case "next":
        title = `שבוע הבא - ${moment()
          .add(1, "week")
          .endOf("week")
          .format("DD/MM")} - ${moment()
          .add(1, "week")
          .startOf("week")
          .format("DD/MM")}`;
        break;

      default:
        break;
    }

    return (
      <React.Fragment key={type}>
        <ListSubheader
          style={{
            padding: 0,
            marginBottom: 15,
            marginTop: type !== "current" ? 15 : 0,
          }}
        >
          <div className={classes.subHeader}>{title}</div>
        </ListSubheader>
        {events.map((e) => (
          <React.Fragment key={e._id}>
            <Event event={e} />
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  };

  return (
    <Container maxWidth="md" className={classes.container}>
      <Paper title="לוח אירועים" bigTitle titleBackground>
        {eventGroups ? (
          <List>
            {Object.entries(eventGroups).map(([weekType, weekEvents]) =>
              renderWeek(weekType, weekEvents)
            )}
          </List>
        ) : (
          <CircularProgress />
        )}
      </Paper>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(5),
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(3),
    },
  },
  subHeader: {
    backgroundColor: "rgba(42, 82, 152, 0.3)",
    border: "1px solid #EEE",
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flex: 1,
    width: "100%",
    fontWeight: "bold",
    fontSize: 16,
  },
}));
